import { createContext, useState, useContext } from 'react';

import ProjectContext from './ProjectContext'
import SceneContext from './SceneContext'

import { detectDecsAndWorks, getCalculation } from '../utils/calcUtils'
import { getCalculatedDimensions } from '../utils/sceneUtils'

const CalcContext = createContext();

export const CalcProvider = ({ children }) => {

    const { calcData, results, setResults, project } = useContext(ProjectContext)
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const makeCalculations = () => {

        // console.log('CALC DATA DECISIONS',projectActions.scenes)

        projectActions.scenes.length > 1 && projectActions.setScenes(scenes => {

            const tempScene = scenes[1]

            tempScene.house.map(houseItem => {
                const dec = detectDecsAndWorks(houseItem.id_element,houseItem.features,calcData.int_decisions)
                // dec.length > 0 && console.log('RESOLVED DEC HOME',houseItem.id,dec)
                houseItem.decisions = dec;
                return houseItem;
            })

            tempScene.layers[0].layer_1.areas.map(area => {
                const decFloor = detectDecsAndWorks(area.floor.id_element,area.floor.features,calcData.int_decisions)
                const decCeiling = detectDecsAndWorks(area.ceiling.id_element,area.ceiling.features,calcData.int_decisions)
                // decFloor.length > 0 && console.log('RESOLVED DEC',area.floor.id,area.floor.features,decFloor)
                // decCeiling.length > 0 && console.log('RESOLVED DEC',area.ceiling.id,area.ceiling.features,decCeiling)
                area.floor.decisions = decFloor;
                area.ceiling.decisions = decCeiling;
                return area;
            })

            tempScene.layers[0].layer_1.lines.map(line => {
                const dec = detectDecsAndWorks(line.id_element,line.features_a,calcData.int_decisions)
                // dec.length > 0 && console.log('RESOLVED DEC',line.id,dec)
                line.decisions = dec;
                return line;
            })

            tempScene.layers[0].layer_1.holes.map(hole => {
                const dec = detectDecsAndWorks(hole.id_element,hole.features,calcData.int_decisions)
                // dec.length > 0 && console.log('RESOLVED DEC',hole.id,dec)
                hole.decisions = dec;
                return hole;
            })

            tempScene.layers[0].layer_1.items.map((item,i,items) => {
                // console.log('before',item.decisions)
                if(item.features[1] === 195){
                    const newMeasurements = {"area":null,length:getCalculatedDimensions(items,[133, 134, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 249, 250, 251, 252, 254, 257 ]),height:null,depth:null};
                    if(newMeasurements['length'] !== 0){
                        item.measurements = newMeasurements;
                    }
                    // console.log('IN DEC',newMeasurements,item.measurements)
                }

                const dec = detectDecsAndWorks(item.id_element,item.features,calcData.int_decisions)
                // if(item.features[1] === 153){
                //     console.log('RESOLVED DEC',item.id_element,item.type,dec,item)
                // }
                item.decisions = dec === undefined ? null : dec;
                return item;
            })

            // console.log(scenes[1].layers[0].layer_1)

            return scenes
        })

        sceneActions.setRefresh(!sceneActions.refresh)
    }

    const makeResults = () => {

        const newResults = getCalculation(calcData, project, projectActions.scenes[1])

        setResults(results => {
            results[0] = newResults

            return results
        })
    }

    const addResult = () => {
        const newResults = getCalculation(calcData, project, projectActions.scenes[1])

        setResults(...results,newResults)
    }

    return (
        <CalcContext.Provider value={{
            makeCalculations, makeResults, addResult
        }}>{children}</CalcContext.Provider>
    )
}

export default CalcContext;

// const makeResults = () => {

//     // const newResults = getCalculation(calcData, project, projectActions.scenes[1])

//     let newResults = null;

//     // console.log('RESULTS', newResults)

//     const makeResult = new Promise((resolve,reject) => {
//         newResults = getCalculation(calcData, project, projectActions.scenes[1])

//         resolve(newResults)
//     })

//     makeResult.then(() => {
//         setResults(results => {
//             results[0] = newResults

//             return results
//         })

        
//         console.log('IN RESULTS',results[0])
//     })

//     console.log('OUTSIDE RESULTS',results[0])
// }

