import { useState, useEffect, useContext } from 'react'
import { RedFormat } from 'three'
import ProjectContext from '../../context/ProjectContext'
import QnrContext from '../../context/QnrContext'
import Canvas from '../planner/Canvas'
import ShapePlanner from '../planner/ShapePlanner'
import BigImg from '../widgets/BigImg'
import { checkValueFilter, getElementData, getElementOfId, getFeatureValue, getGroupFeatureValue } from '../../utils/sceneUtils'

const Combo = ({q,itemId,groupId}) => {
    
    const projectActions = useContext(ProjectContext)
    const qnrActions = useContext(QnrContext)

    const [elementData, setElementData] = useState(getElementData(projectActions.elementsData,q.id_element))
    const [item, setItem] = useState(null)
    const [model, setModel] = useState(null)
    const [activeLayer, setActiveLayer] = useState(null)
    const [value, setValue] = useState(null)
    const [group, setGroup] = useState(null)

    useEffect(() => {
        const layer = projectActions.scenes[1].layers[0].layer_1
        setActiveLayer(layer)

        const data = getElementData(projectActions.elementsData,parseInt(q.id_element))
        setElementData(() => data)
        
        if(layer.groups && q.group){
            const foundGroup = layer.groups.find(group => group.id === q.group);
            setGroup(() => foundGroup)
            if(foundGroup){
                const modelId = getGroupFeatureValue(layer,foundGroup.id,17)
 
                console.log(modelId)
                setModel(modelId)
                setValue(elementData.properties.find(prop => prop.name === 'model').values.find(val => val.value === modelId))
            }
        } else {
            if(parseInt(q.id_element) === 2){
                console.log(layer.areas[0])
                setItem(layer.areas[0].floor)
            } else if(itemId){
                setItem(getElementOfId(layer,parseInt(q.id_element),itemId))
            }
            if(item){
                const modelId = getFeatureValue(item,17)
                setModel(modelId)
                setValue(elementData.properties.find(prop => prop.name === 'model').values.find(val => val.value === modelId))
            }            
        }


        // setPropertyData(() => data ? data.properties.find(property => property.name === option.property) : null)
    },[])

    useEffect(() => {
        if(activeLayer && activeLayer.groups && q.group){
            console.log('IN GROUP')
            const foundGroup = activeLayer.groups.find(group => group.id === q.group);
            setGroup(() => foundGroup)
            if(foundGroup){
                const modelId = getGroupFeatureValue(activeLayer,foundGroup.id,17)
                console.log(modelId)
                setModel(modelId)
                setValue(elementData.properties.find(prop => prop.name === 'model').values.find(val => val.value === modelId))
            }
        } else if(item){
            const modelId = getFeatureValue(item,17)
            setModel(modelId)
            setValue(elementData.properties.find(prop => prop.name === 'model').values.find(val => val.value === modelId))
            // console.log('IN COMBO ',model,elementData.properties.find(prop => prop.name === 'model').values.find(val => val.value === modelId))
        }
        // console.log("REFRESHING",model,value,activeLayer)
    },[projectActions.refresh,item])

    const handleActions = (i) => {
        console.log(i,q.options[i])
        const actions = q.options[i].actions

        actions.forEach(a => {
            switch(a.a){
                case 'changeVariant':
                    console.log('changing variant to ',a.v);
                    qnrActions.setActiveVariant(parseInt(a.v))
                default:
            }
        })
    }

    return <div className='combo-panel'>
        {q.options.map((option,i) => <div key={i}>
            {/* <h4>QID: {q.id} OID:{option.id} TYPE: {option.type} IID: {itemId} M:{model}</h4> */}
            {option.type === 'styleimg' && <div>
                {projectActions.styles && <BigImg source={{img:[`${process.env.PUBLIC_URL}${projectActions.styles[projectActions.project.defaults.style ?? 0].substyles[projectActions.project.defaults.substyle ?? 0].url}`]}} />}
            </div>}
            {option.type === 'modelImg' && <div>
                {(item || group) && value && <BigImg source={value} />}
            </div>}
            {option.type === 'minifloorplan' && <div style={{
                position:'absolute',
                width:'200px',
                height:'200px',
                right:'1em',
                bottom:'1em',
                backgroundColor:'#fff9',
                borderRadius:'1em'
            }}>
                <div className='q-blur-high' style={{
                    position:'absolute',
                    left:'10px',
                    width:'100%',
                    height:'200px',
                    backgroundImage:'url(' + `${process.env.PUBLIC_URL}${projectActions.styles[projectActions.project.defaults.style ?? 0].substyles[projectActions.project.defaults.substyle ?? 0].url}` + ')',
                    backgroundPosition:'right bottom'
                }}></div>
                <div style={{
                    fontSize:'10px',
                    position:'absolute',
                    textAlign:'center',
                    padding:'.5em 0 0',
                    backgroundColor:'#fff9',
                    borderRadius:'1em',
                    width:'200px',
                    height:'200px',
                }}>
                    {option.label && <div>{option.label}</div>}
                    <Canvas width={500} height={500} q={option} plannermode={option.plannermode} itemId={itemId}/>
                </div>
            </div>}
            {option.type === 'shapePlanner' && <ShapePlanner q={option}/>}
            {option.type === 'bigbtn' && <div className='big-btn' style={{
                position:'absolute',
                top:'1em',
                right:'1em',
                height:'40px',
                padding:'1em 2em'
            }} onClick={() => handleActions(i)}>{option.label}</div>}
        </div>)}
    </div>
}

export default Combo