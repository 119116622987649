import { useContext, useState, useEffect } from 'react'
import Navbar from '../components/dashboard/Navbar'
import Sidebar from '../components/dashboard/Sidebar'
import UserPanel from '../components/dashboard/UserPanel'
import ProjectList from '../components/dashboard/ProjectList'
import ProjectsGrid from '../components/dashboard/ProjectsGrid'

const MiniProjects = () => {
  return (
    <div className="dashboard-layout">
        <Navbar />
        <div className='dashboard-content'>
            <Sidebar />
            <ProjectsGrid />
        </div>
        <UserPanel />    
    </div>
  )
}

export default MiniProjects