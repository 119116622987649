import React, { useEffect, useContext, useState } from 'react'
import SaveResult from './SaveResult'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import Question from '../qnr/Question'
import { AiFillCodeSandboxCircle } from 'react-icons/ai'
import { Tabs } from 'antd'
import { getElements } from '../../utils/sceneUtils'

const Modal = ({category}) => {
    const projectActions = useContext(ProjectContext)
    const qnrActions = useContext(QnrContext)
    const sceneActions = useContext(SceneContext)
    const [ activeTab, setActiveTab ] = useState(0)

    const handleClose = () => {
        projectActions.setModalData(null)
        projectActions.setModal(null)
        document.body.style.removeProperty('overflow')
    }

    const handleTabChange = (key) => {
        setActiveTab(key);
        projectActions.setRefresh(!projectActions.refresh)
        // console.log('IN MODAL',key,sceneActions.selected);
    }

    useEffect(() => {
        setActiveTab(() => 0);
        projectActions.setRefresh(!projectActions.refresh)
    },[qnrActions.activeVariant,projectActions.project.defaults.style,projectActions.project.defaults.substyle])

    let count = 0;
    let pcount = 0;
    let shift = 0;

  return (
    <div className='modal'>
        <div className='overlay' onClick={handleClose}></div>
        <div className='content'>
            {category !== true && <div className="modal-panels">
                {category && category.questions && <div className="m-left">
                    {category.m_title && <div className="c-title">{category.m_title}</div>}
                    {category.m_description && <div className="q-description">{category.m_description}</div>}
                    {category.questions.filter(ques => ques.tab && ques.variant === qnrActions.activeVariant).length > 0 
                        && <Tabs defaultActiveKey="0" size="small" onChange={handleTabChange} style={{width:'380px'}}>
                        {category.questions.filter(ques => ques.type === 'tabs' && ques.tab && ques.variant === qnrActions.activeVariant).map((q,i) => {
                            const labels = ["A","B","C","D","E","F","G","H"];
                            const elements = projectActions.scenes[0].layers[0].layer_1.lines;
                            shift = 1
                            return elements.map((el,j) => {
                                count = j;
                                return <Tabs.TabPane 
                                    key={j.toString()}
                                    tab={<span style={{padding:'0 .em'}}>{labels[j]}</span>}
                                >
                                    <Question key={j} q={q} refreshCategory={null} itemId={el.id} />
                                </Tabs.TabPane>
                            })
                        })}
                        {category.questions.filter(ques => ques.type !== 'tabs' && ques.tab && ques.variant === qnrActions.activeVariant).map((q,k) => {
                            const groups = projectActions.scenes[0].layers[0].layer_1.groups;
                            const group = groups && groups.length > 0 ? groups.find(gr => gr.id === q.group) : null;

                            console.log(groups,group)

                            return <Tabs.TabPane 
                                style={{
                                    alignSelf:'flex-start'
                                }}
                                key={(k + count + shift).toString()}
                                tab={q.tab === 'scope' ? projectActions.project.scope === 'BanoProyecto' ? 'Bano' : 'Cocina' : `${q.tab}`}
                            >
                        <Question key={k + count + shift} q={q} refreshCategory={null} />
                        </Tabs.TabPane>
                        })}
                    </Tabs>}
                    {category.questions.filter(ques => !ques.tab && ques.type !== 'tabs').map((q,i) => <Question key={i} q={q} refreshCategory={null} />)}
                </div>}
                {category && category.panels && <div className="m-right">
                    {category.panels.filter(panel => panel.tab && panel.variant === qnrActions.activeVariant).map((panel,j) => {
                        let elements = getElements(projectActions.scenes[0].layers[0].layer_1,null,parseInt(panel.id_element));
                        return panel.type === 'combos' 
                            ? elements.map((element,i) => {
                                pcount = i
                                return parseInt(activeTab) === i && <Question key={i} q={panel} refreshCategory={null} itemId={element.id}/>
                            }) 
                            : parseInt(activeTab) === (j + pcount) && <Question key={j + pcount} q={panel} refreshCategory={null} itemId={panel.group} />
                        }
                    )}
                    {category.panels.filter(panel => !panel.tab).map((q,i) => <Question key={i} q={q} refreshCategory={null}/>)}
                </div>}
            </div>}
            {projectActions.modalData !== null && <div>{projectActions.modalData}</div>}
        </div>
        <div className='close-btn' onClick={handleClose}>X</div>
    </div>
  )
}

export default Modal 