import {Table} from "antd";
import React, {useContext} from "react";
import { Modal } from 'antd';
import { currencyFormat } from "../../utils/formatUtils";
import ImageDetail from "./ImageDetail";
import ModalDescriptionJob from "./ModalDescriptionJob";
import {HiArrowRight} from "react-icons/hi";
import ProjectContext from "../../context/ProjectContext";
import {useLocation} from "react-router-dom";
const { Column } = Table;

/**
 * Table to render jobs (work | detail).
 *
 * @param prop Props.
 * @param prop.type Type list enum 'work' | 'detail').
 * @param prop.jobs Jobs to list (work | detail)
 * @param prop.isBasicBudget If render basic data.
 * @param prop.blur TODO: create doc.
 * @param prop.isActive If project is active.
 * @return React element TableDetailJob component.
 */
const TableDetailJob = ({type, jobs, isBasicBudget, blur= false , isActive}) => {
    const location = useLocation();
    const projectActions = useContext(ProjectContext)
    const allElements = [].concat(
        projectActions.elementsData.elements,
        projectActions.elementsData.items,
        projectActions.elementsData.holes
    )

    const closeModal = () => {
        Modal.destroyAll()
    }

    const showModalDetailJob = (job) => {
        if (
            (location.pathname.includes('summary') ||
            location.pathname.includes('project')) &&
            type === 'detail'
        ) {
            Modal.info({
                content: <ModalDescriptionJob
                    job={job}
                    closeModal={closeModal}
                    allElements={allElements}
                    isActive={isActive}
                />,
                centered: true,
                icon: false,
                style: {minWidth: '65%', maxWidth: '99%'},
                className: 'custom-modal-budget',
                closable: true,
                maskClosable: true,
                okButtonProps: {style: !isActive
                    ? {
                        backgroundColor: isActive ? '#fff' : '#050850FF',
                        border: 0,
                        height: 'auto',
                        padding: 14
                    }
                    : {display: "none"}
                },
                onOk: () => {
                    !isActive && window.open('https://meetings-eu1.hubspot.com/marta-garcia1','_blank')
                },
                okText: <span style={{display: 'flex'}} className="flex items-center gap-3">
                    <span>Quiero hablar con mi asesor</span>
                    <HiArrowRight size="1em"/>
                </span>
            });
        }
    };

    return (
        <>
            <Table
                pagination={false}
                dataSource={jobs.data}
                size={'small'}
                locale={{emptyText: ' '}}
                className={'mt-4'}
            >
                <Column
                    className="w-60 color-blue-dark text-sm text-secondary"
                    title={type === 'detail' ? 'Acabados' : 'Obra'}
                    dataIndex="name"
                    key="name"
                    render={(name, job) => (
                        <div
                            onClick={() => showModalDetailJob(job)}
                            className={
                                (
                                    type === 'detail'
                                    ? (location.pathname.includes('summary') ||
                                        location.pathname.includes('project')
                                        ? 'cursor-pointer' : '') +
                                        ' flex items-center '
                                    : '') + ' text-sm text-secondary'
                            }
                        >
                            {(type === 'detail' && !isBasicBudget) &&  <div className="wideonly">
                                <ImageDetail allElements={allElements} jobName={name} />
                            </div>}
                            <span>{name.replace(/\/|\_|\|/g, ' ')}</span>
                        </div>
                    )}
                />
                <Column className={'text-center'} title="Medidas" dataIndex="measurement" key="measurement"
                        render={(measurement) => (
                            <div className={'text-sm text-secondary'}>
                                {measurement.measurement.toFixed(2)} {measurement.unit}
                            </div>
                        )}
                />
                <Column className={'text-center'} title="Precio" dataIndex="price" key="price"
                        render={(price) => (
                            <div className={'text-sm text-secondary'}>
                                {blur === true ? '999,99' : currencyFormat(price.toFixed(2))}
                            </div>
                        )}
                />
                <Column className={'text-right'}
                        title={currencyFormat(jobs.amountTotal.toFixed(2))}
                        dataIndex="amountTotal" key="amountTotal"
                    render={(amountTotal) => (
                        <div className={'text-sm text-secondary'}>
                            {blur === true ? '999,99' : currencyFormat(amountTotal.toFixed(2))}
                        </div>
                    )}
                />
            </Table>
        </>
    );
}

export default TableDetailJob;
