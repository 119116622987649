import React, { useState, useContext } from 'react';
import { AutoComplete, message } from 'antd';
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import { checkZipCode } from '../../utils/formatUtils';

const { Option } = AutoComplete;

const key = "updatable";

const ZipCodeCities = ({citiesData, refresh, setRefresh, messages}) => {
  const { setDefault, project } = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)
  const projectActions = useContext(ProjectContext)
  const [result, setResult] = useState([]);
  // console.log(data);

  const handleSearch = (value) => {
    let res = [];

    if (!value) {
      res = [];
    } else {
      res = citiesData.cities.filter((city) => city.startsWith(value));
    }
    setResult(res);
  };
  const handleChange = (city) => {
    localStorage.setItem("city", city)
    setDefault('city',city.split(',')[0])
    setRefresh(!refresh)
    const partZip = city.substr(0, 5);
    let regExp = /^\d+$/;
    
    // if(result.indexOf(city) !== -1){
    if(city.length > 4 && regExp.test(partZip) === true && citiesData.cities.find(c => c.split(',')[0] === partZip)){

      // console.log('OK',partZip,checkZipCode(partZip,citiesData))

      // if(checkZipCode(partZip,citiesData)){
        
      //   message.error({
      //     content: messages.cityNotSupported,
      //     key,
      //     duration: 2,
      //   });  
      // }
      projectActions.setZipChange(true)
    } else {
      // console.log('zipcode NOT OK',city,'-',partZip,citiesData.cities.find(c => c.split(',')[0] === partZip))
      projectActions.setZipChange(false)
    }
  }

  return (
    <AutoComplete
      style={{
        width: 200,
      }}
      onSearch={handleSearch}
      onChange={handleChange}
      placeholder="introduce tu código postal"
    >
      {result.map((city) => (
        <Option key={city} value={city}>
          {city}
        </Option>
      ))}
    </AutoComplete>
  );
};

export default ZipCodeCities;