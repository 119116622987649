import { useEffect, useState, useContext, useRef } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { auth, firestore as db } from "../../firebase/config";
import PuffLoader from "react-spinners/PuffLoader";
import Search from './Search'
import DataTable from './DataTable';
import { message, Space } from 'antd'
import AuthContext from '../../context/AuthContext'
import AdminContext from '../../context/AdminContext'
import ModalContext from '../../context/ModalContext'
import { FaPlusSquare, FaTrash, FaInfinity, FaRegClock, FaCalendarWeek } from 'react-icons/fa'
import { SearchOutlined } from '@ant-design/icons';
import TimeToggle from '../widgets/TimeToggle';
import { Form, Input, Button, Select, Checkbox } from 'antd';

const key = "updatable";

const UserList = ({filters = null}) => {

    const navigate = useNavigate()
    const location = useLocation();
  
    const authActions = useContext(AuthContext)
    const adminActions = useContext(AdminContext)
    const modalActions = useContext(ModalContext)

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [edit,setEdit] = useState(false)
    const [delUsers,setDelUsers] = useState([])
    const [delProjects,setDelProjects] = useState(true)
    const [delProjectsMulti,setDelProjectsMulti] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [count, setCount] = useState(1)

    const searchInput = useRef(null);

    const fetchUsers = async () => {
      let allUsers = await adminActions.getAllUsers();
      adminActions.setAllUsers(() => allUsers)
      adminActions.filterUsers();
      return allUsers
    }

    useEffect(() => {
      authActions.setSelectedUser(() => null)
    },[])

    const userQuery = useQuery('users', fetchUsers)
  
    const [newUserForm] = Form.useForm();
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    
    const handleCheck = (e) => {
      setDelProjects(e.target.checked)
      console.log(delUsers)
    }

    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text
  });

    const userColumns = [
      {
        title: 'User Id',
        dataIndex: 'userId',
        key: 'userId',
        ...getColumnSearchProps('userId'),
      },
      {
        title: 'User name',
        dataIndex: 'userName',
        key: 'userName',
        ...getColumnSearchProps('userName'),
      },
      {
        title: 'User email',
        dataIndex: 'userEmail',
        key: 'userEmail',
        ...getColumnSearchProps('userEmail'),
        render: (email => <a href={'mailto:' + email} target="_blank">{email}</a>)
      },
      {
        title: 'User_phone',
        dataIndex: 'userPhone',
        key: 'userPhone',
        ...getColumnSearchProps('userPhone'),
        render: (phone => <a href={'tel:+' + phone}>{phone}</a>)
      },
      {
        title: 'User_type',
        style:{minWidth:'100px'},
        dataIndex: 'userType',
        key: 'userType',
        filters: [
          {
            text: 'Personal',
            value: 'personal',
          },
          {
            text: 'Admin',
            value: 'admin',
          },
          {
            text: 'Pro',
            value: 'pro',
          },
        ],
        onFilter: (value, record) => record.userType.indexOf(value) === 0,
        render: (userType => <span>{userType}</span>)
      },
      {
        title: 'Registered',
        key: 'Registered',
        ...getColumnSearchProps('projectId'),
        render: (user) => {
          var date = new Date(user.accountCreationDate.seconds * 1000);
          // console.log(user.accountCreationDate)
          return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
        }
      },
      {
        title: 'Action',
        key: 'user',
        render: (user) => {
          return (
          <Space size="middle">
            <button onClick={() => {authActions.setSelectedUser(user.userId);navigate('/profile/' + user.userId)}}>Profile</button>
            <button onClick={() => {
              authActions.setSelectedUser(user.userId);
              window.open('/miniprojects/' + user.userId,'_blank');
            }}>Projects</button>
            <button onClick={() => {
              authActions.setSelectedUser(user.userId);
              window.open('/summary/' + user.userId,'_blank');
            }}>Summary</button>
            <button onClick={() => handleDeleteUser(user.userId)}>Delete</button>
          </Space>
        )}
      }
    ]
 
    // useEffect(() => {
    //   console.log('IN LIST',adminActions.filteredUsers)
    //   getUsers(location.state ? location.state.filters : null);
    //   filterUsers(location.state ? location.state.filters : null);
    // }, []);
   
    // useEffect(() => {
    //   filterUsers();
    // },[status,adminActions.userRange])

    // const filterUsers = (filters = null) => {
    //   console.log('USER RANGE',adminActions.userRange)
    //   const now = Math.round(Date.now() / 1000);
    //   if(status === 'success'){
    //     adminActions.setFilteredUsers(() => Object.values(data).filter(user => {
    //       let testType = true;
    //       if(filters !== null){
    //         filters.forEach(f => {
    //           if(Object.keys(f)[0] === 'userType'){
    //             if(user.userType !== Object.values(f)[0]){
    //               testType = false;
    //             }
    //             if(Object.keys(f)[0] === 'time'){
    //               setTime(() => Object.values(f)[0])
    //             }
    //           }
    //         })
    //       }
    //       // console.log('FILTERING',time)
    //       const testTime = user.accountCreationDate.seconds > now - parseInt(timeIcons[time].timespan);
    //       return testTime === true && testType === true;
    //     }))      
    //   }
    // }
  

    const handleUserTypeChange = (e) => {
      console.log(e.target.value)
    }

    const handleFilterProjects = (filters) => {
      
    }

    // const getUsers = async () => {
    //   const users = await db.collection("Users").orderBy("accountCreationDate")
    //   .limit(20)
    //   .get();
    //   // setProject(project.data() ? project.data() : null);
    //   const tempUsers = [];
    //   users.forEach(doc => {
    //     const user = doc.data()
    //     tempUsers.push({userId:doc.id,...user})
    //   })
    //   // console.log(tempUsers)
    //   setUsers(tempUsers)
    // };

    const showProfile = (userID) => {
      navigate('/profile/' + userID);
    }

    const delProfile = () => {

    }

    const showProjects = (userID) => {
      authActions.setSelectedUser(() => userID);
      navigate('/miniprojects');
    }
  
    const handleDeleteUser = (userId) => {
      const delModal = <div>Are You sure You want to delete the user? This aoperation cannot be undone
      <Form.Item label="Delete only user and leave his projects intact ?"><Checkbox onChange={handleCheck} /></Form.Item>
    </div>
    modalActions.setModalData({
      heading: `Are You sure to delete user`,
      subHeading: "del",
      note: "Delete. This operatin cannot be undone",
      body: (
        delModal
      ),
      buttons: [
        { text: "En otro momento", type: "text", onClick: "close" },
        {
          text: "Delete project",
          type: "danger",
          onClick: () => {
            adminActions.deleteUser(userId,delUsers);
            modalActions.setOpen(false);
            setEdit(false);
          }
        }
      ]
    });
    modalActions.setOpen(true);
  }

  const handleDelUsers = () => {
      console.log('IN DEL',selectedRowKeys)
      if(selectedRowKeys.length > 0){
        console.log('deleting',delUsers)
        const delModal = <div>Are You sure You want to delete selected users? This aoperation cannot be undone
        <Form.Item label="Delete only user and leave his projects intact ?"><Checkbox onChange={handleCheck} /></Form.Item>
      </div>
      modalActions.setModalData({
        heading: `Are You sure to delete ${selectedRowKeys.length} users`,
        subHeading: "del",
        note: "Delete. This operatin cannot be undone",
        body: (
          delModal
        ),
        buttons: [
          { text: "En otro momento", type: "text", onClick: "close" },
          {
            text: "Delete SELECTED Users",
            type: "danger",
            onClick: () => {adminActions.deleteUsers(selectedRowKeys,delProjectsMulti);modalActions.setOpen(false);setEdit(false);}
          }
        ]
      });
      modalActions.setOpen(true);
      } else {
        message.error({content:'SELECT USERS'})
      }
    }   

    const handleNewUser = () => {
      const addModal = <Form
        form={newUserForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={onAddNewUser}
      >
        Fill the form to add new 
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'email'}
        label="Email"
        rules={[
          {
            type: 'email',
            required: true
          },
        ]}
      >
      <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="ZipCode"
        name="zipCode"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'phone'}
        label="phone"
      >
      <Input />        
      </Form.Item>
      <Form.Item label="User type" name="profile_type">
          <Select
            options={[
              {
                value: 'personal',
                label: 'Personal',
              },
              {
                value: 'admin',
                label: 'Admin',
              },
              {
                value: 'pro',
                label: 'Pro',
              },
            ]}
          />
        </Form.Item>
      </Form>
      modalActions.setModalData({
        heading: "New User",
        subHeading: "del",
        note: "Add new user",
        body: (
          addModal
        ),
        buttons: [
          { text: "En otro momento", type: "secondary", onClick: "close" },
          {
            text: "Reset",
            type: "secondary",
            onClick: () => newUserForm.resetFields()
          },
          {
            text: "Add user",
            type: "primary",
            danger: "danger",
            onClick: () => newUserForm.submit()
          }
        ]
      });
      modalActions.setOpen(true);
    }

    const onAddNewUser = ({username, email, password, phone, zipCode, profile_type}) => { 
      if(!username || !email || !password || !profile_type){
        return message.error({
          content: 'select profile type',
          key,
          duration: 2,
        }); 
      } else {
        console.log('ADD NEW USER',username, email, password, profile_type)
        auth.createUserWithEmailAndPassword(email,password)
          .then((userCredential) => {
            const user = userCredential.user;
            const userData = {
              email: user.email,
              name: username,
              phone: phone ?? '',
              accountCreationDate: new Date(),
              profile_type: profile_type ?? "personal",
              note:'created',
              label:'new',
              zipCode: zipCode ?? ''
            }
            db.collection("Users")
              .doc(userCredential.user.uid)
              .set({...userData})
              .then(() => {
                message.info({
                  content: 'User created',
                  key,
                  duration: 2
                })
                modalActions.closeModal();
              })
          })
          .catch(() => {
            message.error({
              content:'New user error',
              key,
              duration: 2,
            });
          })
      }
    }

    return (
      <div className="projects-container">
        <div className='d-toolbar'>
          <FaPlusSquare size="2em" title="Add new user" onClick={handleNewUser} style={{cursor:'pointer'}}/>
          <TimeToggle target="users"/>
          <span>{adminActions.filteredUsers.length + ' users found'}</span>
          <Button onClick={handleDelUsers}>DELETE SELECTED</Button>
        </div>
        {userQuery.status === 'loading' && <PuffLoader />}
        {userQuery.status === 'error' && (<div>Error fetching data</div>)}
        {userQuery.status === 'success' && (<div style={{padding:'.5em'}}>
        <DataTable 
          // data={projects.slice((page * perPage), (page * perPage) + perPage)} 
          data={adminActions.filteredUsers}
          tableColumns={userColumns}
          expendable={false}
          // scroll={{x: 500, y: '100vw'}} 
          pagination={{
            pagination: ['none', 'bottomCenter'],
            pageSize: 20
          }}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        /></div>)}
      </div>
    )
  }

export default UserList