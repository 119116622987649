import { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { firebase, firestore as db  } from "../firebase/config";
import AuthContext from '../context/AuthContext'

const Login = () => {

    const [error, setError] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()

    const {setCurrentUser, currentUser, setUser, user} = useContext(AuthContext)

    useEffect(() => {
        localStorage.setItem("user",'')
        localStorage.setItem("userDetails",'')
        localStorage.removeItem("user")
        localStorage.removeItem("userDetails")
        setCurrentUser(null)
        setUser(null)
    },[])

    useEffect(() => {
        localStorage.removeItem('userDetails')
        localStorage.removeItem('user')
    },[user])

    const handleLogin = (e) => {
        e.preventDefault();

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in 
                const currentUser = userCredential.user;
                
                console.log('USER',currentUser,"ID:",currentUser.uid)
                db.collection("Users")
                    .doc(currentUser.uid)
                    .get()
                    .then((user) => {
                        setCurrentUser(currentUser)
                        setUser(user.data())
                        switch(user.data().profile_type){
                            case "personal":
                                navigate('/profile/' + currentUser.uid)
                            break;
                            default: 
                                navigate('/dashboard')
                        }
                    }).catch((err) => console.error('USER ERROR: ',err))
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setError(true)
                console.log(errorCode,errorMessage)
            });
    }

  return (
    <div className='user-popup'>
        <div style={{marginBottom:'1em',textAlign:'center',width:'100%'}}>
            <img src={process.env.PUBLIC_URL + 'homyplan-logo.svg'} alt="HOMYPLAN-LOGO" />
        </div>
        <form onSubmit={handleLogin}>
            <input type="email" placeholder="email" onChange={e => setEmail(e.target.value)}/>
            <input type="password" placeholder="password" onChange={e => setPassword(e.target.value)}/>
            <button type="submit" className='button'>Login</button>
            {error && <div className="user-error">Wrong user or password</div>}
        </form>
        {/* <Link to="/register">If You are already registered, please login.</Link>  */}
    </div>
  )
}

export default Login