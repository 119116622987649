import {useState, useEffect} from 'react'
import { getOldValue } from '../../utils/sceneUtils'
import BigImg from './BigImg'

const ImageSlider = ({refresh,actualLayer,o,elementData,id}) => {

  const [value, setValue] = useState(null)

  useEffect(() => {

    const feature = elementData.properties.find(pro => pro.name === o.property)
    
    if(o.property){
      const oldValue = getOldValue(actualLayer,null,o.e,feature.feature,id)
      const value = feature.values.find((val) => val.value === oldValue)
      value && setValue(value)
    }
    
    console.log('IN SLIDER ',value)
    
  },[o,actualLayer,refresh])

  return (
    <BigImg source={value}/>
  )
}

export default ImageSlider