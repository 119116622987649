import React, {useEffect, useContext, useState, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import ModalContext from '../../context/ModalContext'
import ProjectContext from '../../context/ProjectContext'
import { Button, Modal, Form, Input, Select, Checkbox } from "antd";
import ProjectForm from '../widgets/ProjectForm'
import { FaTrash } from 'react-icons/fa';

const key = "updatable";

const ProjectIcon = ({project}) => {
  // const [open, setOpen] = useState(false)
  const [nam, setName] = useState(project.name)
  const [form, setForm] = useState({
    name:project.name || '',
    userId:project.userId || ''
  })
  const [activeProject, setActiveProject] = useState(project.active)
  // const [activeResult, setActiveResult] = useState(project.results.map((res,i) => {return res.active}))
  const [activeResult, setActiveResult] = useState([])
  const [test, setTest] = useState(false)
  const [editProjectForm] = Form.useForm();

  const modalActions = useContext(ModalContext)
  const projectActions = useContext(ProjectContext)

  const navigate = useNavigate()

  useEffect(() => {
    setForm({
      name:project.name,
      userId:project.userId,
      plan:project.plan,
      access:project.access,
    })
    console.log(project.name)
  },[])

    useEffect(() => {
      console.log(test)
    },[test])

  const handleDelProject = () => {
    const delModal = <div>Are You sure You want to delete project? This aoperation cannot be undone</div>
    modalActions.setModalData({
      heading: "Are You sure to delete project",
      subHeading: "del",
      note: "Delete. This operatin cannot be undone",
      body: (
        delModal
      ),
      buttons: [
        { text: "En otro momento", type: "text", onClick: "close" },
        {
          text: "Delete project",
          type: "danger",
          onClick: () => projectActions.deleteProject(project.projectId)
        }
      ]
    });
    modalActions.setOpen(true);
  }

  const handleProjectActive = (e) => {
    projectActions.updateProjectProperty(project.projectId,'active',e.target.checked)
    setActiveProject(e.target.checked)
  }

  const handleUpdateProject = (e) => {
    // console.log('UPD',editProjectForm)
    // console.log('UPD',setName(() => e.target.value))
    projectActions.updateProject(project.projectId,editProjectForm)
  }
 
  const onActiveResultChange = (e) => {

    // console.log(e.target.value,activeResult)

    const testArray = new Array(3).fill(false)
    
    if(e.target.checked === true){
      testArray[e.target.value] = true;
      // setActiveResult(() => activeResult.forEach((active,i) => i === e.target.value ? true : false));
      setActiveResult(() => [true,true,true]);
      setTest(true)
      console.log('checking',test,testArray,activeResult)
    }
  };

  const handleTest = (e) => {
    setTest(() => true);
    console.log(e,test)
  }

  const handleProjectProperties = (prj) => {

    let resultsActive = prj.results.map((res,i) => {return res.active}) 

    const handleActiveResults = (e) => {
      resultsActive = new Array(3).fill(false)
      if(e.target.checked === true){
        resultsActive[e.target.value] = true
        console.log(resultsActive)
      }
    }

    const propertiesModal = <div key={prj.projectId}>
      <Form
        form={editProjectForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
      >
      <Form.Item label="Name" name='name' initialValue={prj.name}>
        <Input/>
      </Form.Item>
      <Form.Item label="userId" name="userId" initialValue={prj.userId}>        
        <Input/>
      </Form.Item>
      <Form.Item label="plan" name="plan" initialValue={prj.plan}>
        <Select>
          <Select.Option value="basic">Basic</Select.Option>
          <Select.Option value="pro">Pro</Select.Option>
          <Select.Option value="elite">Elite</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="access" name="access" initialValue={prj.access}>
        <Select>
          <Select.Option value="pending">pending</Select.Option>
          <Select.Option value="onboard">dashboard</Select.Option>
          <Select.Option value="accepted">accepted</Select.Option>
        </Select>
      </Form.Item>
      <div>Number of results: {prj.results !== null ? prj.results.length : '-'}</div>
      {/* <Space direction="vertical"> */}
        {prj.results && prj.results.length > 0 && prj.results.map((res,i) => {
        return <div key={i} style={{
          border:res.active ? '1px solid blue' : 'none',
          color:res.active ? 'blue' : 'black'
        }}>&nbsp;
            {Math.round(res.overall * 100) / 100 + ' €'} - {res.description ?? ''} {resultsActive[i] ? 'active' : 'inactive'}
          </div>
        })}
      {/* </Space> */}
      {/* <Space direction="vertical">
        <Checkbox.Group options={prj.results.map((res,i) => {return {label:`${res.overall.toFixed(2)} ${res.description}`,value:i}})} onChange={handleTest}/>
      </Space> */}
    </Form>

    </div>

    modalActions.setModalData({
      heading: "You can change properties of the project here",
      note: `(${prj.active === true ? 'ACTIVE PROJECT' : "INACTIVE PROJECT"})`,
      body: (
        propertiesModal
      ),
      buttons: [
        { text: "Cancel", type: "text", onClick: "close" },
        {
          text: "Delete",
          type: "danger",
          onClick: () => handleDelProject()
        },
        {
          text: "Open in dashboard",
          type: "primary",
          onClick: () => {window.open('/project/' + project.projectId,'_blank'); modalActions.setOpen(false);}
        },
        {
          text: "Update project",
          type: "primary",
          onClick: () => {handleUpdateProject();modalActions.setOpen(false);}
        }
      ]
    });
    // modalActions.setOpen(true);
  }

  return (
    <div className="project-thumb">
      <div className='icon-details' style={{alignItems:'flex-start',fontSize:'12px'}}>
        <label style={{width:'30px',height:'30px'}}>
          <input style={{width:'30px',height:'30px'}} type="checkbox" onChange={handleProjectActive} checked={activeProject}/>{project.scope}
        </label>
        <div>Access: {project.access ? project.access : 'N/A'}<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Plan: {(project.plan && typeof project.plan === 'string') ? project.plan : 'N/A'}</div>
      </div>
      <div className="icon-info" onClick={() => handleProjectProperties(project)}>
        {project.name}
      </div>
      <div>{project.projectId}</div>
      <div className='icon-details'>
        <div style={{cursor:'pointer'}} title="show results" onClick={() => window.open('/results/' + project.projectId, '_blank')}>
          <div style={{textDecoration:'underline'}}>Results: {project.results !== null ? project.results.length : '-'}</div>
          {project.results && project.results.map((res,i) => <div key={i} title={res.description ? res.description : ''}
            style={{
              color:res.active ? 'blue' : "black", 
              border:res.active ? '1px solid blue' : 'none'
            }}
          >&nbsp;{Math.round(res.overall * 100) / 100 + ' €'} : {res.description ? res.description.slice(0, 10) + '...' : ''} {res.active && ' - Active'}&nbsp;</div>)} 
        </div>
        <div className="active" title="Delete project" onClick={() => handleDelProject()}><FaTrash size="1.5em"/></div>
      </div>
    </div>
  )
}

export default ProjectIcon