import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MdDashboard, MdOutlineMapsHomeWork } from 'react-icons/md'
import { AiOutlineUser } from 'react-icons/ai'
import { GrUserWorker, GrTask, GrDocumentStore} from 'react-icons/gr'
import { IoStatsChart, IoChatbubblesOutline, IoCalendarSharp } from 'react-icons/io5'
import { IoMdExit } from 'react-icons/io'
import { HiUserGroup } from 'react-icons/hi'
import AuthContext from '../../context/AuthContext'

const Sidebar = () => {

  const { currentUser, setCurrentUser, user, setUser } = useContext(AuthContext)

    const handleLogout = (e) => {
        setCurrentUser(null)
        setUser(null)
    }
  
  return (
    <div className='sidebar'>
    <div className='top'>
        <ul>
            <p>MAIN</p>
            <Link to="/dashboard"><li><MdDashboard /><span>Dashboard</span></li></Link>
            {user.profile_type === "admin" && <><p>LISTS</p>
              <Link to="/users"><li><HiUserGroup /><span>Users</span></li></Link>
              <Link to="/projects"><li><MdOutlineMapsHomeWork /><span>Projects</span></li></Link>
              <Link to="/clients"><li><GrUserWorker /><span>Contractors</span></li></Link>
              <Link to="/jobs"><li><GrTask /><span>Jobs</span></li></Link>
            </>}
            <p>SERVICE</p>
            {user.profile_type === "admin" && <Link to="/stats"><li><IoStatsChart /><span>Stats</span></li></Link>}
            {user.profile_type === "admin" 
              ? <Link to="/calendar"><li><IoCalendarSharp /><span>Calendar</span></li></Link>
              : <>
                <Link to="/miniprojects"><li><IoCalendarSharp /><span>My Mini Projects</span></li></Link>
                <Link to="/myprojects"><li><IoCalendarSharp /><span>My Projects</span></li></Link>
                <Link to="/calendar"><li><IoCalendarSharp /><span>My Calendar</span></li></Link>
              </>}
            {user.profile_type === "admin" 
              ? <Link to="/documents"><li><GrDocumentStore /><span>Documents</span></li></Link>
              : <Link to="/documents"><li><GrDocumentStore /><span>My Documents</span></li></Link>}
            {user.profile_type === "admin" 
              ? <Link to="/chatroom"><li><IoChatbubblesOutline /><span>Chats</span></li></Link>
              : <Link to="/chatroom"><li><IoChatbubblesOutline /><span>My Chats</span></li></Link>}
        </ul>
    </div>
    <div className='bottom'>
        <hr />
        <ul>
            <p>USER</p>
            <Link to={"/profile/" + currentUser.uid}><li><AiOutlineUser /><span>User Profile</span></li></Link>
            <Link to="/login" onClick={handleLogout}><li><IoMdExit /><span>Logout</span></li></Link>
        </ul>
    </div>
    </div>
  )
}

export default Sidebar