import { createContext, useEffect, useState, useContext } from 'react';
import { getQuestions } from '../utils/formatUtils';
import AuthContext from '../context/AuthContext'

const QnrContext = createContext();

export const QnrProvider = ({ children }) => {

    const {currentUser} = useContext(AuthContext)

    const [qnrData, setQnrData] = useState(null)
    const [qnrCities, setQnrCities] = useState(null)
    const [filteredCategories, setFilteredCategories] = useState(null)
    const [currentQnr, setCurrentQnr] = useState(null)
    const [chapter, setChapter] = useState(0)
    const [activeQ, setActiveQ] = useState(0)
    const [activeC, setActiveC] = useState(null)
    const [zoom, setZoom] = useState(false)
    const [activeTab, setActiveTab] = useState(null)
    const [activeVariant, setActiveVariant] = useState(0)

    useEffect(() => {
        const aQnr = {};
        if(qnrData !== null){
            setCurrentQnr(getQuestions(qnrData))
        }
    },[qnrData])

    useEffect(() => {
        if(qnrData && currentQnr){

            // console.log('USR',currentUser)

            setFilteredCategories(qnrData.categories
                .filter(cc => {
                    let permit = true;
                    if(cc.access){
                        if(cc.access === 'admin' && currentUser !== null){
                            return true
                        } else if (cc.access === 'user' && currentUser !== null) {
                            return true
                        } else if (cc.access === 'anonymous' && currentUser === null) {
                            return true
                        } else {
                            return false
                        }
                    }
                    return true
                })
                .filter(c => {
                if(c.connected){
                    const found = Object.values(currentQnr).filter(cc => {
                        return cc.value === c.connected
                    })
                    return found.length > 0
                } else {
                    return true
                }
            }))
        }

    },[currentQnr,chapter])

    const zoomImg = (e) => {
        setZoom(!zoom);
        // console.log('zoom',currentQnr)
    }



    return (
        <QnrContext.Provider value={{ 
            activeC, setActiveC, 
            activeTab, setActiveTab,
            activeVariant, setActiveVariant,
            activeQ, setActiveQ, 
            chapter, setChapter, 
            qnrData, setQnrData,
            filteredCategories, setFilteredCategories,
            currentQnr, setCurrentQnr,
            qnrCities,setQnrCities,
            zoom, zoomImg,
        }}>{children}</QnrContext.Provider>
    )
}

export default QnrContext;