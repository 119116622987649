import {useEffect, useState} from 'react'
import { getSumResults, getRangeDays, getStartDates } from '../../utils/calcUtils'
import {currencyFormat, formatDate} from "../../utils/formatUtils";

const Overall = ({projects = []}) => {

    const [sum, setSum] = useState('N/A')
    const [days, setDays] = useState('N/A')
    const [startDates, setStartDates] = useState('00-00-0000')

    useEffect(() => {
        setSum(() => getSumResults(projects))
        setDays(() => getRangeDays(projects))

        setStartDates(() => formatDate(getStartDates(projects.filter(prj => prj.active === true))))
    },[projects])

  return (
    <div className='s-overall'>
        <div className="s-output">
            <div className="s-row"><span className="bolder">Tiempo:</span><span className="s-subtitle" style={{fontSize:'24px'}}>{isNaN(days) ? 'N/A' : days} días</span></div>
            <div className="s-row">
                <span className="s-comment">Inicio estimado</span>
                <span className="bolder" style={{marginTop:'1em',paddingRight:'0.8em',fontSize:'12px'}}>{startDates === 'Invalid date' ? 'N/A' : startDates}</span>
            </div>
        </div>
        <div className="s-output">
            <div className="s-row">
                <span><span className="bolder">Precio total:</span><br/><span className="s-comment">(IVA no incluido)</span></span>
                <span className="s-subtitle" style={{fontSize:'24px'}}>{currencyFormat(sum)}</span>
            </div>
        </div>
    </div>
  )
}

export default Overall 