/**
 * All events allowed by amplitude.
 */
export const EnumEventsAmplitude = {

  // Module AdminPaymentProject
  /** Make payment PSD2 event */
  MakePaymentPSD2Invoice: 'MakePaymentPSD2Invoice',

  /** Approve payment invoice event */
  ApprovePaymentInvoice: 'ApprovePaymentInvoice',

  /** Create invoice event */
  CreateInvoice: 'CreateInvoice',

  /** Update invoice event */
  UpdateInvoice: 'UpdateInvoice',

  /** Delete invoice event */
  DeleteInvoice: 'DeleteInvoice',

  /** Change status payment event */
  ChangeStatusPayment: 'ChangeStatusPayment',

  /** View detail payment event */
  ViewDetailPayment: 'ViewDetailPayment',

  /** Confirm payment PSD2 event */
  ConfirmPSD2Payment: 'ConfirmPSD2Payment',
  // End Module AdminPaymentProject

  /** Change route event */
  ChangeRoute: 'ChangeRoute',

  /** Check Budget */
  CheckBudget: 'CheckBudget',

  // Start of Onboarding Module Events
  /**
   * Onboarding Bathroom Equipment Viewed
   * 
   * Displayed when a user view the bathroom equipment screen on the onboarding flow
   * 
   */
   onb_equipment_viewed: "onb_equipment_viewed",
   
  /** 
   * Onboarding Electric Equipment Viewed 
   * 
   * Displayed when a user view the measurement screen on the onboarding flow
   * 
  */
  onb_electric_equipment_viewed: 'onb_electric_equipment_viewed',

  /** 
   * Onboarding Location Viewed 
   * 
   * Displayed when a user view the location screen on the onboarding flow
   * 
  */
  onb_location_viewed: 'onb_location_viewed',

  /** 
   * Onboarding Logged In
   * 
   * When a user is logged in
   * 
  */
  onb_logged_in: 'onb_logged_in',

  /**
   * Onboarding Measurements Viewed
   * 
   * Displayed when a user view the measurement screen on the onboarding flow
   */
  onb_measurements_viewed: 'onb_measurements_viewed',

  /**
   * Onboarding New Electric Viewed
   * 
   * Displayed when a user view the new electric equipment screen on the onboarding flow
   */
  onb_new_electric_equipment_viewed: 'onb_new_electric_equipment_viewed',

  /**
   * Onboarding New Equipment Viewed
   * 
   * Displayed when a user view the new equipment screen on the onboarding flow
   */
  onb_new_equipment_viewed: 'onb_new_equipment_viewed',

  /**
   * Onboarding Quality Viewed
   * 
   * Displayed when a user view the material quality screen on the onboarding flow
   */
  onb_quality_viewed: 'onb_quality_viewed',

  /**
   * Onboarding Qoute Section Opened
   * 
   * When any dropdown from "presupuesto" screen is clicked
   */
  onb_quote_section_opened: 'onb_quote_section_opened',

  /**
   * Onboarding Qoute Viewed
   * 
   * Displayed when a user view the quote screen on the onboarding flow
   */
  onb_quote_viewed: 'onb_quote_viewed',

  /**
   * Onboarding Registration Viewed
   * 
   * Displayed when a user view the registration screen on the onboarding flow
   */
  onb_registration_viewed: 'onb_registration_viewed',

  /**
   * Onboarding Renovation Plan Viewed
   * 
   * Displayed when a user view the renovation screen on the onboarding flow
   */
  onb_renovation_plan_viewed: 'onb_renovation_plan_viewed',

  /**
   * Onboarding Set up Call Clicked
   * 
   * When "Agendar llamada" button is clicked on "Presupuesto" screen 
   */
  onb_set_up_call_clicked: 'onb_set_up_call_clicked',

  /**
   * Onboarding Bathroom Shape Viewed
   * 
   * Displayed when a user view the bathroom shape screen on the onboarding flow
   */
  onb_shape_viewed: 'onb_shape_viewed',

  /**
   * Onboarding Bathroom Style Viewed
   * 
   * Displayed when a user view the bathroom style screen on the onboarding flow
   */
  onb_style_viewed: 'onb_style_viewed',

  /**
   * Onboarding Bathroom Height Viewed
   * 
   * Displayed when a user view the walls height screen on the onboarding flow (the first step)
   */
  onb_height_viewed: 'onb_height_viewed',

  /**
   * Onboarding New Equipment
   * 
   * Displayed when a user view the new equipment screen on the onboarding flow
   */
  onb_new_equipment: 'onb_new_equipment',

  /**
   * Onboarding Equipment Changes Viewed
   * 
   * Displayed when a user view the changes on their actual equipment screen on the onboarding flow
   */
  onb_equipment_changes_viewed: 'onb_equipment_changes_viewed',

  /**
   * Continue Clicked
   * 
   * When any "continue" button is clicked included "ver prepuesto" button. This button will provide extra info from its stage of the onboarding when its clicked
   */
  continue_clicked: 'continue_clicked',

  /**
   * Onboarding Call Form Sent
   * 
   * When the form of setting up a call is sent succesfully
   */
  onb_call_form_sent: 'onb_call_form_sent',

  /**
   * Go Back Clicked
   * 
   * When any "go back" button is clicked
   */
  go_back_clicked: 'go_back_clicked',

  /**
   * Onboarding User Registration
   * 
   * Displayed when a user register succesfully
   */
  registration_completed: 'registration_completed',

  /**
   * Onboarding WhatsApp Clicked
   * 
   * When a user clicks on the "WhatsApp" button
   */

  whatsapp_clicked: 'whatsapp_clicked',

  /**
   * Onboarding WhatsApp Estimate Clicked
   * 
   * When a user clicked on the "Recibe tu presupuesto por WhatsApp" button
   */
  whatsapp_estimate_clicked: 'whatsapp_estimate_clicked',

  /**
   * Onboarding WhatsApp Estimate Sent
   * 
   * When a user send the form to receive their estimage via WhatsApp
   */
  whatsapp_estimate_sent: 'whatsapp_estimate_sent',

  /**
   * Onboarding Welcome Viewed
   * 
   * Displayed when a user view the initial screen
   */
   onb_welcome_viewed: 'onb_welcome_viewed',

  /**
   * Kitchen Onboarding Welcome Viewed
   * 
   * Displayed when a user view the initial Kitchen screen
   */
    kitchen_onb_welcome_viewed: 'kitchen_onb_welcome_viewed',

  /**
   * Onboarding Kitchen Shape Viewed
   * 
   * Displayed when a user view the kitchen shape screen on the onboarding flow
   */
   kitchen_onb_shape_viewed: 'kitchen_onb_shape_viewed',
  
  /**
   * Onboarding Kitchen Layout Viewed
   * 
   * Displayed when a user view the Kitchen layout screen on the onboarding flow
   */
   kitchen_onb_layout_viewed: 'kitchen_onb_layout_viewed',
  
  /**
   * Onboarding Kitchen Side Viewed
   * 
   * Displayed when a user view the Kitchen side screen on the onboarding flow
   */
   kitchen_onb_side_viewed: 'kitchen_onb_side_viewed',
  
  /**
   * Onboarding Kitchen Countertop Viewed
   * 
   * Displayed when a user view the Kitchen countertop screen on the onboarding flow
   */
   kitchen_onb_countertop_viewed: 'kitchen_onb_countertop_viewed',

  /**
   * Onboarding Kitchen New Equipment
   * 
   * Displayed when a user view the new Kitchen equipment screen on the onboarding flow
   */
   kitchen_onb_new_equipment: 'kitchen_onb_new_equipment',

  /**
   * Onboarding Kitchen New Electric Viewed
   * 
   * Displayed when a user view the new Kitchen electric equipment screen on the onboarding flow
   */
   kitchen_onb_new_electric_equipment_viewed: 'kitchen_onb_new_electric_equipment_viewed',

   /**
   * Onboarding Kitchen Style Viewed
   * 
   * Displayed when a user view the kitchen style screen on the onboarding flow
   */
   kitchen_onb_style_viewed: 'kitchen_onb_style_viewed',

   /** 
   * Onboarding Kitchen Location Viewed 
   * 
   * Displayed when a user view the Kitchen location screen on the onboarding flow
   * 
   */
   kitchen_onb_location_viewed: 'kitchen_onb_location_viewed',

   /**
   * Onboarding Kitchen Registration Viewed
   * 
   * Displayed when a user view the Kitchen registration screen on the onboarding flow
   */
   kitchen_onb_registration_viewed: 'kitchen_onb_registration_viewed',

   /**
   * Onboarding Kitchen Qoute Viewed
   * 
   * Displayed when a user view the Kitchen quote screen on the onboarding flow
   */
   kitchen_onb_quote_viewed: 'kitchen_onb_quote_viewed'

  // End of Onboarding Module Events
}
