import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDGvhS1dXdYpBmyja_XDuTWrCAhtwn1KEk",
  authDomain: "homyplan-develop.firebaseapp.com",
  databaseURL: "https://homyplan-develop-default-rtdb.firebaseio.com",
  projectId: "homyplan-develop",
  storageBucket: "homyplan-develop.appspot.com",
  messagingSenderId: "957891998003",
  appId: "1:957891998003:web:b0ba8fbf4c67626cbe0e46",
};

const app = firebase.initializeApp(firebaseConfig);

// const app = firebase.initializeApp({
//   apiKey: "AIzaSyCGs1dsFwa-Nwhwd-v1R6OyluiCUwAdnzk",
//   authDomain: "wds-my-auth.firebaseapp.com",
//   projectId: "wds-my-auth",
//   storageBucket: "wds-my-auth.appspot.com",
//   messagingSenderId: "830963386494",
//   appId: "1:830963386494:web:4c8ecbfbf7669b33f3702f"
// });

const storage = firebase.storage();
const firestore = firebase.firestore();
const database = firebase.database();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const auth = app.auth();

export { firebase, auth, firestore, storage, database, timestamp };

export default app;
