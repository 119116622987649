import { useContext, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Collapse, Image } from 'antd'
import PuffLoader from "react-spinners/PuffLoader";
import ShapePlanner from '../planner/ShapePlanner'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import {getDateOfBudget} from "../../utils/budgetUtils";
import {currencyFormat} from "../../utils/formatUtils";
import Budget from '../budget/Budget'
import moment from 'moment'


const Output = ({wideonly=true, projects}) => {
  const { Panel } = Collapse;

  const [result,setResult] = useState(null)
  const [expBudgetDate, setExpBudgetDate] = useState('00/00/0000')

  const projectActions = useContext(ProjectContext) 
  const sceneActions = useContext(SceneContext) 


  
  const handleOpenResult = (result = null) => {
    projectActions.setModalData(
      <div className={'flex flex-col justify-center items-center w-full h-full'}>
        <div className={'p-4 modal-budget-basic bg-white overflow-auto'}>
          {result !== null
            ?
            <Budget result={result} isCTA={true} isTitle={true} expBudgetDate={expBudgetDate}/>
            :
            <PuffLoader />
          }
        </div>
      </div>)
    projectActions.setModal(true);
  }

  useEffect(() => {
    setResult(() => projectActions.results[0])

    if(projectActions.project.created_At){
      setExpBudgetDate(() => moment(projectActions.project.created_At.seconds * 1000).add(10, 'days').format('DD/MM/YYYY'));
    }

  },[projectActions.results[0]],sceneActions.refresh)

  return (
    <div className={'r-output overflow-auto bg-white'}>
      <div className="s-output">
        <div className="flex">
          <div>
            <div className="s-text-small">Duración:</div>
             <div className='c-title' style={{margin:0}}>{result ? getDateOfBudget(result.startDate, result.endDate) : 'Loading...'}</div>
            <div className="s-comment">Inicio estimado: {moment(new Date()).add(21, 'days').format('DD/MM/YYYY')} </div>
          </div>
          <div>
            <div className="s-text-small">Precio total:</div>
            <div className='c-title' style={{margin:0}}>{result ? result.overall && currencyFormat(result.overall.toFixed(2)) : 'Loading...'}</div>
            <div className="s-comment">IVA no incluido</div>
          </div>
        </div>
        <div className='q-link' style={{marginTop:'1em'}} onClick={() => handleOpenResult(result)}><img src={process.env.PUBLIC_URL + '/icons/ExternalLink.svg'}/>Ver presupuesto detallado</div>
      </div>
      {/* {projectActions.project.name && <h2>{projectActions.project.name}</h2>}
      <div className={'flex justify-between gap-3'} >
        <p className={'m-0'}>Duración:</p>
        <p className={'m-0'}>Precio total:</p>
      </div>
      <div className={'flex justify-between gap-3 price-total font-bold text-xl'} >
        <p>{getDateOfBudget(result.startDate, result.endDate)} </p>

        <div>
            <p className={'c-title'}>
              {result.overall && currencyFormat(result.overall.toFixed(2))}
            </p>
            <p className={' _textDataGrayIva'}>IVA no incluido</p>
        </div>
      </div>

        <p>Fecha estimada de inicio {result.startDate ? result.startDate : ' N/A'} </p>
        <p className="exp-date">Fecha límite de confirmación {result.expDate ? result.expDate : ' N/A'} </p>

      {result.description && <h3>{result.description}</h3>}

      <div className='q-link' onClick={() => handleOpenResult(result)}>Ver presupuesto detallado</div> */}
    </div> 
  )
}

export default Output;
