import { useEffect, useRef, useContext } from "react";
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { DragControls } from 'three/examples/jsm/controls/DragControls'
import SceneContext from '../../context/SceneContext'
import ProjectContext from '../../context/ProjectContext'

const Scene = ({del}) => {

  const mountRef = useRef(null);

  const sceneActions = useContext(SceneContext)
  const projectActions = useContext(ProjectContext)

  useEffect(() => {

    const vertices = projectActions.scenes[0].layers[0].layer_1.vertices;
    const elements = projectActions.scenes[0].layers[0].layer_1.lines;

    console.log(elements,vertices)

    var scene = new THREE.Scene();
    // var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var camera = new THREE.PerspectiveCamera( 75, 700/500, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer( { alpha: true } );

    renderer.setSize( 700, 500 );
    mountRef.current.appendChild( renderer.domElement );

    vertices.forEach(v => {
        let geometry = new THREE.BoxGeometry( 0.1, 0.1, 1 );
        let material = new THREE.MeshLambertMaterial( { color: 0xc8c8c8 } );
        let cube = new THREE.Mesh( geometry, material );
        cube.position.set(v.x, v.y, 0)
        scene.add( cube );
    })

    // var geometry = new THREE.BoxGeometry( 1, 1, 1 );
    // var material = new THREE.MeshLambertMaterial( { color: 0xc8c8c8 } );
    // var cube = new THREE.Mesh( geometry, material );

    var spotLight = new THREE.SpotLight(0xffffff);
    spotLight.position.set(100, 350, 200);
    scene.add(spotLight);

    // scene.add( cube );
    camera.position.z = 5;

    const orbitControls = new OrbitControls(camera, renderer.domElement)

    // const dragControls = new DragControls([cube], camera, renderer.domElement)
    // dragControls.addEventListener('dragstart', function (event) {
    //     orbitControls.enabled = false
    //     event.object.material.opacity = 0.33
    // })
    // dragControls.addEventListener('dragend', function (event) {
    //     orbitControls.enabled = true
    //     event.object.material.opacity = 1
    // })

    var animate = function () {
      requestAnimationFrame( animate );
      // cube.rotation.x += 0.01;
      // cube.rotation.y += 0.01;
      renderer.render( scene, camera );
    };

    let onWindowResize = function () {
      camera.aspect = 700 / 500;
      camera.updateProjectionMatrix();
      renderer.setSize( 700, 500 );
    };

    window.addEventListener("resize", onWindowResize, false);

    animate();
    if(mountRef.current){
      // return () => mountRef.current.removeChild( renderer.domElement);
    }
  }, []);

  return (
    <div ref={mountRef}></div>
  );
}

export default Scene;