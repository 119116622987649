import React, {useState, useEffect, useContext} from 'react'
import ProjectContext from '../../context/ProjectContext'
import QnrContext from '../../context/QnrContext'
import SceneContext from '../../context/SceneContext'
// import { angleBetweenTwoPoints, verticesDistance, extendLine, getPolygonCentroid } from '../../utils/geometry'
import Canvas from './Canvas'
import Scene from './Scene'
// import Range from './Range'
// import ItemsMenu from './ItemsMenu'
// import MaterialsMenu from './MaterialsMenu'
// import WallCanvas from './WallCanvas'
// import { ContinuousSizeLegend } from 'react-vis'
// import { scenes } from '../../Data/scenes'
import { AiOutlineEdit } from 'react-icons/ai'
import { BsArrowsMove } from 'react-icons/bs'

const ShapePlanner = ({q}) => {
  const sceneActions = useContext(SceneContext)

  useEffect(() => {
    console.log('In SP',sceneActions.viewMode)
  },[sceneActions.viewMode])

  return (
      <div className="flex justify-center items-center h-full">
          <div className='rc-container justify-center'>
              <Canvas style={{display:sceneActions.viewMode === false ? 'block' : 'none'}} plannermode={q.plannermode} width={700} height={500} />
              <div style={{
                display:sceneActions.viewMode === true ? 'block' : 'none'
              }}><Scene width={700} height={500}/></div>
              {/* <div className="q-btn rc-mode-btn test" onClick={() => sceneActions.setViewMode(!sceneActions.viewMode)}>{sceneActions.viewMode === true ? "2D" : "3D"}</div> */}
              {/* {q.plannermode === 'dimensions' && <div className="rc-toolbar test">
        <div className="rc-btn" onClick={() => sceneActions.setViewMode(!sceneActions.viewMode)}><AiOutlineEdit /></div>
        <div className="rc-btn" onClick={() => sceneActions.setViewMode(!sceneActions.viewMode)}><BsArrowsMove /></div>
      </div>} */}
          </div>
      </div>
  )
}

export default ShapePlanner
