import React, { useContext } from 'react'
import QnrContext from '../../context/QnrContext'
import ProjectContext from '../../context/ProjectContext'

const Zoom = () => {

    const { currentQnr, zoomImg } = useContext(QnrContext)
    const { project } = useContext(QnrContext)

    return (
        <div className='q-overlay' style={{background:'#0009'}} onClick={zoomImg}>
            Zoom
        </div>
    )
}

export default Zoom