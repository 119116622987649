import { useEffect, useState, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import { getPrice } from '../../utils/calcUtils'

const Pricetag = ({combinedName}) => {

    const projectActions = useContext(ProjectContext) 

    const [ price, setPrice ] = useState( 'No disponible' )

    useEffect(() => {
        const price = getPrice(projectActions.calcData,combinedName);
        setPrice(() => price)
    },[combinedName])

  return (
    price ? <div className='q-price'>{price} €</div>
        : <><div style={{textAlign:'center',padding:'1em'}}>No disponible</div><div className="debug">{combinedName}</div></>
  )
}

export default Pricetag