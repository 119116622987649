import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import ModalContext from "../../context/ModalContext";
import AuthContext from '../../context/AuthContext'
import AdminContext from '../../context/AdminContext'
import ProjectContext from '../../context/ProjectContext'
import { useNavigate } from 'react-router-dom'
import { auth, firestore as db } from "../../firebase/config";
import PuffLoader from "react-spinners/PuffLoader";
import Search from './Search'
import ProjectIcon from './ProjectIcon'


const ProjectsGrid = () => {
    const authActions = useContext(AuthContext)
    const modalActions = useContext(ModalContext);
    const projectActions = useContext(ProjectContext)
    const adminActions = useContext(AdminContext)

    const navigate = useNavigate()
    const params = useParams();

    const [selectedUser, setSelectedUser] = useState(null);
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [loaded, setLoaded] = useState(false);
  
    useEffect(() => {
        getProject();
        console.log('GRID',authActions.currentUser,projects,params);
        // toggleModal();
    }, [projectActions.refresh]);

    useEffect(() => {
        if(params){
            console.log('wchodzi')
            setSelectedUser(() => params.userId)
            authActions.setSelectedUser(params.userId)
        } else {
            setSelectedUser(() => authActions.selectedUser || authActions.selectedUser !== null ? authActions.selectedUser : authActions.currentUser.uid);
        }
        projectActions.setRefresh(!projectActions.refresh)
    },[])

    const getProject = async () => {  
        // console.log('USR',authActions.user)
        const projects = await db.collection("Projects").orderBy("created_At")
        .where("userId", "==", selectedUser)
        .limit(20).get();
        const tempProjects = [];
        projects.forEach(doc => {
          const project = doc.data()
          tempProjects.push({projectId:doc.id,...project})
        })
        setProjects(tempProjects);
        setLoaded(() => true)
    };

    const handleNewProject = () => {
        modalActions.setModalData({
            heading: "Nuevo miniproyecto",
            subHeading: "Nuevo miniproyecto",
            note: "Select scope of new project.",
            body: (
                <div className='projects-container'>
                    <div className="project-thumb" onClick={() => navigate('/bano',{target:'_blank'})}>Bano Proyecto</div>
                    <div className="project-thumb" onClick={() => navigate('/cocina',{target:'_blank'})}>Cocina Proyecto</div>
                </div>
            )})
    }

    return (
        <div className="projects-container">
            {loaded ? <>
                <div className="project-thumb" style={{
                    fontSize:'3em'
                }}>
                    <div className="icon-info" onClick={() => handleNewProject()}>+</div>
                </div>
                {projects.length > 0 
                    ? projects.map((project,i) => <ProjectIcon key={i} project={project}/>)
                    : <h3>No projects found</h3>}
                </>            
            : <div style={{
                position:'absolute',
                left:'50%',
                top:'50%',
                transform:'translate(-50%, -50%)'
            }}><PuffLoader /></div>
            }
        </div>
    )
}

export default ProjectsGrid