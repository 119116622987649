import { useContext, useEffect, useRef } from 'react'
import Question from './Question'
import QnrContext from '../../context/QnrContext'
import { amplitudeEvent, EnumActionsAmplitude } from "../../utils/amplitude"

const Qlayout = ({ id, category }) => {
  const { activeQ,currentQnr, filteredCategories } = useContext(QnrContext)
  const leftRef = useRef()

  useEffect(() => {
    if (category.amplitudePageViewEvent) {
      if(category.amplitudePageViewEvent.action == EnumActionsAmplitude.PageView) {
        amplitudeEvent(category.amplitudePageViewEvent.name, "", category.amplitudePageViewEvent.action)
      }
    }
    // console.log(leftRef.current.scrollY,layoutRef.current.scrollY)
    leftRef.current.scrollTo({
      top:0,
      behavior:"smooth"
    })
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  }, [activeQ])

  return (
    <div id={"slide_" + id} className="q-layout">
        <span className="debug" style={{position:'absolute'}}>[{category.id}]</span>
        <div className="q-left" ref={leftRef}>
          <div className="q-content">
            {category.id === 'c-0'&& category.panels && category.panels.find(p => p.type === 'imgbig') && <div className="q-left-img mobileonly" style={{
              background:'url(' + process.env.PUBLIC_URL + category.panels.find(p => p.type === 'imgbig').options[0].img + ')',
              backgroundPosition:'center',
              backgroundSize:'cover',
            }}></div>}
            {category.c_name && <p className="q-name" dangerouslySetInnerHTML={{__html: category.c_name + '&nbsp'}} />}
            {category.c_title && <p className="c-title" style={{textAlign:category.panels ? 'left' : 'center'}} dangerouslySetInnerHTML={{__html: category.c_title }}/>}
            {category.c_subtitle && <p className="q-subtitle" dangerouslySetInnerHTML={{__html: category.c_subtitle}} />}
            {category.c_description && <p className="q-description" dangerouslySetInnerHTML={{__html: category.c_description}} />}
            {category.c_message && <div className="q-message">
              {(category.c_message && category.c_message.icon) && <span className="q-icon">{category.c_message.icon}</span>}
              <p dangerouslySetInnerHTML={{__html: category.c_message.text}} />
            </div>}
            {category.questions && category.questions.map((q,i) => {
              return <Question key={i} q={q} />})}
          </div>                
        </div>
        {category.panels && <div className={category.wideonly ? "q-right wideonly" : "q-right"}>
          {category.panels && category.panels.map((q,i) => <Question key={i} q={q} />)}
        </div>}
    </div>
  )
}

export default Qlayout
