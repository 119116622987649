import { Form, Radio,  Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';

const defaultExpandable = {
  expandedRowRender: (record) => <p>{record.description}</p>,
};

const DataTable = ({
  data = [], 
  tableColumns,
  bordered,
  loading = false,
  size,
  expandable = true,
  title = false,
  showHeader = true,
  footer = false,
  // rowSelection,
  scroll={x: 240, y: '100vw'},
  tableLayout={undefined},
  pagination={
    position: ['none', 'bottomLeft'],
  },
  selectedRowKeys,setSelectedRowKeys
}) => {

  const [ellipsis, setEllipsis] = useState(false);


  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // useEffect(() => {
  //   console.log('IN TABLE',data);
  // },[])

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
 
  return (
    <div style={{maxWidth:'1250px'}}>
      <Table
        bordered={bordered}
        loading={loading}
        size={size}
        expandable={{expandedRowRender: (record) => ('OVERALL:' + record.overall)}}
        title={title}
        showHeader={showHeader}
        footer={footer}
        rowSelection={rowSelection}
        scroll={scroll}
        tableLayout={tableLayout}
        onChange={handleChange}
        pagination={pagination}
        columns={tableColumns}
        dataSource={data ? data : []}
      />
    </div>
  );
};

export default DataTable;