import { useRef, useState, useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useParams } from 'react-router-dom'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import PuffLoader from "react-spinners/PuffLoader";
import { database, firestore as db } from "../firebase/config";
import ModalContext from "../context/ModalContext";
import AdminContext from '../context/AdminContext';
import CalcContext from '../context/CalcContext';
import ProjectContext from '../context/ProjectContext'
import StyleImg from '../components/widgets/StyleImg';
import Labels from '../components/widgets/Labels';
import Thumbs from '../components/widgets/Thumbs';
import Output from '../components/budget/Output';
import Overall from '../components/budget/Overall';
import Modal from '../components/widgets/Modal';
import Table from '../components/results/Table';
import Budget from '../components/budget/Budget';
import Budgets from '../components/budget/Budgets';
import { HiArrowRight, HiOutlineInformationCircle } from 'react-icons/hi';
import { AiOutlineMail, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BsChevronExpand, BsTelephone } from 'react-icons/bs';
import { getScope } from '../utils/budgetUtils';
import { NoToneMapping } from 'three';
import { IoGameController } from 'react-icons/io5';
import { getStartDates } from '../utils/calcUtils';
import { formatDate } from "../utils/formatUtils";


const Summary = () => {
  let params = useParams();
  const navigate = useNavigate();
 
  const startCountRef = database.ref("14eDFn5tD9pjzebcLcnuOcs6bA53XO2r3psezHtCP9wc")

  const { toggleModal, setModalData } = useContext(ModalContext);
  const adminActions = useContext(AdminContext)
  const projectActions = useContext(ProjectContext)
  const calcActions = useContext(CalcContext)

  const [activeProjects, setActiveProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [activeResult, setActiveResult] = useState(null);
  const [ debug, setDebug ] = useState(false) 
  const [ show, setShow ] = useState(false)
  const [ expDate, setExpDate ] = useState(false)
  const [ showShadow, setShowShadow ] = useState(false)
  const [ summaryScope, setSummaryScope ] = useState('summary_bano')

  const debugRef = useRef(null)

  const fetchProjects = async () => {
    let allProjects = await adminActions.getProjects(params.userId);
    setActiveProject(() => allProjects[0])
    setActiveProjects(() => allProjects.filter(prj => prj.active === true))
    setActiveResult(() => allProjects[0].results[0])

    // console.log('IN SUMMARY ALL',allProjects.filter(prj => prj.active === true))


    const activeProjects = allProjects.filter(prj => prj.active === true)

    if(activeProjects.length > 0){
      projectActions.setProject(() => ({
        ...projectActions.project,
        projectId:activeProjects[0].key ?? allProjects[0].key,
        access:activeProjects[0].access ?? allProjects[0].access,
        scope:activeProjects[0].scope ?? allProjects[0].scope,
        zipCode:activeProjects[0].zipCode ?? allProjects[0].zipCode,
        defaults:activeProjects[0].defaults ?? allProjects[0].defaults,
        name:activeProjects[0].name ?? allProjects[0].name,
        images:activeProjects[0].images ?? allProjects[0].images,
        results:activeProjects[0].results ?? allProjects[0].results,
        scenes:activeProjects[0].scenes ?? allProjects[0].scenes
      }))

      projectActions.loadProject({projectID:activeProjects[0].key ?? allProjects[0].key});

      setSummaryScope(() => activeProjects[0].scope === 'BanoProyecto' ? 'summary_bano' : 'summary_cocina')      
    }


    return allProjects
  }

  const fetchData = async () => {
    let fetched = await fetch(process.env.PUBLIC_URL + '/qnr/summaryData_es.json')
    return fetched.json();
  }

  const fetchElements = async () => {
    let fetched = await fetch(process.env.PUBLIC_URL + '/elements/elements.json')
    return fetched.json();
  }
  
  const projectQuery = useQuery('users', fetchProjects)
  const dataQuery = useQuery('summaryData', fetchData)
  const elementsQuery = useQuery('elementsData', fetchElements)
  const calcQuery = useQuery('calcData', () => {
        startCountRef.once("value", (snapshot) => {
        const data = snapshot.val();
        projectActions.setCalcData(data)
    })
  })
  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 10) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    });
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "white";
    // "#FBFBF9";
  },[])
  
  useEffect(() => {
    setExpDate(() => formatDate(getStartDates(activeProjects.filter(prj => prj.active === true),'expDate')))
  },[activeProjects])

  useEffect(() => {
    if(elementsQuery.status === 'success'){
      projectActions.setElementsData(() => elementsQuery.data)
    }
  },[elementsQuery.status])

  const handleContact = (how) => {
    switch(how){
      case 'whatsup':
        window.open('https://wa.me/34613662008?text=Please%20help%20with%20HOMYPLAN%20project','_blank')
      break;
      case 'calendly':
        window.open('https://meetings-eu1.hubspot.com/marta-garcia1','_blank')
      break;
    }
  }

  const component = (comp) => {
    switch (comp.type){
      case 'line':
        return <hr className='mobileonly' style={{margin:'2em 0', borderTop:'grey'}} />
      case 'output':
        return <Output result={activeResult} link={true} />
      case 'text':
        return <div className='s-text' dangerouslySetInnerHTML={{__html:comp.content}} />
      case 'contact':
        return <div>
          <div style={{display:'flex',margin:'1em 0',gap:'1em'}}>
            {comp.img && <div style={{
              width:'45px',
              height:'45px',
              borderRadius:'50%',
              backgroundSize:'cover',
              backgroundPosition:'center',
              backgroundImage:`url(${process.env.PUBLIC_URL}/${comp.img})`
            }}></div>}
            <div>
              {comp.name && <div className='s-name'>{comp.email ? <a className="darklink" href={'mailto:' + comp.email} target="_blank">{comp.name}</a> : comp.name}</div>}
              {comp.subtitle && <div className='s-description' dangerouslySetInnerHTML={{__html:comp.subtitle}} />}
              {comp.info && <div className='bolder'>{comp.info}</div>}
              {comp.phone && <div className='s-name'><a href={'tel:' + comp.phone}>{comp.phone}</a></div>}
            </div>
          </div>
          {comp.button && (
              <>
                <div className='mobileonly p-4'></div>
                <a className='wideonly' href="https://meetings-eu1.hubspot.com/marta-garcia1" target="_blank">
                  <span className='s-button'>{comp.button}&nbsp;&nbsp;<HiArrowRight size="1em"/></span>
                </a>
              </>
          )}
        </div>
      case 'checklist':
        return <>
          <ul className='s-list' style={{
            listStyle:`url(${process.env.PUBLIC_URL}/icons/Checkmark.svg)`
          }}>
            {comp.options.map((option,i) => <li key={i}>{option.text}</li>)}
          </ul></>
      case 'button':
        return <div style={{
          display:'flex',
          justifyContent: comp.align ? comp.align : 'flex-start'
        }}><a href="https://meetings-eu1.hubspot.com/marta-garcia1" target="_blank"><span className='s-button' style={{margin:'2em 0'}}>{comp.label}&nbsp;&nbsp;<HiArrowRight size="1em"/></span></a></div>
      case 'styleImg':
        return <StyleImg styles={dataQuery.status === 'success' ? dataQuery.data[summaryScope].styles : null}/>
      case 'labels':
        return <Labels styles={dataQuery.status === 'success' ? dataQuery.data[summaryScope].styles : null}/>
      case 'thumbs':
        return <Thumbs styles={dataQuery.status === 'success' ? dataQuery.data[summaryScope].styles : null}/>
      case 'title':
        return comp.content ? <div className="c-title c-title-big" dangerouslySetInnerHTML={{__html:comp.content}} 
          style={{
            textAlign:comp.align ? comp.align : 'left',
          }}/> : null
      case 'bigtitle':
        return <div style={{
          textAlign:'center',
          padding:'5em'
        }}>
          {comp.content ? <div className="c-title c-title-big" style={{color:'white',}} dangerouslySetInnerHTML={{__html:comp.content}} /> : null}
        </div>
      case 'image':
        return <div className='s-image' style={{
          minHeight:'400px',
          backgroundImage:`url(${process.env.PUBLIC_URL}/${comp.url})`,
          backgroundSize:'contain',
          backgroundPosition:'center'
        }}></div>
      case 'table':
        return <div className='s-table s-table-padding' style={{display:'flex',justifyContent:'center'}}><div>
          <table><tbody>
            {comp.rows && comp.rows.map((row,r) => {
              return <tr key={r}>{row.map((cell,i) => {
                switch (cell.type){
                  case 'img':
                    return <th key={i}>
                      {cell.value && <img src={process.env.PUBLIC_URL + '/' + cell.value}/>}
                      {cell.pc && <img className="wideonly" src={process.env.PUBLIC_URL + '/' + cell.pc}/>}
                      {cell.mobile && <img className='mobileonly' src={process.env.PUBLIC_URL + '/' + cell.mobile}/>}
                    </th>
                  case 'th':
                    return <th key={i}>{cell.value}</th>
                  case 'td':
                    return <td key={i}>
                      {cell.value && <span>{cell.value}</span>}
                      {cell.pc && <span className='wideonly'>{cell.pc}</span>}
                      {cell.mobile && <span className='mobileonly'>{cell.mobile}</span>}
                    </td>
                  case 'check':
                    return <th key={i}>{cell.value === 'tick' ? <AiOutlineCheck color="#007DFF"/> : <AiOutlineClose color="grey"/>}</th>
                  default:
                    return <td key={i}>{cell.value}</td>
                }
              })}</tr>
            })}
          </tbody></table>
          </div></div>
      case 'carousel':
        return <div className='s-carousel-container' style={{
          overflow:'hidden'
        }}><div className="s-carousel">
          {comp.images.map((img,i) => {
            return <div key={i} className='s-carousel-thumb' style={{
              backgroundImage:`url(${process.env.PUBLIC_URL}/${img.url})`
            }}></div>
          })}
        </div></div>
      default:
        return <div>TYPE: {comp.type}</div>
    }
  }

  const navbutton = (btn,i) => {
    switch (btn.type){
      case 'email':
        return <div key={i}><AiOutlineMail size="1.3em"/><a className="darklink" href={'mailto:' + btn.label} target="_blank">{btn.label}</a></div>
      case 'phone':
        return <div key={i}><BsTelephone size="1.3em"/><a className="darklink" href={'tel:' + btn.label}>{btn.label}</a></div>
      case 'help':
        return  <a key={i} href="https://wa.me/34613662008?text=Please%20help%20with%20HOMYPLAN%20project" target="_blank"><div className='s-button'>{btn.label} &nbsp; <HiArrowRight size="1em"/></div></a>
      default:
        return <div key={i}>{btn.type}</div>
    }
  }

  return <>
    <div className='s-navbar' style={{
      boxShadow:showShadow ? '10px 0px 32px rgba(0, 0, 0, 0.08)' : 'none'
    }}>
      <div className='s-logo-bar'>
        <img className='logo' style={{height:'30px'}} src={'../' + process.env.PUBLIC_URL + 'homyplan-logo.svg'} alt="HOMYPLAN-LOGO" />
        {/* {projectActions.project.defaults.style} */}
        <div className="button-group">
          {dataQuery.data && dataQuery.data[summaryScope].navbar.buttons.map((btn,i) => navbutton(btn,i))}
        </div>
      </div>
      {activeProjects.length > 0 && activeProjects[0].results.find(res => res.active === true) &&
      <div className='s-expiration'>
        <HiOutlineInformationCircle size="1.5em" />&nbsp;&nbsp;Fecha inicio válida si contratas antes del &nbsp;
        {expDate !== 'Invalid date' && expDate}
      </div>}
    </div>
    <div className='s-container'>
        {params.userId ? <div className='s-sections'>
            {(projectQuery.status === 'loading' || dataQuery.status === 'loading' || calcQuery.status === 'loading' ) && <div style={{
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              width:'100%',
              height:'100vh' 
            }}><PuffLoader /></div>}
            {projectQuery.status === 'error' && <div>Error fetching FB data</div>}
            {dataQuery.status === 'error' && <div>Error fetching JSON data</div>}
            {elementsQuery.status === 'error' && <div>Error fetching JSON data</div>}
            {calcQuery.status === 'error' && <div>Error with realtime data</div>}
            
            {(projectQuery.status === 'success' && dataQuery.status === 'success' && calcQuery.status === 'success' && elementsQuery.status === 'success') && 
            <>
            <div className='debug' style={{marginTop:'5em'}}>{projectActions.project.projectId} - {projectActions.project.scope} - Results: {projectActions.results.length} - Projects: {activeProjects.length}</div>

            {activeProjects.filter(res => res.active === true).length > 0 ? <div>
              {activeProjects.length > 1 ? <div> 
                {/* more then one project */}
                <section className='s-section' style={{marginBottom:'2em'}}>
                  <div className="s-col">
                    <div className='s-name'>Presupuesto detallado</div>
                    <div className="c-title" style={{margin:0}}>Presupuesto de tu reforma</div>
                    <div className='q-description'>Este es el presupuesto cerrado para tu reforma. Para continuar con el proceso y recibir asesoramiento personalizado, agenda una llamada con uno de 
    nuestros asesores.</div>
                  </div>             
                </section>
                <section className='s-section'>
                <div className="s-col">
                  <Overall projects={activeProjects}/>
                  </div>
                </section>
                <section className='s-section'>
                <div className="s-col">
                  <Budgets 
                    projects={activeProjects} 
                    isSingle={false} 
                    isHeader={true} 
                    styles={dataQuery.status === 'success' ? dataQuery.data : null}
                  />
                  </div>
                </section>
                </div>          
              : // if single project
              <div>
                  {activeProjects[0].results.find(res => res.active === true) 
                  ? 
                  <section className='s-section'><div className="s-col">
                  <div className='s-name'>Presupuesto detallado</div>
                    <div className="c-title" style={{margin:0}}>Presupuesto de tu reforma</div>
                    <div className='q-description'>Este es el presupuesto cerrado para tu reforma. Para continuar con el proceso y recibir asesoramiento personalizado, agenda una llamada con uno de 
    nuestros asesores.</div>
                  <Budget
                    result={activeProjects[0].results.find(res => res.active === true)}
                    scope={activeProjects[0].scope}
                    isDownload={false}
                    isHeader={true}
                    defaults={activeProjects[0].defaults}
                    styles={dataQuery.status === 'success' ? dataQuery.data : null}
                  /></div></section>
                  : // inactive project
                  <>
                  {dataQuery.data[summaryScope].sections.map((section,i) => {
                    let sectionCss = 's-section';
                    if (section.reversed && section.reversed === true){
                      sectionCss += ' s-reversed';
                    }
                    if(section.fullwidth && section.fullwidth === true){
                      sectionCss += ' s-fullwidth';
                    }
                    return <section key={i} className={sectionCss}
                      style={{
                        backgroundColor:section.backgroundColor ? section.backgroundColor : 'none',
                        backgroundImage:section.background ? `url(${process.env.PUBLIC_URL}/${section.background})` : 'none',
                        backgroundSize:'80%',
                        backgroundPosition:'center',
                        backgroundRepeat:'no-repeat'
                      }}
                    >
                      {section.cols && section.cols.map((col,i) => 
                        <div key={i} className="s-col">
                          {col.name && <div className='s-name'>{col.name}</div>}
                          {col.titleBig && <div className="c-title c-title-big" dangerouslySetInnerHTML={{__html:col.titleBig}} />}
                          {col.title && <div className="c-title" dangerouslySetInnerHTML={{__html:col.title}} />}
                          <div className="s-directed">
                            {col.subtitle && <div className="s-subtitle s-color-text-dark-blue" dangerouslySetInnerHTML={{__html:col.subtitle}} />}
                            {col.description && <div className="s-description" dangerouslySetInnerHTML={{__html:col.description}} />}
                          </div>
                          {col.components && col.components.length > 0 && col.components.map((compData,j) => <div className='s-text-secondary' key={j}>{component(compData)}</div>)}
                        </div>)}
                    </section>
                    })}
                  </>
                  }
                  </div>}
              </div>
              :
              <div style={{marginTop:'5em',textAlign:'center'}}>Projects not found</div>
            }
            <footer className='s-footer'><span className='s-button' style={{margin:'1em 0'}}>Quiero hablar con mi asesor&nbsp;&nbsp;<HiArrowRight size="1em"/></span>
            </footer>
            </>}
          </div>
          : <div>User does not exists</div>
        }
    </div>
    {projectActions.modal !== null && <Modal />}

    {debug && <div className='q-output debug' ref={debugRef} style={{positin:'abslute',marginBottom:'8em',maxHeight:'calc(100vh - 8em)'}}>
      <div>
          <button className='debug-btn debug' onClick={() => setShow(!show)}>Toggle results (RESULTS COUNT - {projectActions.results ? projectActions.results.length : 0}) <BsChevronExpand /></button>
          <div>SCENES: {projectActions.scenes && projectActions.scenes.length > 0 ? projectActions.scenes.map((scene,j) => {
              return <span key={j}> {scene.name} </span>
          }):
            "NO SCENES FUND"
          }
          </div>
          {show && <Budget />}
          <button className="debug" style={{width:'200px',height:'50px',margin:'1em'}} onClick={() => calcActions.makeCalculations()}>MAKE CALCULATIONS</button>
          <button className="debug" style={{width:'200px',height:'50px',margin:'1em'}} onClick={() => calcActions.makeResults()}>MAKE RESULTS</button>
          <div className='b-table debug'><Table sceneId={0}/></div>
          <div className='b-table debug'><Table sceneId={1}/></div>
        </div>
      </div>}

      <div className="debug" style={{
        position:'absolute', 
        width:"20px",
        height:"20px",
        top:'10em', 
        right:'10em', 
        border:'1px solid black', 
        cursor:'pointer',
        background:'white'}} 
        onClick={() => setDebug(!debug)}>
      X</div>
    </>
}

export default Summary
