import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Form, Checkbox, Button, message } from "antd";
import QnrContext from "../../context/QnrContext";
import ProjectContext from "../../context/ProjectContext";
import SceneContext from "../../context/SceneContext";
import AuthContext from '../../context/AuthContext'
import { auth, firestore as db } from "../../firebase/config";
import { amplitudeEvent, EnumEventsAmplitude, EnumActionsAmplitude } from "../../utils/amplitude";
import { formatDefaults, convertScene, formatTable, formatResults } from "../../utils/formatUtils";
import { cleanResults } from '../../utils/calcUtils'

const key = "updatable";

const Register = ({ q, messages }) => {

  const navigate = useNavigate()

  const [form] = Form.useForm();
  const { activeQ, setActiveQ } = useContext(QnrContext);
  const projectActions = useContext(ProjectContext);
  const sceneActions = useContext(SceneContext);
  const [privacy, setPrivacy] = useState(false);
  const [isBlur, setIsBlur] = useState({
    name: false,
    email: false,
    phone: false
  })

  const {setCurrentUser, currentUser, setUser, user} = useContext(AuthContext)

  useEffect(() => {
    // console.log('----- results in Register',projectActions.results)
    // console.log('DEFAULTS IN REGISTER',projectActions.project)
    // console.log('SCENE',convertScene(sceneActions.scene.scene))
    formatTable(sceneActions.scene,sceneActions.mScene)
    formatDefaults(projectActions.project.defaults)
  },[projectActions.results])

  const getIsRequired = (key) => {
    return isBlur[key] && !document.getElementById(key).value
  }

  const setBlur = (key) => {
    setIsBlur({
      ...isBlur,
      [key]: true
    })
  }

  const getNameRequired = (key) => {
    return key === 'name' ? 'Nombre'
        : key === 'phone' ? 'Teléfono'
        : key === 'password' ? 'Contraseña'
        : key === 'email' ? 'Email'
        : key;
  }

  const togglePrivacy = () => setPrivacy((privacy) => !privacy);

  const submit = () => {

    message.loading({
      content: messages.loading,
      key,
    });
    const phone = document.getElementById("phone").value;    
    
    let cleanPhone = phone.replace(/[^\d.-]/g, '').split("+").join("").split(" ").join("").split("-").join("")

    if (!privacy) {
      return message.error({
        content: messages.pleaseAgreeToTerms,
        key,
        duration: 2,
      });
    }

    // console.log('submit-',phone,'-',cleanPhone,!phone)

    let name = document.getElementById("name").value;

    let email = document.getElementById("email").value;
    let password = null;

    if(!name){
      return message.error({
        content: messages.pleaseFillAllFields,
        key,
        duration: 2,
      });       
    }

    if(!phone){
      console.log('no phone')
      return message.error({
        content: messages.pleaseFillAllFields,
        key,
        duration: 2,
      });      
    } else if((cleanPhone.length !== 11 && cleanPhone.length !== 9) || (cleanPhone[0] !== '6' && cleanPhone[0] !== '7')){
      console.log('wrong phone',cleanPhone.length,cleanPhone[0])
      return message.error({
        content: messages.wrongPhone,
        key,
        duration: 2,
      });
    } 

    console.log('passed through')

    // if(projectActions.project.scope === "CocinaProyecto"){
      if(!password) {
        password = cleanPhone;
      }
      
      //phone validation


    // } else {
      email = document.getElementById("email").value;
      // password = document.getElementById("password").value;
      if(!name || !email || !password){
          return message.error({
            content: messages.pleaseFillAllFields,
            key,
            duration: 2,
          });      
      }
    // }

    auth.createUserWithEmailAndPassword(email,password)
      .then((userCredential) => {
        console.log('create user')
        var user = userCredential.user;
        var myUserData = {
          email: user.email,
          name: name,
          policy: privacy,
          plan: "Plan Gratuito",
          plan_id: "prod_Kr9EadM2IidbkV",
          phone: cleanPhone,
          label:'new',
          note:'',
          accountCreationDate: new Date(),
          profile_type: "personal",
          zipCode:localStorage.getItem("city").split(',')[0]
        }

          let now = new Date();
          // console.log(projectActions.results)
          db.collection("Projects")
            .add({
              name: "proyectoNum 1",
              plan: "basic",
              initialQNR: {
                status: "incomplete",
              },
              zipCode: localStorage.getItem("city"),
              images: {
                floorplan: "",
                initialview: "",
                integratedview: "",
                photo: "",
                renders: [],
                thumbnail: "",
              },
              marketPlaceIntro: false,
              access: "pending",
              active:true,
              userId: auth.currentUser.uid,
              created_At: now,
              last_Modified_At: now,
              results: cleanResults(projectActions.results),
              scope: projectActions.project.scope,
              defaults:formatDefaults(projectActions.project.defaults),
              scenes: projectActions.scenes
            })
            .then((project) => {
              console.log(project)
              localStorage.setItem("projectID", project.id)
              myUserData.projectID =  project.id 
              // localStorage.removeItem("city")
              db.collection("Users")
              .doc(user.uid)
              .set({...myUserData})
              .then(() => {
    
                amplitudeEvent(EnumEventsAmplitude.registration_completed, '', EnumActionsAmplitude.InteractionEvent)
                user.sendEmailVerification().then((res) => {
                  message.info({
                    content: messages.checkEmailForVerification,
                    key,
                    duration: 2,
                  });
                  setActiveQ((active) => active + 1);
    
                  console.log('redirecting to ', project.id)
                  
                  navigate("/summary/" + user.uid)
                  // navigate("/results/" + project.id)
                });
                message.success({
                  content: messages.registrationSuccess,
                  key,
                  duration: 2,
                });
              });
            })
      })

      .catch((error) => {
        console.log(error);
        message.error({
          content: messages[error.code] ? messages[error.code] : messages.unknownError,
          key,
          duration: 2,
        });
      });
  };

  return (
    !currentUser || currentUser === null ? <div>
      {q.options.map((option, key) => (<div key={key}>
          <label
              style={{display:'flex',flexDirection:'row',alignItems:'center'}}
          >
            <input
              type={option.type}
              name={option.id}
              className={(getIsRequired(option.id) ? 'input-border-red ' : '') + 'q-input'}
              id={option.id}
              placeholder={option.placeholder}
              // onChange={handleProjectValue}
              onBlur={() => setBlur(option.id)}
            />
          </label>
        {getIsRequired(option.id) && (<span className="q-required">
          {getNameRequired(option.id)+ ' '}
          es requerido!
        </span>)}
      </div>))}
      <Form form={form}>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox onChange={togglePrivacy}>
            He leído y acepto la{" "}
            <a target="blank" href="https://homyplan.es/politica-privacidad/">
              Política de Privacidad
            </a>
            y las{" "}
            <a
              target={"blank"}
              href="https://homyplan.es/terminos-y-condiciones"
            >
              {" "}
              condiciones de uso
            </a>
          </Checkbox>
        </Form.Item>

        <Button className="q-navbtn next wideonly" onClick={submit} id="log-presupuesto">
          <span className="button-log-presupuesto">Ver mi presupuesto &nbsp; &#x279C;</span>
        </Button>

        <div className="q-navbtns mobileonly" style={{


        }}>
          <Button className="q-navbtn next" onClick={submit} id="log-presupuesto">
            <span className="button-log-presupuesto">Ver mi presupuesto &nbsp; &#x279C;</span>
          </Button>
        </div>
      </Form>


    </div>
    :
    <div>
      SAVE
    </div>
  );
};

// {
//   "id": "q-11-1",
//   "type": "confirm",
//   "options": [
//     { "t": "checkbox", "actions": [{ "a": "confirm" }] },
//     { "t": "txt", "content": "He leido y acepto la  " },
//     {
//       "t": "link",
//       "content": " Política de Privacidad ",
//       "actions": [{ "a": "link", "href": "" }]
//     },
//     { "t": "txt", "content": " y las " },
//     {
//       "t": "link",
//       "content": " condiciones de uso",
//       "actions": [{ "a": "link", "href": "" }]
//     }
//   ]
// }
export default Register;
