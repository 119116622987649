import React, {useState, useEffect, useContext} from 'react'
import ProjectContext from '../../context/ProjectContext'
import QnrContext from '../../context/QnrContext'
import SceneContext from '../../context/SceneContext'
// import { angleBetweenTwoPoints, verticesDistance, extendLine, getPolygonCentroid } from '../../utils/geometry'
import Canvas2 from '../widgets/Canvas2'
// import Range from './Range'
// import ItemsMenu from './ItemsMenu'
// import MaterialsMenu from './MaterialsMenu'
// import WallCanvas from './WallCanvas'
// import { ContinuousSizeLegend } from 'react-vis'
// import { scenes } from '../../Data/scenes'


const ShapePlanner2 = ({q}) => {

  const projectActions = useContext(ProjectContext)

  return (
    <div className='rc-container'>
      {/* {projectActions.scenes[0] ? <Canvas2 sceneid={0} width={700} height={500} /> : <h3>No Initial scene found</h3>} */}
      {projectActions.scenes[1] ? <Canvas2 sceneid={1} width={700} height={500} /> : <h3>No Reform scene found</h3>}
    </div>
  )
}

export default ShapePlanner2
