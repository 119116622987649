import React, {useContext, useEffect, useState} from 'react'
import { getChapters } from '../../utils/budgetUtils'
import BudgetTableGroup from "./BudgetTableGroup";
import AuthContext from '../../context/AuthContext'
import ProjectContext from "../../context/ProjectContext";
import FooterBudget from "./FooterBudget";
import HeaderBudget from "./HeaderBudget";
import ExcelExportBudget from "./ExportBudget/ExcelExportBudget";
import { HiArrowRight } from 'react-icons/hi'
import { Button } from 'antd'
import {BudgetList} from "./BudgetList";
import {useLocation} from "react-router-dom";

/**
 * Budget initial component.
 *
 * @param prop Props.
 * @param prop.result Results by projectActions.
 * @param prop.isBasicBudget If only show a budget basic.
 * @param prop.scope TODO: create doc.
 * @param prop.isDownload If show buttons to download budget.
 * @param prop.blur TODO: create doc.
 * @param prop.isSingle TODO: create doc.
 * @param prop.isTitle TODO: create doc.
 * @param prop.isHeader If show header budget.
 * @param prop.isCTA TODO: create doc.
 * @param prop.isFooter If show footer budget.
 * @param prop.expBudgetDate TODO: create doc.
 * @param prop.styles Custom styles to header budget.
 * @return React element Budget component.
 */
const Budget = ({
        result, 
        isBasicBudget, 
        scope, 
        isDownload = false, 
        blur, 
        isSingle, 
        defaults,
        isTitle=false, 
        isHeader=false, 
        isCTA=false, 
        isFooter=false, 
        expBudgetDate="",
        styles
    }) => {
    const { user } = useContext(AuthContext)
    const [results, setResults] = useState(null)
    const projectActions = useContext(ProjectContext)
    const location = useLocation();
    const [summaryScope, setSummaryScope] = useState('summary_bano')

    if (!result) result = projectActions.results[0]

    useEffect(() => {
        if(projectActions.calcData){
            getChapters(result, projectActions.calcData).then((resultTemp) => setResults(resultTemp))
        }
        setSummaryScope(scope === 'BanoProyecto' ? 'summary_bano' : 'summary_cocina')

        // console.log('IN BUDGET',scope,summaryScope)

    }, [result,projectActions.calcData])

    return (
        <>
            {(results && results.jobs && results.jobs.length) && (
                <div className={'rounded bg-white'}>
                    {isTitle && <>
                        <div className="c-title">Presupuesto detallado</div>
                        <span style={{fontSize:'10px',padding:'.5em 1em',color:'#777786',background:'#EFFAFF'}}>
                            *Validez del presupuesto hasta el 
                            {/* {moment(new Date()).add(21, 'days').format('DD/MM/YYYY')} */}
                            &nbsp;{expBudgetDate}
                        </span>
                    </>}

                    {isHeader !== false && <div className="px-4 pt-4">
                        <HeaderBudget result={result} scope={scope} isSingle={isSingle} defaults={defaults} styles={styles[summaryScope].styles}/>
                    </div>}

                    <div className={'custom-table-budget bg-white rounded'}>

                        <div className={(location.pathname.includes('summary') ||
                            location.pathname.includes('project')
                            ? 'wideonly '
                            : '') + 'w-full bg-white px-4'}
                        >
                            <BudgetTableGroup
                                jobs={results}
                                isBasicBudget={isBasicBudget}
                                blur={blur}
                                isActive={result.active}
                            />
                        </div>
                        {(location.pathname.includes('summary') ||
                            location.pathname.includes('project')) && (
                            <div className="mobileonly px-2">
                                <BudgetList
                                    results={results}
                                    isActive={result.active}
                                />
                            </div>
                        )}
                        {isCTA && <div style={{textAlign:'right'}}>
                            <span className='s-button' style={{marginTop:'4em'}} onClick={() => {window.open('https://meetings-eu1.hubspot.com/marta-garcia1','_blank')}}>Quiero hablar con mi asesor&nbsp;&nbsp;<HiArrowRight size="1em"/></span>    
                        </div>}
                        {isFooter && <div className="bg-secondary px-4 mt-4 py-1 rounded-b">
                            <FooterBudget result={result} amountTotal={results.amountTotal} />
                        </div>}
                        {user && user.profile_type === "admin" && isDownload && <div className={'p-4 pb-0 m-1'}>
                            <ExcelExportBudget jobs={results} />
                            <Button
                                shape="round"
                                type="primary"
                            >
                                PDF
                            </Button>
                        </div>}                    
                    </div>
                </div>
            )}
        </>
    )
}

export default Budget
