import React from "react";

/**
 * Item of the detail project.
 *
 * @param prop Props.
 * @param prop.name Name of the item.
 * @param prop.value Value of item.
 * @param prop.customClass Push custom classes.
 * @return React element ItemDescription component.
 */
const ItemDescriptionProject = ({name, value, customClass}) => (
    <div className={customClass + ' flex gap-3 py-2 detail-attribute-job'}>
        <div className="truncate name-attribute" title={name}>{name}:</div>
        <div className="color-blue-dark font-bold value-attribute">{value}</div>
    </div>
)

export default ItemDescriptionProject
