import { createContext, useState, useContext, useEffect } from 'react';
import { elementNameToId, elementIdToName } from '../utils/formatUtils'
import { createElement, createGroup, getElementOfId } from '../utils/sceneUtils'
import { getOldFromCalcData, addElementToLayer, removeElementFromLayer, updateElement, checkFilterItem, getElementsFromLayer } from '../utils/sceneUtils'
import { angleBetweenTwoPoints, extendLine, verticesDistance } from '../utils/geometry'
import areapolygon from 'area-polygon';

import ProjectContext from './ProjectContext'
import QnrContext from './QnrContext'
import CalcContext from './CalcContext'
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';

const SceneContext = createContext();

export const SceneProvider = ({ children }) => {

    const { calcData, project, setDefault, saveProject ,setScenes } = useContext(ProjectContext)

    const projectActions = useContext(ProjectContext)
    const qnrActions = useContext(QnrContext)
    const calcActions = useContext(CalcContext)

    const [scene, setScene] = useState(null)
    const [mScene, setMScene] = useState(null)
    const [layer, setLayer] = useState(null)
    const [elements, setElements] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [viewMode, setViewMode] = useState(false)
    const [tool, setTool] = useState('idle')

    const [selected, setSelected] = useState(null)
    const [active, setActive] = useState(null)
    const [over, setOver] = useState(null)
    const [currentArea, setCurrentArea] = useState(null)

    useEffect(() => {

      // console.log('IN SCENE DATA',projectActions.sceneData)

      if(projectActions.sceneData !== null){
        projectActions.setScenes([projectActions.sceneData[projectActions.project.defaults.houseShape].scene])
      }      

    },[projectActions.sceneData])

    const saveScenes = (projectID,projectName) => {
      setScenes(projectActions.scenes)
      saveProject(projectID,projectName)
    }

    const setCurrentScene = (id,option = null) => {  
      projectActions.setScenes([projectActions.sceneData[id].scene])
    }

    const replaceLines = (layer) => {
          
      const tempScenes = projectActions.scenes
     
      tempScenes.map((scene,i) => {
        const tempLayer = JSON.parse(JSON.stringify(layer));
        const oldLayer = scene.layers[0].layer_1;

        const currentLines = scene.layers[0].layer_1.lines;


        tempLayer.lines.map((line,j) => {

          // console.log('LINE',line,i)

          if(currentLines[j]){
            line.features_a = i === 0 ? currentLines[j].features_a.slice(0,7) : currentLines[j].features_a;
            line.features_b = i === 0 ? currentLines[j].features_b.slice(0,7) : currentLines[j].features_b;
          } else if (currentLines[0]){
            line.features_a = i === 0 ? currentLines[0].features_a.slice(0,7) : currentLines[0].features_a;
            line.features_b = i === 0 ? currentLines[0].features_b.slice(0,7) : currentLines[0].features_b;
          } else {
            if(i !== 0){
              const zeros = new Array(8).fill(0)
              line.features_a = line.features_a.concat(zeros);
              line.features_b = line.features_b.concat(zeros);
            }         
          }

          if(i !== 0){
              line.features_a[16] = 1;
              line.features_b[16] = 1;            
          }

          return line;
        })

        // tempLayer.items = oldLayer.items.map((item,k) => {
        //   const zeros = new Array(16).fill(0)
        //   if(i !== 0){
        //     item.features[7] = getOldFromCalcData(calcData,item.id_element)
        //     item.features = item.features.concat(zeros)
        //   }
        // })

        // const currentHoles = scene.layers[0].layer_1.holes

        // tempLayer.holes = oldLayer.holes.map((hole,k) => {
        //   const zeros = new Array(16).fill(0)
        //   if(i !== 0){
        //     hole.features[7] = getOldFromCalcData(calcData,hole.id_element)
        //     hole.features = hole.features.concat(zeros)
        //   }
        // })

        tempLayer.areas = oldLayer.areas;
        tempLayer.items = oldLayer.items;
        tempLayer.holes = oldLayer.holes;

        scene.layers[0].layer_1.vertices = layer.vertices;

        return scene.layers[0].layer_1 = tempLayer;
      })

      projectActions.setScenes([...tempScenes])  
    }

    const replaceVertices = (vertices) => {
      console.log('verts to replace',vertices)

      const tempScenes = projectActions.scenes
      
      tempScenes.map((scene,i) => {
        const tempLayer = tempScenes[i].layers[0].layer_1;

        tempLayer.vertices = vertices;

        tempLayer.lines.map(line => {
            
            const length = verticesDistance(vertices[line.v1],vertices[line.v2])
            const height = parseFloat(project.defaults.height)
            line.measurements = {length:length,height:height,area:parseFloat(length * height).toFixed(2)}

          return line
        })

        tempLayer.areas.map(area => {
          const polygon = vertices.map(v => [v.x,v.y])

          let areaSize = areapolygon(polygon, false);

          area.floor.measurements = {area:parseFloat(areaSize).toFixed(2)}
          area.ceiling.measurements = {area:parseFloat(areaSize).toFixed(2)}

          return area
        })

      
        return scene.layers[0].layer_1 = tempLayer
      })

      projectActions.setScenes([...tempScenes])  
    }

    const setSceneParameter = (parameter,value) => {

      const tempScenes = projectActions.scenes

      switch(parameter){
        case 'height':
            tempScenes.map(scene => {
              const tempLayer = scene.layers[0].layer_1
              console.log(tempLayer)
              tempLayer.lines.map(line => {
                line.measurements[parameter] = (value/100).toFixed(2)
              })
            })
            projectActions.setScenes([...tempScenes])  
        break;
        default:
      }
    }

    const setNewVertices = (vertices) => {
      const tempScenes = projectActions.scenes

      tempScenes.map((scene,i) => {
      
        const tempLayer = tempScenes[i].layers[0].layer_1;

        tempLayer.vertices = vertices;

        tempLayer.lines.map(line => {
          if(line.type === 'wall'){
            const length = verticesDistance(vertices[line.v1],vertices[line.v2])
            const height = parseFloat(project.defaults.height)
            line.measurements = {length:length,height:height,area:parseFloat(length * height).toFixed(2)}
          }
          return line
        })

        tempLayer.areas.map(area => {
          const polygon = vertices.map(v => [v.x,v.y])

          let areaSize = areapolygon(polygon, false);

          area.floor.measurements = {area:parseFloat(areaSize).toFixed(2)}
          area.ceiling.measurements = {area:parseFloat(areaSize).toFixed(2)}

          return area
        })

        return scene.layers[0].layer_1 = tempLayer
      })

      projectActions.setScenes([...tempScenes])  
    }

    const addElement = (table = 'integratedTable',element) => {

        const sceneId = table === 'integratedTable' ? 1 : 0;

        projectActions.setScenes(scenes => {
          const tempLayer = scenes[sceneId].layers[0].layer_1;
          scenes[sceneId].layers[0].layer_1 = addElementToLayer(tempLayer, currentArea, element)

          return scenes
        })
        setRefresh(!refresh)
        // refreshElements()
        // console.log('after',sceneId,projectActions.scenes[sceneId].layers[0].layer_1.items)
    }

    const shiftActive = (active,start,end) => {
      console.log(active,start,end)

      // const activeLine = layer.lines[active.split('_')[1]]
      // const verts = 

      console.log(layer)
    }

    const addElementToAll = (table,element) => {
      console.log('IN ADD ALL',table, element, projectActions.project)

      projectActions.setScenes(scenes => {

        scenes.map((scene,i) => {
          let tempLayer = scene.layers[0].layer_1
          if(i > 0){
            const zeros = new Array(16).fill(0)
            const tempElement = {...element};

            console.log('in add all',tempElement)

            tempElement.features = [...tempElement.features,...zeros]
            tempElement.features[7] = getOldFromCalcData(calcData,tempElement.id_element)
            tempLayer = addElementToLayer(tempLayer, currentArea, tempElement)
          } else {
            element.features[7] = null;
            tempLayer = addElementToLayer(tempLayer, currentArea, element)
          }
          scene.layers[0].layer_1 = tempLayer
          
          return scene
        })

        return scenes
      })

      setRefresh(!refresh)
    }

    const removeElement = (table = 'integratedTable',id_element = null, typology = null, itemID = null) => {//todo - add filter to remove multiple elements

      projectActions.setScenes(scenes => {
        const tempScenes = scenes
        const sceneId = table === 'integratedTable' ? 1 : 0;
        if(table === 'all'){
          scenes.map((scene,i) => {
            const tempLayer = scene.layers[0].layer_1;
            scene.layers[0].layer_1 = removeElementFromLayer(tempLayer,currentArea,id_element,itemID)
          })
        } else {
          const tempLayer = scenes[sceneId].layers[0].layer_1
          scenes[sceneId].layers[0].layer_1 = removeElementFromLayer(tempLayer,currentArea,id_element,itemID)
        }
        
        return scenes
      })

      setRefresh(!refresh)
    }

    const toggleBetweenGroups = (table = 'integratedTable',itemId,groupId,groupId1,groupId2) => {
        const sceneId = table === 'integratedTable' ? 1 : 0;       

        projectActions.setScenes(scenes => {
          const tempLayer = scenes[sceneId].layers[0].layer_1;

          tempLayer.groups = tempLayer.groups ?? [createGroup(groupId1,[],groupId1),createGroup(groupId2,[],groupId2)]
          
          tempLayer.groups = tempLayer.groups.map(group => {
            group.items = group.items.filter(item => item !== itemId);
            // if(group.items.includes(itemId)){
              // group.items = group.items.filter(item => item.id !== itemId);
            // } else {
              if(group.id === groupId){
                  group.items.push(itemId)
              }
            // }

            return group
          })

          // tempLayer.groups.forEach(gr => gr.id === groupId && gr.items.push(itemId))

          scenes[sceneId].layers[0].layer_1 = tempLayer

          console.log('IN TOGGLE GROUPS',tempLayer.groups.map(gr => gr.items),itemId,groupId1,groupId2)

          return scenes
        })
    }

    const moveToGroup = (table,itemId=null,groupId=null,groupName=null) => {
      console.log('addToGroup',itemId,groupId)

      if(itemId){
        const sceneId = table === 'integratedTable' ? 1 : 0;

        projectActions.setScenes(scenes => {
          const tempLayer = scenes[sceneId].layers[0].layer_1;

          if(!tempLayer.groups){
            tempLayer.groups = []
          }

          let group = tempLayer.groups.find(gr => gr.id === groupId);

          tempLayer.groups.forEach(group => {
            return group.items.filter(item => item.id !== itemId)
          })

          if(!group){
            group = createGroup(groupId,[itemId],groupName)
            tempLayer.groups = [...tempLayer.groups,group]
            console.log('group not found')
          } else {
            console.log('group exists')
            group.items = [...group.items,itemId]
            const tempGroups = tempLayer.groups.filter(gr => gr.id !== group.id)
            tempLayer.groups = [...tempGroups, group];
          }

          
          console.log('CREATED',group,tempLayer.groups)

          scenes[sceneId].layers[0].layer_1 = tempLayer

          return scenes
        })

        // setRefresh(!refresh)
      }
    }

    const removeFromGroup = (itemId,group) => {
      console.log('removeFromGroup',itemId,group)
    }

    const resetGroups = (group=null) => {
      console.log('resetGroups',group)
    }

    const setGroupFeature = (scene=null, column, value, groupId, id_element) => {
      const sceneId = scene === null ? 1 : scene === 'scene' ? 0 : 1;

      const layer = projectActions.scenes[sceneId].layers[0].layer_1

      const foundGroup = layer.groups ? layer.groups.find(gr => gr.id === groupId) : null;
      console.log('IN F CHANGE ',layer.groups,groupId)

      if(foundGroup){

        foundGroup.items.forEach(itemId => {
          const item = getElementOfId(layer,null,itemId)
          if(item){
            setFeature('mScene',column,parseInt(value),parseInt(id_element),[],itemId)
          }
        })
      }
    }

    const setFeature = (scene=null, feature, value, id_element, filters=[], itemID=null, areaID=null, parentID=null) => {       
      
      console.log('SET FEATURE',scene,'id_element:',id_element, 'itemID:',itemID,'feature:',feature,'value:',value, filters)

      const aFeature = feature.toString().split(',')
      const aValue = value.toString().split(',')
      
      const elId = isNaN(id_element) ? elementNameToId(id_element) : id_element;
        let tempItems = null;
        const sceneId = scene === null ? 1 : scene === 'scene' ? 0 : 1;
        projectActions.setScenes(scenes => {
          const tempScene = scenes[sceneId];
          switch (parseInt(elId)){
            case 1:
                tempScene.layers[0].layer_1.lines.map(line => {
                  if(itemID !== null){
                    aFeature.forEach((feat,i) => {
                      if(line.id === itemID){
                        line.features_a[parseInt(feat)] = parseInt(aValue[i])
                        line.features_b[parseInt(feat)] = parseInt(aValue[i])                      
                      }                      
                    })
                  } else {
                    aFeature.forEach((feat,i) => {
                      line.features_a[parseInt(feat)] = parseInt(aValue[i])
                      line.features_b[parseInt(feat)] = parseInt(aValue[i])
                    })
                  }
                  return line
                })
            break;
            case 2:
              console.log(tempScene.layers[0].layer_1.areas)
              aFeature.forEach((feat,i) => {
                tempScene.layers[0].layer_1.areas.map(area => area.floor.features[parseInt(feat)] = parseInt(aValue[i]));
              })
            break;
            case 3:
              aFeature.forEach((feat,i) => {
                tempScene.layers[0].layer_1.areas.map(area => area.ceiling.features[parseInt(feat)] = parseInt(aValue[i])); 
              })
            break;
            case 4:
            case 5:
            case 6:
              tempScene.layers[0].layer_1.holes
                .filter(h => h.id_element === parseInt(elId))
                .filter(it => checkFilterItem(it,filters))
                .map(hole => {
                  const tempFeatures = hole.features;
                  aFeature.forEach((feat,i) => {
                    tempFeatures[parseInt(feat)] = parseInt(aValue[i])
                  })
                  hole.features = tempFeatures;
                  return hole
                }); 
            break;
            case 8:
            case 10:
            case 11:
            case 12:
            case 15:
            case 16:
              tempScene.layers[0].layer_1.items
                .filter(it => it.id_element === parseInt(elId))
                .filter(it => checkFilterItem(it,filters))
                .filter(it => {
                  return  itemID !== null ? it.id === itemID : true;
                })
                .map(it => {
                  const tempFeatures = it.features;
                  aFeature.forEach((feat,i) => {
                    tempFeatures[parseInt(feat)] = parseInt(aValue[i])
                  })
                  it.features = tempFeatures;
                  return it
                });
            break;
            case 22:
              aFeature.forEach((feat,i) => {
                tempScene.house[2].features[parseInt(feat)] = parseInt(aValue[i]);
              })
            break;
            default:
             tempScene.layers[0].layer_1.items.filter(it => {
                return  itemID !== null ? it.id === itemID : true;
              }).map(item => {
                if(filters.length > 0){
                  const test = [];
                    filters.forEach(fil => {                              
                      if(item.features[fil.f] === parseInt(fil.v)){
                        test.push(true)
                      } else {
                        test.push(false)
                      }
                    })

                    // console.log('Changing',item.id,test,filters.length)              

                    if(test.every(t => t === true)){
                      aFeature.forEach((feat,i) => {
                        item.features[parseInt(feat)] = parseInt(aValue[i]);
                      })
                    }                            
                } else {
                  aFeature.forEach((feat,i) => {
                    item.features[parseInt(feat)] = parseInt(aValue[i]);
                  })
                }
            }) 
            // console.log('after',tempScene)
          }

          return scenes
        })

        setRefresh(!refresh)
        // console.log('after',projectActions.scenes[1].layers[0].layer_1)
    }

    const setFeatureOfAll = ({scene=null, layer=0, feature, value=0}) => {

      console.log('==========',value)

      const aFeature = feature.toString().split(',')
      const aValue = value.toString().split(',')

      if(projectActions.scenes){
        
        const sceneId = scene === null ? 1 : scene === 'scene' ? 0 : 1;
        
        return new Promise ((resolve) => projectActions.setScenes((scenes) => {
          const tempScene = scenes[sceneId];
          
          // console.log('IN SET',projectActions.scenes,feature,value)

          let templayer = layer !== null ? layer : tempScene.layers[0].layer_1;

          aFeature.forEach((feat,i) => {
            tempScene.house.forEach((houseItem) => {
              houseItem.features[parseInt(feat)] = parseInt(aValue[i]);
            })
          })

          tempScene.layers[layer].layer_1.lines.map(line => {
            aFeature.forEach((feat,i) => {
              line.features_a[parseInt(feat)] = parseInt(aValue[i])
              line.features_b[parseInt(feat)] = parseInt(aValue[i])
            })
            return line
          })

          aFeature.forEach((feat,i) => {
            tempScene.layers[layer].layer_1.areas.map(area => area.floor.features[parseInt(feat)] = parseInt(aValue[i]));
            tempScene.layers[layer].layer_1.areas.map(area => area.ceiling.features[parseInt(feat)] = parseInt(aValue[i])); 
          })

          tempScene.layers[layer].layer_1.holes.map(hole => {
            aFeature.forEach((feat,i) => {
              hole.features[parseInt(feat)] = parseInt(aValue[i])
            })
            return hole
          }); 

          tempScene.layers[layer].layer_1.items.map(item => {
            aFeature.forEach((feat,i) => {
              item.features[parseInt(feat)] = parseInt(aValue[i]);
            })
          }) 

          // console.log('after',projectActions.scenes[1].layers[layer].layer_1)
          
          resolve(scenes);

          return scenes
        }))
      }
    }
 
    const resetScenes = () => {

      projectActions.setScenes(scenes => {
        const tempScene = scenes[0]

        tempScene.layers[layer].layer_1.items = projectActions.sceneData[projectActions.project.defaults.houseShape].scene.layers[layer].layer_1.items

        scenes[0] = tempScene

        return scenes
      })

      copyScene();
    }

    const deleteScene = (sceneId) => {
      new Promise((resolve, reject) => {
        projectActions.setScenes(scenes => {
          return scenes.filter((scene,i) => i === 0)
        })
      }).then(() => console.log('DEL OK'))
    }

    const copyScene = () => {

      return new Promise((resolve, reject) => {
        const tempLayer = JSON.parse(JSON.stringify(layer))

        const zeros = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

        projectActions.setScenes(scenes => {
          const tempInitialScene = JSON.parse(JSON.stringify(scenes[0]))

          tempInitialScene.house = tempInitialScene.house.map(houseItem => {
            houseItem.features = houseItem.features.slice(0,8).concat(zeros)
            return houseItem
          })
 
          const currentLayer = tempInitialScene.layers[0].layer_1

          currentLayer.areas.map(area => {
            const floorFeatures = [53,null,null,37,47,null,50]
            const oldTypology = area.ceiling.features[1]
            const ceilingFeatures = [70,oldTypology,57,60,66,null,67]

            area.features = area.features.slice(0,8).concat(zeros)
            const oldValue = calcData.elements.find(el => el.id_element === area.id_element)
            area.features = area.features.slice(0,8).concat(zeros)
            area.features[7] = oldValue ? oldValue.old : null;

            area.floor = {...area.floor,features:area.floor.features.slice(0,7).concat(floorFeatures,zeros).slice(0,24)}
            area.ceiling = {...area.ceiling,features:area.ceiling.features.slice(0,7).concat(ceilingFeatures,zeros).slice(0,24)}

            if(area.floor.features[5]){
              const floorArea = area.floor.measurements.area;

              let areaOption = 0

              if(parseFloat(floorArea) > 5 && parseFloat(floorArea) <= 8){areaOption = 1}
              if(parseFloat(floorArea) > 7){areaOption = 2}

              const values = [
                [1,2,3],
                [4,5,6],
                [7,8,9],//size 2
                [10,11,12]//size 2
              ]

              console.log('areaOption:',areaOption,' | floor feature:',area.floor.features[5],' FROM ARRAY:',values[areaOption]);

              area.floor.features[15] = values[area.floor.features[5] - 1][areaOption];
              // area.floor.features[15] = area.floor.features[5];
              area.floor.features[5] = null;
            }
            return area
          })

          currentLayer.lines.map(line => {
            const oldValue = calcData.elements.find(el => el.id_element === line.id_element)

            const filler = [null,null,29,32,12,14,line.features_a[3],null,null,29]
            line.features_a = line.features_a.slice(0,4).concat(filler,zeros).slice(0,24)
            line.features_b = line.features_b.slice(0,4).concat(filler,zeros).slice(0,24)

            return line
          })

          currentLayer.items.map(item => {
            const itemData = calcData.elements.find(el => el.id_element === item.id_element)
            const oldTypology = item.features[1]
            item.features = item.features.slice(0,8).concat(zeros)
            item.features[7] = itemData ? itemData.old : null;
            item.features[8] = oldTypology;
            if(item.id_element === 15){
                item.features[7] = 174;
                item.features[15] = 2;
            }

            return item
          })    

          currentLayer.holes.map(hole => {
            const holeData = calcData.elements.find(el => el.id_element === hole.id_element)
            const oldTypology = hole.features[1]
            hole.features = hole.features.slice(0,8).concat(zeros)
            hole.features[7] = holeData ? holeData.old : null;
            hole.features[8] = oldTypology;
            if(hole.id_element === 4){
                hole.features[7] = 76;
            }

            return hole
          })  

          currentLayer.groups = currentLayer.groups ? currentLayer.groups.map(group => {
            return group
          }) : [];

          return [scenes[0],tempInitialScene]
        })
           
        resolve()

        calcActions.makeCalculations()
        calcActions.makeResults()

        console.log('after',projectActions.scenes)
        
      })
    }

    const getNewElement = (scenename,id_element = null,type = null,typology,name,label,sentfeatures=null,measurements=null) => {

      if(type === null){
        type = elementIdToName(id_element)
      } 

      if(id_element === null){
        elementNameToId(type)
      }

      let features = new Array(8).fill(null);

      features[0] = projectActions.roomType ? projectActions.roomType : 6;
      features[1] = parseInt(typology);
      if(scenename === 'mScene'){
        const zeros = new Array(16).fill(0)
        
        features = features.slice(0,8).concat(zeros)
        // features[15] = 2
        // console.log('WCHODZI',features)
      }
      const newElement = createElement(name,label,id_element,type,features,measurements)
      
      return newElement
    }

    const performSingleAction = (scenename = 'mScene',action,id_element = null,filters=[]) => {
      console.log('PERFORMING ACTION',action)
      let table = 'initialTable';
      switch(action.a){
        case 'closePopup':
          projectActions.setPopup(null)
          projectActions.setModal(null)
          projectActions.setModalData(null)
        break;
        case 'copyScene':
          copyScene();
        break;
        case 'set':
          console.log('in scene',action)
          setFeature(scenename,action.f, action.v, id_element !== null ? id_element : parseInt(elementNameToId(action.e)),filters)
        break;
        case 'setDefault': 
          projectActions.setDefault(action.e,action.v)
          // setFeature(scenename,action.f, action.v, id_element !== null ? id_element : parseInt(elementNameToId(action.e)),filters)
        break;
        case 'setChapter':
          qnrActions.setChapter(action.v)
        break;
        case 'showModal':
          projectActions.setModal(action.v)
        break;
        case 'add':
          addElement( table ,getNewElement(scenename,id_element,null,action.typ,action.name,action.label,null,action.measurements))
        break;
        case 'addGroup':
          switch (action.driver){
            case 'roomSize':
              const actualLayer = projectActions.scenes[table === 'initialTable' ? 0 : 1].layers[0].layer_1;
              const area = actualLayer.areas[0].floor.measurements.area;
              let areaOption = 0;
              if(parseFloat(area) > 5 && parseFloat(area) <= 8){areaOption = 1}
              if(parseFloat(area) > 8){areaOption = 2}
              action.variants[areaOption].elements.forEach(el => {
                addElement( table,getNewElement(scenename,elementNameToId(el.e),null,el.typ,el.name,el.label,null,el.measurements))
              }) 
            break;
            default:
          }
        break;
        case 'removeAll':
          removeElement(table,id_element)
        break;
        default:
          console.log('TRYING TO PERFORM ACTION',action)
      }                              
    }

    const performActions = (scenename = 'mScene', actions = [],id_elements = [], filters=[]) => {

      actions.forEach(action => {
            if(id_elements.length > 0){
                id_elements.forEach(el_id => {
                  performSingleAction(scenename,action,el_id,filters)
                })
            } else {
              performSingleAction(scenename,action,parseInt(elementNameToId(action.e)),filters)           
            }
        })
    }

    const changeSize = (e) => {
        const { name, value, id } = e.target;
        
        console.log('IN SIZE',name, value, id)

        const realValue =  parseFloat(value) / 100;

        const tempScene = projectActions.scenes[0]

        const tempVerts = tempScene.layers[0].layer_1.vertices
        
        const foundElement = tempScene.layers[0].layer_1.lines.find(el => {
            return el.id === id
        })

        const connectedElements = tempScene.layers[0].layer_1.lines.filter(elm => elm.id !== foundElement.id).filter(el => {
            return el.v1 === foundElement.v2 || el.v2 === foundElement.v2
        })

        const extendLn  = extendLine(
            tempVerts[connectedElements[0].v1].x,
            tempVerts[connectedElements[0].v1].y,
            tempVerts[connectedElements[0].v2].x,
            tempVerts[connectedElements[0].v2].y,
            realValue
          )
        
        const angle = angleBetweenTwoPoints(
            tempVerts[foundElement.v1].x,
            tempVerts[foundElement.v1].y,
            tempVerts[foundElement.v2].x,
            tempVerts[foundElement.v2].y
        )

        const oldDistance = verticesDistance(tempVerts[foundElement.v1],tempVerts[foundElement.v2])

        if(oldDistance < realValue){
            const diff = realValue - oldDistance;
            if(angle === 0){
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].x += diff;
                  tempVerts[connectedElements[0].v2].x += diff;
                } else {
                  tempVerts[foundElement.v2].x += diff;
                }
            } else if (angle > 0 && angle < 1.6) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].y += diff;
                  tempVerts[connectedElements[0].v2].y += diff;
                } else {
                  tempVerts[foundElement.v2].y += diff;
                }
            } else if (angle > 3) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].x -= diff;
                  tempVerts[connectedElements[0].v2].x -= diff;
                } else {
                  tempVerts[foundElement.v2].x -= diff;
                }
            } else if (angle > 2 && angle < 3) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].x -= diff;
                  tempVerts[connectedElements[0].v2].x -= diff;
                } else {
                  tempVerts[foundElement.v2].x -= diff;
                }
              } else if (angle < -1.5 && angle > -3) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].y -= diff;
                  tempVerts[connectedElements[0].v2].y -= diff;
                } else {
                  tempVerts[foundElement.v2].y -= diff;
                }
              } else if (angle < -3) {
                if(connectedElements.length > 0){
                  tempVerts[connectedElements[0].v1].x -= diff;
                  tempVerts[connectedElements[0].v2].x -= diff;
                } else {
                  tempVerts[foundElement.v2].x -= diff;
                }
              }
          }
          if(oldDistance > realValue){
            const diff = oldDistance - realValue;
            if(angle === 0){
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].x -= diff;
                tempVerts[connectedElements[0].v2].x -= diff;
              } else {
                tempVerts[foundElement.v2].x -= diff;
              }
            } else if (angle > 0 && angle < 1.6) {
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].y -= diff;
                tempVerts[connectedElements[0].v2].y -= diff;
              } else {
                tempVerts[foundElement.v2].y -= diff;
              }
            } else if (angle > 3) {
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].x += diff;
                tempVerts[connectedElements[0].v2].x += diff;
              } else {
                tempVerts[foundElement.v2].x += diff;
              }
            } else if (angle < -1.5 && angle > -3) {
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].y += diff;
                tempVerts[connectedElements[0].v2].y += diff;
              } else {
                tempVerts[foundElement.v2].y += diff;
              }
            } else if (angle < -3) {
              if(connectedElements.length > 0){
                tempVerts[connectedElements[0].v1].x += diff;
                tempVerts[connectedElements[0].v2].x += diff;
              } else {
                tempVerts[foundElement.v2].x += diff;
              }
            }
          }
        
          setNewVertices(tempVerts)
    }

    return (
        <SceneContext.Provider value={{
            viewMode, setViewMode,
            scene, setScene, setSceneParameter,  setCurrentScene, layer,
            mScene, setMScene, copyScene, saveScenes, resetScenes, deleteScene,
            refresh, setRefresh,
            elements, setElements, addElement, addElementToAll, removeElement, removeElement, 
            moveToGroup,removeFromGroup,resetGroups,toggleBetweenGroups,
            setFeature, setFeatureOfAll, setGroupFeature, 
            performActions,
            replaceLines, replaceVertices,setNewVertices,changeSize,
            currentArea, setCurrentArea,
            selected, setSelected,
            active, setActive,
            over, setOver,
            shiftActive
        }}>{children}</SceneContext.Provider>
    )
}

export default SceneContext; 