import React, { useState, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import QnrContext from '../../context/QnrContext'
import { message } from 'antd'

const SaveResult = () => {
    const projectActions = useContext(ProjectContext)
    const qnrActions = useContext(QnrContext)

    const [title, setTitle] = useState('')

    const handleForm = () => {
        console.log('SAVING',title)
        if(projectActions.results[0]){
            if(title === ''){
                console.log('SAVING EMPTY',title)

                message.error({
                    content: projectActions.messages['emptyField'],
                    key:"updatable"
                });
            } else {
                projectActions.addResult(projectActions.results[0],title)
                    .then(() => {
                        console.log('IN SAVE',projectActions.results)
                        projectActions.saveProject()
                    })
            }            
        } else {
            message.error({
                content: projectActions.messages['noCalculations'],
                key:"updatable"
            });            
        }
    }

    return (
        <div style={{margin:'1em 0', background:'white',padding:'2em',borderRadius:'1em'}}>
            {projectActions.results[0] ? <h1>{projectActions.results[0].overall.toFixed(2)} €</h1> : <h3>No results</h3>}
            <label>
                <input placeholder="Escribe aquí tus comentarios sobre el presupuesto..." type="text" name="resultName" onChange={(e) => setTitle(e.target.value)}/>
                (Escribre un máximo de 125 caracteres)
            </label>
            <div style={{display:'flex',justifyContent:'space-between',marginTop:'2em'}}>
                <div className='q-navbtn border' onClick={() => {projectActions.setPopup(null);projectActions.setModal(null)}}>En otro momento</div>
                <div className='q-navbtn border primary' onClick={() => handleForm()}>Guardar</div>
            </div>
        </div>
    )
}

export default SaveResult