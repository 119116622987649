import React from 'react'
import { getThumb } from '../../utils/formatUtils'
import { AiOutlineCheck } from 'react-icons/ai'

const Thumb = ({value, oldValue, action, shape=null}) => {
  return (
    <div>
        <div
                onClick={action}
                style={{
                    backgroundImage:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
                    backgroundSize:'200px',
                    flex:'0 0 45%',
                    backgroundPosition:'center', 
                    backgroundRepeat:'no-repeat'  ,
                    position:'relative',
                    height:'100px',
                    width:'100px', 
                    borderRadius:shape === 'circle' ? '50%' : '.7em',
                    // border:'1px solid red'
                    border:value.value === oldValue ? '2px solid #007DFF' : '2px solid white',
                    boxShadow:'3px 3px 10px #0002'
                }}
                ><div style={{
                    display:'flex',
                    alignItems:'flex-end',
                    gap:'1em',
                    cursor:'pointer',
                    backgroundImage:value.img ? `url(${process.env.PUBLIC_URL}${getThumb(value.img[0])})` : null,
                    backgroundSize:'cover',
                    backgroundPosition:'center', 
                    backgroundRepeat:'no-repeat',
                    borderRadius:shape === 'circle' ? '50%' : '.7em',
                    height:'100%',
                }}
            >
            {shape !== 'circle' && <div style={{
                padding:'10px 10px',
                background:'linear-gradient(360deg, rgba(21, 19, 19, 0.7) 0%, rgba(21, 19, 19, 0) 100%)',
                borderRadius:'.5em',
                width:'100%'
            }}>{value.label}</div>}
            </div> 
            {value.value === oldValue && <div style={{
                    position:'absolute',
                    top:'10px',
                    right:'10px',
                    background:'#007DFF',
                    width:'20px',
                    height:'20px',
                    borderRadius:'10px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                <AiOutlineCheck /></div>}
            </div>
            {shape === 'circle' && <div style={{color:'grey', width:'100px', overlay:'hidden',fontSize:'10px'}}>{value.label}</div>}
        </div>
  )
}

export default Thumb