import { useState, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import '../scss/style.scss';
import Qnr from '../pages/Qnr';
import ExtQnr from '../pages/ExtQnr';
import Chatroom from '../pages/Chatroom';
import SharedBudget from "./SharedBudget/SharedBudget";
import AuthContext from '../context/AuthContext';
import { AdminProvider } from '../context/AdminContext';
import { ProjectProvider } from '../context/ProjectContext';
import { QnrProvider } from '../context/QnrContext'
import { CalcProvider } from '../context/CalcContext'
import { SceneProvider } from '../context/SceneContext'
import { ModalProvider } from '../context/ModalContext';
import { ChatProvider } from '../context/ChatContext';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import Register from '../pages/Register';
import Users from '../pages/Users';
import Projects from '../pages/Projects';
import Clients from '../pages/Clients';
import Calendr from '../pages/Calendr';
import Jobs from '../pages/Jobs';
import Stats from '../pages/Stats';
import Documents from '../pages/Documents';
import Dashboard from '../pages/Dashboard';
import Summary from '../pages/Summary';
import MiniProjects from '../pages/MiniProjects';
import RemoveTrailingSlash from '../utils/RemoveTrailingSlash'

const Homyplan = () => {

  const queryClient = new QueryClient()
  const { currentUser } = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ProjectProvider>
        <QnrProvider>
          <AdminProvider>
            <SceneProvider>
              <ModalProvider>
                <CalcProvider>
                  <ChatProvider>
                      <BrowserRouter>
                        <>
                        <RemoveTrailingSlash/>
                        <Routes>
                          <Route path="/login" element={<Login />}/>
                          <Route path="/" element={<Qnr />} />
                          <Route path="/cocina" element={<Qnr />} />
                          <Route path="/bano" element={<Qnr />} />
                          <Route path="/project/:projectID" element={<RequireAuth><ExtQnr /></RequireAuth>}/>
                          <Route path="/results/:projectID" element={<SharedBudget />} />
                          <Route path="/summary/:userId" element={<Summary />} />
                          <Route path="/profile/:userId" element={<RequireAuth><Profile /></RequireAuth>} />
                          <Route path="/chatroom" element={<RequireAuth><Chatroom /></RequireAuth>} exact />
                          <Route path="/register" element={<Register />} exact />
                          <Route path="/projects" element={<RequireAuth><Projects /></RequireAuth>} exact />
                          <Route path="/calendar" element={<RequireAuth><Calendr /></RequireAuth>} exact />
                          <Route path="/documents" element={<RequireAuth><Documents /></RequireAuth>} exact />
                          <Route path="/users" element={<RequireAuth><Users /></RequireAuth>} exact/>
                          <Route path="/jobs" element={<RequireAuth><Jobs /></RequireAuth>} exact/>
                          <Route path="/stats" element={<RequireAuth><Stats /></RequireAuth>} exact/>
                          <Route path="/miniprojects/:userId" element={<RequireAuth><MiniProjects /></RequireAuth>} exact />
                          <Route path="/myprojects" element={<RequireAuth><Projects /></RequireAuth>} exact />
                          <Route path="/clients" element={<RequireAuth><Clients /></RequireAuth>} exact />
                          <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} exact />
                        </Routes>
                        </>
                      </BrowserRouter>
                  </ChatProvider>
                </CalcProvider>
              </ModalProvider>
            </SceneProvider>
          </AdminProvider>
        </QnrProvider>
      </ProjectProvider>
    </QueryClientProvider>
  );
}

export default Homyplan;
