import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import UserPanel from './UserPanel'
import { Button } from 'antd'
import AuthContext from '../../context/AuthContext'

const Navbar = () => {

  const navigate = useNavigate();

    const authActions = useContext(AuthContext)

    const inputCss = {
        height:'2em',
        width:'200px'
    }

  return (
    <div className="navbar">
        <div className='logo'>
            <img className='q-logo' src={process.env.PUBLIC_URL + 'homyplan-logo.svg'} alt="HOMYPLAN-LOGO" />
        </div>
        <div className='navbar-info'>
          <div>PROFILE TYPE : {authActions.user.profile_type}</div>
          <div>SELECTED USER : {authActions.selectedUser ? authActions.selectedUser : 'N/A'}</div>
        </div>
    </div>
  )
}

export default Navbar