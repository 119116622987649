import React, { useContext, useRef, useState, useEffect, Fragment } from 'react'
import {useLocation, useNavigate} from "react-router-dom"
import ProjectContext from '../../context/ProjectContext'
import QnrContext from '../../context/QnrContext'
import CalcContext from '../../context/CalcContext'
import SceneContext from '../../context/SceneContext'
import AuthContext from '../../context/AuthContext'
import ModalContext from '../../context/ModalContext'
import { motion } from 'framer-motion'
import Carousel from '../widgets/Carousel'
import Slider from '../widgets/Slider'
import StyleImg from '../widgets/StyleImg'
import Selector from '../widgets/Selector'
import ImageSlider from '../widgets/ImageSlider'
import Overlay from '../widgets/Overlay'
import Budget from '../budget/Budget'
import Results from '../results/Results'
import SceneTable from '../results/SceneTable'
import ShapePlanner from '../planner/ShapePlanner'
import ShapePlanner2 from '../planner/ShapePlanner2'
import Register from '../results/Register'
import { createElement, getItem, filteredItems, checkFilterItems, getNewFromCalcData, checkActions, getIdfromSelected, getElementOfId } from '../../utils/sceneUtils'
import { elementNameToId, formatDefaults, getItemValueLabel, getItemValueSuper, elementIdToName } from '../../utils/formatUtils'
import { cleanResults } from '../../utils/calcUtils'
import {Form, modal, Input, Checkbox, Button, Tooltip, message, Card, Carousel as AntdCarousel, Tabs} from 'antd';
import Summary from '../results/Summary'
import ZipCodeCities from '../results/ZipCodeCities'
import { verticesDistance } from '../../utils/geometry'
import { QuestionCircleOutlined } from '@ant-design/icons';
import {BsArrowLeft, BsArrowRight, BsCheck2, BsFileBreakFill, BsPlus, BsX} from 'react-icons/bs'
import { FiChevronDown, FiChevronUp, FiArrowRight } from 'react-icons/fi';
import FeatureInput from '../widgets/FeatureInput'
import StyleSelector from '../widgets/StyleSelector'
import BigImg from '../widgets/BigImg'
import Properties from '../widgets/Properties'
import ProductInfo from '../widgets/ProductInfo'
import { firestore as db } from "../../firebase/config";
import Meta from "antd/es/card/Meta";
import DecisionSelector from '../widgets/DecisionSelector'
import SelectorInput from '../widgets/SelectorInput'
import GroupSelector from '../widgets/GroupSelector'
import Combo from './Combo'
import Sum from '../dashboard/Sum'

const key = "updatable";

const Question = ({q,refreshCategory,itemId=null}) => { 

  const navigate = useNavigate()

  const { calcData, project, setDefault, saveProject, saveScene, messages, addResult } = useContext(ProjectContext)
  const { activeQ, setActiveQ, qnrData, currentQnr, setCurrentQnr, qnrCities } = useContext(QnrContext)

  const authActions = useContext(AuthContext)
  const projectActions = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)
  const modalActions = useContext(ModalContext)
  
  const [ refresh, setRefresh ] = useState(false) 
  const [ edit, setEdit ] = useState(false) 
  const [ projectID, setProjectID ] = useState('')
  const [ projectName, setProjectName ] = useState('')
  const [ activeDetail, setActiveDetail ] = useState(null)
  const [ height, setHeight ] = useState(projectActions.project.defaults.height * 100)
  const location = useLocation();

  const [actualLayer, setActualLayer] = useState(null)
  const [saveOpen, setSaveOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [style, setStyle] = useState(projectActions.project.defaults.style ?? 0)
  const [substyle, setSubstyle] = useState(projectActions.project.defaults.substyle ?? 0)
      
  useEffect(() => {
    if(projectActions.scenes[0] && q.table === 'initialTable' || q.table === 'project' || q.table === 'all'){     
      setActualLayer(() => projectActions.scenes[0].layers[0].layer_1)
    } else if(projectActions.scenes[1]) {
      setActualLayer(() => projectActions.scenes[1].layers[0].layer_1)
    }
  },[q.table,projectActions.scenes])
  
  const handleDragStart = (e) => {
    console.log('Start drag',e.target.dataset.name)
  } 
  
  const handleDragEnd = (e) => {
    if(sceneActions.over === 'canvas'){
      const {dataset} = e.target;
      const option = q.options[parseInt(dataset.option)]
      let features = new Array(8).fill(null);
      if(option.filter){
        option.filter.forEach(flt => {
          features[0] = projectActions.roomType ? projectActions.roomType : 6;
          features[flt.f] = flt.v;
          features[7] = (q.table && q.table === 'initialTable') ? null : getNewFromCalcData(calcData,option.id_element)
        })
      }

      const itemData = projectActions.elementsData.items.find(item => item.type === parseInt(dataset.typology));
      let measurements = itemData.measurements ? itemData.measurements : undefined;

      if(q.table === 'integratedTable'){
        const zeros = new Array(16).fill(0)
        features = features.slice(0,8).concat(zeros) 
        features[15] = 2;
      }
      console.log('End drag = ADD',dataset)
      const newElement = createElement(option.name,option.label,option.id_element,'doors',features,measurements)
      if(q.table === 'all'){
        sceneActions.addElementToAll(q.table,newElement)
      } else {
        sceneActions.addElement(q.table,newElement)
      }

    }  
  } 

  const handleActions = (e) => {
    const { value, dataset } = e.target;

    q.options
      && q.options[parseInt(dataset.option)].buttons 
      && q.options[parseInt(dataset.option)].buttons[value].actions
      && sceneActions.performActions(null,q.options[parseInt(dataset.option)].buttons[value].actions)

    if(q.options && q.options[parseInt(dataset.option)] && q.options[parseInt(dataset.option)].actions){
      sceneActions.performActions(null,q.options[parseInt(dataset.option)].actions)
    }
    const card = document.getElementById('scroll-chapter-project');
    card && card.scroll({
      top: 0
    })
  }

  const performActions = (e) => {

    const { dataset } = e.target

    
    if(q.options){
      if(q.options[parseInt(dataset.option)]){
        const actions = q.options[parseInt(dataset.option)].actions;
          sceneActions.performActions(null,actions)
      }

      // projectActions.setModal('c-28')
    }
  }

  const handleFormValue = (e) => {
    e.preventDefault()
    projectActions.setDefault('height',height,'height');
    sceneActions.setSceneParameter('height',height)
    refreshCategory()
    // setRefresh(!refresh)
  }

  const handlePropertyChange = (e) => {
    const { dataset, value, name } = e.target;

    let filters = []

    if(dataset.option && q.options[dataset.option].filter){
      filters = q.options[dataset.option].filter
    }

    console.log('F:',dataset.feature, 'V:',value, name, 'ID:',dataset.itemid)

    sceneActions.setFeature('mScene',parseInt(dataset.feature),parseInt(value),null,filters,dataset.itemid)
  }

  const handleFeatureChange = (e) => {
    const { dataset, value, name } = e.target;

    console.log('FEAT CHANGE ',q.table,refresh,dataset.feature,value)

    sceneActions.setFeature(q.table === 'integratedTable' ? 'mScene' : 'scene',dataset.feature,value,parseInt(dataset.idelement),[],dataset.id)

    if(q.table === 'all'){
      const columns = projectActions.calcData.columns.find(f => f.old === parseInt(dataset.feature))

      if(columns && columns.id_column && projectActions.scenes.length > 1){

        console.log('CONNECTED',columns.id_column,parseInt(value))

        sceneActions.setFeature('mScene',columns.id_column,value,parseInt(dataset.idelement),[],dataset.id)
      }
    }

    // if(q.table !== 'integratedTable'){
    //   value = parseString(value.toString().split(',')[0])
    // }    

    setRefresh(!refresh)
    projectActions.setRefresh(!projectActions.refresh)
  }

  const handleCreateProject = (e) => {
    console.log('CREATE')
  }
  
  const handleSaveProject = (e) => {
    if(projectID !== ''){
      sceneActions.saveScenes(projectID,projectName)
    } else {
      message.error({
        content: messages.emptyInput,
        duration: 2,
      })
    }
  }
  
  const handleSaveNewProject = () => {

    let now = new Date();

    let newName = document.getElementById('projectName').value

    console.log('IN SAVE NEW PROJECT',projectActions.scenes,authActions.selectedUser)

    db.collection("Projects")
    .add({
      name: newName !== '' ? newName : projectActions.roomType === 8 ? "Cocina Proyecto" : "Bano Proyecto",
      plan: "basic",
      initialQNR: {
        status: "incomplete",
      },
      zipCode: localStorage.getItem("city"),
      images: {
        floorplan: "",
        initialview: "",
        integratedview: "",
        photo: "",
        renders: [],
        thumbnail: "",
      },
      marketPlaceIntro: false,
      access: "pending",
      active:true,
      // user
      //   ? user.profile_type === "agency"
      //     ? "public"
      //     : "private"
      //   : "Invalid",
      userId: authActions.selectedUser ? authActions.selectedUser : authActions.currentUser.uid,
      created_At: now,
      last_Modified_At: now,
      results: cleanResults(projectActions.results),
      scope: projectActions.project.scope,
      defaults:formatDefaults(projectActions.project.defaults),
      scenes: projectActions.scenes
    })
    .then((project) => {
      message.success({
        content: 'SAVE OK',
        key,
        duration: 2,
      });
      navigate(`/summary/${authActions.selectedUser ? authActions.selectedUser : authActions.currentUser.uid}`)
      // navigate("/results/" + project.id)
    })
    .catch(err => console.log(projectActions.scenes,projectActions.project,projectActions.results))
  }

  const handleAddResult = (e) => {
      console.log('ADD RESULTS')
      projectActions.addResult(projectActions.results[0])
      // console.log(results)
  }
  
  const handleOptionChange = (e) => {
    const link = e.target.dataset.link;
    const { name:qId, value, dataset } = e.target;

    const tempQ = currentQnr[link ? link : qId];
    
    const option = q.options[value]
    
    sceneActions.performActions(q.table === 'integratedTable' ? 'mScene' : 'scene',option.actions)

    setCurrentQnr({...currentQnr,[link ? link : qId]:{...tempQ,value:dataset.oid}})
  }

  const showModal = (e) => {
    const { dataset } = e.target
    
    const option = q.options[dataset.option];

    console.log('MODAL',e.target,option.modal)

    let comp = <BigImg source={option} />

    if(option.modal && option.modal.length > 0){
      let component = <BigImg source={option} />

      comp = option.modal.map((m,i) => {
        switch (m){
          case 'BigImg':
            return <BigImg key={i} source={option} />
          case 'Properties':
            return <Properties key={i} table={q.table} source={option} properties={q.properties} preview={q.preview}/>
          case 'ModelInfo':
            return <ProductInfo key={i} table={q.table} source={option} properties={q.properties} preview={q.preview}/>
        }
      });
    }
    
    projectActions.setModalData(comp)

    projectActions.setModal(true)
  }

  const showProductInfo = (e) => {
    const { dataset } = e.target

    const option = q.options[dataset.option];

    console.log('MODAL',e.target,option.modal)

    let comp = <BigImg source={option} />

    if(option.modal && option.modal.length > 0){
      let component = <BigImg source={option} />

      comp = option.modal.map((m,i) => {
            return <ProductInfo key={i} table={q.table} source={option} properties={q.properties} preview={q.preview}/>
      });
    } 
    
    modalActions.setModalData({
      heading: "ProductInfo",
      body: (comp),
      buttons: [
        { text: "Cancel", type: "text", onClick: "close" },
      ]
    });    
  }

  const handleModification = (e) => {
    const { name:qId, value, dataset, checked } = e.target

    const option = q.options[dataset.option];

    sceneActions.performActions('mScene',option.actions,[dataset.idelement],[{f:1,v:dataset.typology}])
 
    setCurrentQnr({...currentQnr,[qId]:{...currentQnr[qId],value:option.id}}) 
  }

  const handleModButton = (e) => {
    const { name:qId, value, dataset, checked } = e.target

    sceneActions.performActions(q.table === 'initialTable' ? '' : 'mScene',q.buttons[dataset.which].actions ,[dataset.idelement],[{f:1,v:dataset.typology}])

    if(q.options[dataset.option].actions){     
      q.options[dataset.option].actions.forEach(a => {
        if(a.a === 'add'){
          sceneActions.performActions('mScene',q.buttons[dataset.which].actions,[elementNameToId(a.e)],[{f:1,v:a.v}])
        }
      })
    }
  }

  const handleItemBox = (e) => {
    const { checked, id, value } = e.target;
    const option = q.options[value]
    // console.log('itemadd', q.table, projectActions.roomType )
    if(checked){
      let features = new Array(10).fill(null);
      if(option.filter){
        option.filter.forEach(flt => {
          features[0] = projectActions.roomType ? projectActions.roomType : 6;
          features[flt.f] = flt.v;
          features[7] = (q.table && q.table === 'initialTable') ? null : getNewFromCalcData(calcData,parseInt(option.id_element))
        })
      }
      const itemData = projectActions.elementsData.items.concat(projectActions.elementsData.holes)
        .find(item => item.type === parseInt(option.filter[0].v));

        console.log(itemData,option.filter[0].v,projectActions.elementsData.items);

      let measurements = itemData.measurements ? itemData.measurements : undefined;
      if(q.table === 'integratedTable'){
        const zeros = new Array(16).fill(0)
        features = features.slice(0,8).concat(zeros) 
        features[0] = projectActions.roomType ? projectActions.roomType : 6;
        features[15] = 2;
      }
      const newElement = createElement(option.name,option.label,option.id_element,elementIdToName(option.id_element),features,measurements)
    
      if(q.table === 'all'){
        sceneActions.addElementToAll(q.table,newElement)
      } else {
        sceneActions.addElement(q.table,newElement)
      }

      if(option.actions){
        option.actions.forEach(a => {
          if(a.a === 'add'){ 
            const addFeatures = [...features];
            addFeatures[parseInt(a.f)] = parseInt(a.v);
            const newAdd = createElement(a.name,a.label,option.id_element,elementIdToName(option.id_element),addFeatures,measurements)
            if(q.table === 'all'){
              sceneActions.addElementToAll(q.table,newAdd)
            } else {
              sceneActions.addElement(q.table,newAdd)
            }
          } else {
            sceneActions.performSingleAction(q.table,option.actions,option.id_element)
          }
        })
      }

      console.log('ADD BOX',newElement)

    } else {
      removeElement(e)
    }

    setRefresh(!refresh)
  }

  const addElement = (e) => {
    console.log('REMOVE',e.target)
    const { dataset } = e.target
    const itemData = projectActions.elementsData.items.find(item => item.type === parseInt(dataset.typology));
    let measurements = itemData.measurements ? itemData.measurements : undefined;

    let features = new Array(8).fill(null);
    features[0] = projectActions.roomType ? projectActions.roomType : 6;
    features[1] = parseInt(dataset.typology)
    if(q.table === 'integratedTable'){
      const zeros = new Array(16).fill(0)
      features[7] = (q.table && q.table === 'initialTable') ? null : getNewFromCalcData(calcData,parseInt(dataset.idelement))
      features = features.slice(0,8).concat(zeros)
      features[15] = 2;
      console.log('WCHODZI',q.table,projectActions.roomType,features,getNewFromCalcData(calcData,dataset.idelement))
    }

    const newElement = createElement(dataset.name,dataset.label,dataset.idelement,elementIdToName(dataset.idelement),features,measurements);

    console.log('ADD',dataset.name,dataset.label,q.table,newElement,features)

    if(q.table === 'all'){
      sceneActions.addElementToAll(q.table,newElement)
    } else {
      sceneActions.addElement(q.table,newElement)
    }
  }

  const removeElementNum = (e) => {
    const { dataset } = e.target

    const foundElement = actualLayer.items.find(item => {
      return item.features[1] === parseInt(dataset.typology)
    })

    if(foundElement){
      sceneActions.removeElement(q.table,dataset.idelement, dataset.typology, foundElement.id)
    }
  }

  const removeElement = (e) => {
    const { dataset } = e.target

    const option = q.options[dataset.option];
 
    const foundItem = getItem(actualLayer,parseInt(dataset.idelement),dataset.typology,option.filter)

    sceneActions.removeElement(q.table,parseInt(dataset.idelement), dataset.typology, foundItem ? foundItem.id : null)

    if(option.actions){
      option.actions.forEach(a => {
        if(a.a === 'add'){
          const addIdElement = elementNameToId(a.e)
          const addFound = filteredItems(actualLayer.items,[{"f":a.f,"v":a.v}])
          console.log('IN REMOVE',a,actualLayer,addFound)
          addFound.forEach(removeItem => {
            sceneActions.removeElement(q.table,parseInt(dataset.idelement), parseInt(a.v), removeItem.id)
          })
        }
      })
    }
  }

  const handleElementChange = (e) => {

    console.log('changing element')
  }

  const handleProjectValue = (e) => {
    const { value, name, dataset = null } = e.target;

    projectActions.setDefault(name,value,dataset ? dataset.datatype : null)

    q.actions && q.actions.forEach(action => {
      console.log('===============action',action,)
      switch(action.a){
        case 'refreshScene':
          sceneActions.setCurrentScene(project.defaults.houseShape);
        break;
        case 'refreshVertices':
          sceneActions.setNewVertices(projectActions.sceneData[value].options[0].vertices);
        break;
        case 'refreshLines':
          sceneActions.replaceLines(projectActions.sceneData[value].scene.layers[0].layer_1)
        break;
        case 'refreshSceneVertices':
          sceneActions.replaceVertices(projectActions.sceneData[value].scene.layers[0].layer_1.vertices)
        break;
        default:
      }
    })

    setRefresh(!refresh)
  }

  const handleSetVertices = (e) => {
    const { value } = e.target;
    
    console.log('VERTS',projectActions.sceneData[projectActions.project.defaults.houseShape].options[value]);



    if(projectActions.sceneData[projectActions.project.defaults.houseShape].options){   
      sceneActions.setNewVertices(projectActions.sceneData[projectActions.project.defaults.houseShape].options[value].vertices);
    }

    setRefresh(!refresh)
  }

  const handleLevelChange = (e) => {
    const { value, name, dataset = null } = e.target;

    console.log('lvl',value, name)

    sceneActions.performActions(q.scene,q.options[value].actions, q.id_element)
  }

  const btnAction = (action) => {
    console.log(action)
    switch (action){
      case 'nextSlide':
        setActiveQ(activeQ + 1)
      break;      
      default:
    }
  }

  const question = (refr) => {
    const elements = sceneActions.scene !== null ? sceneActions.scene.scene.layers[0].layer_1.lines : [];

    switch (q.type){
      case "tab":
      case "tabs":
        return <SelectorInput table={q.table} q={q} itemId={itemId}/>
      case "combo":
      case "combos":
        return <Combo table={q.table} q={q} itemId={itemId}/>
      case "bigBtn":
        return <div className='big-btns'>
          {q.options && q.options.map((o,i) => <div key={i} className="q-navbtn on" onClick={() => btnAction(o.a)}>{o.label}</div>)}
        </div>
      case 'defaultValue':
        return <div className="q-inputs">
        {q.options && q.options.map((o,i) => {
          let oldValue = projectActions.project.defaults[o.id];
          switch(o.id){
            case 'height':
              oldValue = oldValue * 100
          }
          return <form key={i} style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center'}} onSubmit={handleFormValue}>
            <label>
              {o.label && <div>{o.label}</div>}
              <input type="text" name={o.id} value={height} className="q-input size" onChange={(e) => setHeight(e.target.value)}/><br/>
              <span className="q-description">{o.description}</span>
            </label>
            <button type="submit" className="btncheck off" style={{border:'none',height:'2.5em',cursor:'pointer',padding:'.5em 1em'}}>Guardar</button>
          </form>
        })}
        </div>
      case 'title':
        return <>
          {q.q_title && <div className='c-title'>{q.q_title}</div>}
          {q.q_description && <div className='q-description'>{q.q_description}</div>}
        </>
      case 'text':
        return <div className="q-inputs">
        {q.options && q.options.map((o,i) => {
          return <label key={i}>
            <input type="text" name={o.name} className="q-input size" placeholder={o.placeholder} onChange={handleProjectValue}/><br/>
            <span className="q-description">{o.description}</span>
          </label>
        })}
        </div>
      case 'number':
        return <div className="q-sizes">
        {q.options && q.options.map((o,i) => {
          let oldVal = null;
          switch (q.table){
            case 'project':
              oldVal = project.defaults[o.name]
            break;
            default:
          }
          return <label key={i}>
            <input type="number" name={o.name} className="q-input" placeholder={o.placeholder} onChange={handleProjectValue} value={oldVal}/><br/>
            <span className="q-description">{o.description}</span>
          </label>
        })}
        </div>
      case 'rangenum':
        return <div className="q-sizes">
        {q.options && q.options.map((o,i) => {
          let oldVal = null;
          switch (q.table){
            case 'project':
              oldVal = parseFloat(project.defaults[o.name])
            break;
            default:
          }
          return <label key={i} style={{width:'100%'}}>
            <input type="number" name={o.name} className="q-newsize" placeholder={o.placeholder} 
              onChange={handleProjectValue}
              min="200"
              max="300"
              data-datatype="height" 
              value={parseInt(oldVal * 100)}
            />cm
            <input type="range" name={o.name} className='q-size'
              style={{width:'100%'}}
              value={parseInt(oldVal * 100)}
              min="200"
              max="300"
              data-datatype="height"
              onChange={handleProjectValue}
            />
            <span className="q-description">{o.description}</span>
          </label>
        })}
        </div>
      case 'roomsize':
        const currentArea =  actualLayer ? actualLayer.areas[0].floor.measurements.area : null;
        let options = null;
        if(projectActions.sceneData[projectActions.project.defaults.houseShape]){
          options = projectActions.sceneData[projectActions.project.defaults.houseShape].options
        }
        return options && <div className="q-roomsizes">
          {q.subtitle && <p className="q-title" dangerouslySetInnerHTML={{__html: q.subtitle}} />}
           {options.map((o,i) => {
             let testArea = currentArea < (o.area + .4) && currentArea > (o.area - .5)  
             if(i === 0){
               testArea = parseFloat(currentArea) < (o.area + .4)  
             } else if (i === options.length - 1){
               testArea = parseFloat(currentArea) > (o.area - .5)   
             }
             return <label key={i} style={{padding:'0.5em'}} >
               <input onChange={handleSetVertices} value={i} name="roomsize" type="radio" checked={testArea}/>
               <div>{o.label}<sup>2</sup>{")"}</div>
             </label>
           }
        )}
        </div>
      case 'size':
        return <div>
          <label style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'1em'}}>
            <input style={{display:'inline',maxWidth:'30px',minWidth:'30px'}} checked={edit} type="checkbox" onChange={() => setEdit(!edit)}/>
            {q.edittitle ? q.edittitle : "Editar"}
          </label>
          <div className="q-sizes">       
          {edit && actualLayer.lines.filter(elem => elem.label).map((el,i) => {
            const vertices = actualLayer.vertices;
            return <label key={i}>
              <span className="q-description">Pared {el.label}</span>
              <input type="number" className={edit ? 'q-newsize editable' : 'q-newsize'}
                max="500"
                id={el.id} 
                value={parseInt(verticesDistance(vertices[el.v1],vertices[el.v2]) * 100)} 
                name={el.label} 
                disabled={!edit}
                onInput={sceneActions.changeSize}/><br/>
              <span>cm</span>
              {edit && <input type="range" className='q-newsize'
                max="500"
                min="10"
                id={el.id} 
                step="1"
                value={parseInt(verticesDistance(vertices[el.v1],vertices[el.v2]) * 100)} 
                name={"range_" + el.label} 
                onInput={sceneActions.changeSize}/>}
            </label>
          })}
        </div>
        </div>
      case 'collage':
        const positions=[
          [75,66],
          [50,40],
          [33,60],
        ]
        return <div className="q-collage">
          {q.options && q.options.map((img,i) => {
            const pos = {x:img.pos[0],y:img.pos[1]} 
            return <img style={{
              left:pos.x + '%',
              top:pos.y + '%'
            }} key={i} src={process.env.PUBLIC_URL + img.img} />
          })}
        </div>
      case 'label':
        return <div className='q-labels'>{q.options && q.options.map((o,i) => {
          let oldVal = null;
          switch (q.table){
            case 'project':
              oldVal = project.defaults[o.name];
            break;
            default:
          }
          return <label key={i} className={oldVal == o.value ? "q-label on" : "q-label off"}>
            {o.img && <img className={oldVal !== o.value ? "q-icon" : "q-icon on"} src={o.img} alt={o.label}/>}
            <input
              type="radio" 
              className='debug' 
              checked={oldVal == o.value} 
              name={o.name} value={o.value} 
              data-datatype={o.dataType} 
              onChange={handleProjectValue}
            />
          </label>
        })}</div>
      case 'groupSelector':
        return <GroupSelector q={q}/>
      case 'styles':
        return <StyleSelector q={q} styles={qnrData.styles} />
      case 'styleimg':
        console.log('STYLE');
        return <div style={{minWidth:'1000px'}}><StyleImg styles={qnrData.styles} /></div>
      case 'itemboxes':
        return <div className="q-itemboxes">{q.options && q.options.map((o,i) => {

          const itemCheck = checkFilterItems(actualLayer,parseInt(o.id_element),o.filter);
   
          {/* console.log('IN BOX',o.label,o.filter,itemCheck,actualLayer) */}

          return <div key={i} className={itemCheck && itemCheck.length > 0 ? "q-itembox on" : "q-itembox off"}>
            <label className="q-label">
              <div className={'flex items-center'}>
                {o.img && <img className="box-icon bg-white" src={o.img} alt={o.label}/>}
                {o.label && <div className="midtext">{o.label}</div>}
              </div>
              {itemCheck && itemCheck.length > 0
                  ? <span className="btncheck on"><BsCheck2 size="20px"/>
                    {/*<span className="box-btn-label">Añadido</span>*/}
                </span>
                  : <span className="btncheck off"><BsPlus size="20px"/>
                    {/*<span className="box-btn-label">&nbsp;&nbsp;Añadir&nbsp;&nbsp;</span>*/}
                </span> }
              <input type="checkbox"
                name={o.label}
                id={o.id}
                value={i}
                className="debug hide"
                onChange={handleItemBox}
                checked={itemCheck && itemCheck.length > 0}
                data-idelement={o.id_element}
                data-typology={o.filter[0].v}
                data-option={i}
              />
            </label>
          </div>
        })}</div>
      case 'selectElementFeature':
        return <div>
          {q.options && q.options.map((o,i) => {
            const elementData = projectActions.elementsData.elements.find(item => item.id_element == o.id_element)
            return <Fragment key={i}>
              <div className='q-title' style={{margin:0}}>{o.title}</div>
              <FeatureInput table={q.table} action={handleFeatureChange} elementData={elementData} property={o} />
            </Fragment>
          })}

        </div>
      case 'tabbedCoverings':
        return <Tabs defaultActiveKey="0">
          {q.options && q.options.map((o,i) => {
            return <Tabs.TabPane style={{flex:1}} tab={o.label} key={i}>
              {o.title && <div className="q-small-title">{o.title}</div>}
              {o.description && <div className="q-description">{o.description}</div>}
            </Tabs.TabPane>
          })}
        </Tabs>
      case 'addDecision':
        const selectedItem = getElementOfId(actualLayer,sceneActions.selected)        
        console.log(actualLayer,selectedItem,sceneActions.selected)
        return <div>
          Decisions: {sceneActions.selected}
          {q.options && q.options.map((o,i) => {
            const elementData = projectActions.elementsData.elements.find(item => item.id_element == o.id_element)
            return <Fragment key={i}>
              <div className='q-title' style={{margin:0}}>{o.title}</div>
              {/* <FeatureInput table={q.table} action={handleFeatureChange} elementData={elementData} property={o} /> */}
              <DecisionSelector id_element={o.id_element} />
            </Fragment>
          })}

        </div>
      case 'draggableElement':
        return <div className="q-details">{q.options && q.options.map((o,i) => {
          const existCheck = checkFilterItems(actualLayer,o.id_element,o.filter);
          return <div key={i} className={existCheck.length > 0 ? "q-detail on" : "q-detail off"}> 
            {o.icon && <div className='q-detail-item'
              draggable={true} 
              onDragStart={handleDragStart} 
              onDragEnd={handleDragEnd}
              data-option={i}
              data-name={o.name}
              data-label={o.label}
              data-typology={o.filter[0].v}
            >
            <img draggable={false} className="box-icon" src={o.icon} alt={o.label}/></div>}
            <div className="q-detail-content">
              {o.label && <div className="midtext">
                {o.label}
                {/* {q.optionsLabel && item && itemCheck && itemCheck.length > 0 && 
                  <div className='q-link' data-option={i} onClick={showModal}>{q.optionsLabel} <FiArrowRight /></div>} */}
              </div>}
            </div>
          </div>}
        )}</div>
      case 'details':
        return <div className="q-details">{q.options && q.options.map((o,i) => {
          const itemCheck = checkFilterItems(actualLayer,o.id_element,o.filter)

          let item = null
          let label = null;
          let superscript = null
          let checkEliminar = true;

          if(actualLayer){
            item = getItem(actualLayer,o.id_element,null,o.filter)
            label = item ? getItemValueLabel(projectActions.elementsData,item,17) : null
            superscript = item ? getItemValueSuper(projectActions.elementsData,item,17) : null
          }

          if(itemCheck.length > 0 && q.buttons && item){
            checkEliminar = checkActions(item,q.buttons[0].actions)
          } 

          return <div key={i} className={itemCheck.length > 0 ? "q-detail on" : "q-detail off"}>
              <div className='q-detail-item'>
                {o.img && <img className="box-icon" src={o.img} alt={o.label}/>}
              </div>
              <div className="q-detail-content">
              {o.label && <div className="midtext" style={{fontSize:'12px',padding:'0 1em',margin:0,width:'180px',color:itemCheck && itemCheck.length > 0 ? '#007DFF' : '#04073F'}}>
                {o.label}
                {q.optionsLabel && item && itemCheck && itemCheck.length > 0 && checkEliminar === false &&
                  <>
                    <div className='q-link' data-option={i} onClick={showModal}>{label ? label : q.optionsLabel}<FiArrowRight /></div>
                  </>}
              </div>}
              {!item || item.features[7] == getNewFromCalcData(calcData,item.id_element) 
              ? <label>
                {itemCheck && itemCheck.length > 0 
                  ? <span className="btncheck on"><BsCheck2 size="20px"/></span> 
                  : <span className="btncheck off"><BsPlus size="20px"/></span>}
                <input type="checkbox" 
                  name={o.label} 
                  id={o.id}
                  value={i} 
                  className="debug"
                  onChange={handleItemBox}
                  checked={itemCheck && itemCheck.length > 0}
                  data-idelement={o.id_element}
                  data-typology={o.filter[0].v}
                  data-option={i}
                /></label>
              :
              <div className="d-btns">
                {q.buttons && q.buttons.map((btn,j) => {
                  const checklist = checkActions(item,btn.actions);
                  return <label key={j}>
                    <span className={checklist === true ? "bordercheck on" : "bordercheck off"}
                      // style={{background:btn.label === "Eliminar" ? "#c00000" : ""}}
                    >
                    {/* {checklist === true 
                      ? <BsCheck2 size="20px"/> 
                      : btn.label === 'Eliminar' ? <BsX size="20px"/> : <BsPlus size="20px"/>} */}
                    <span className="box-btn-label">{btn.label}</span></span>
                    <input type="radio"
                      className='debug'
                      name={'element_' + i}
                      data-which={j}
                      data-option={i}
                      data-idelement={item.id_element}
                      data-typology={o.filter[0].v}
                      onClick={handleModButton}
                    />
                  </label>
                })}    
              </div>}
              </div>
            </div>
          })}
        </div>
      case 'detailnums':        
        return <div className="q-details">
          {q.options && actualLayer && q.options.map((o,i) => {
            let item = null
            let label = null;
            let superscript = null;

            const quantity = actualLayer.items.filter(item => {
              return item.features[1] == o.filter[0].v
            })
            const itemData = projectActions.elementsData.items.find(item => item.type == o.filter[0].v)

            label = quantity[0] ? getItemValueLabel(projectActions.elementsData,quantity[0],17) : null
            superscript = item ? getItemValueSuper(projectActions.elementsData,item,17) : null

            return <div key={i} className='q-detail'>
            <div className='q-detail-item'>
                <img className='box-icon' src={o.img} alt={o.label} />
                <div className="q-itemnum">
                  <div className="midtext">{o.label}                  
                  {q.optionsLabel && itemData && quantity.length > 0 &&
                    <div className='q-link' data-option={i} onClick={showModal}>{label ? label : q.optionsLabel}<FiArrowRight /></div>}
                  </div>
                </div>
                </div>
                <div className="plusminus">
                  <div className="sq-btn" data-idelement={o.id_element} data-typology={o.filter[0].v} data-name={o.name} data-label={o.label} data-option={i} onClick={removeElementNum}>-</div>
                  <div className="num">{quantity.length}</div>
                  <div className="sq-btn" data-idelement={o.id_element} data-typology={o.filter[0].v} data-name={o.name} data-label={o.label} data-option={i} onClick={addElement}>+</div>
                </div>
                </div>
            })}
        </div>
      case 'itemlist':
        return <div className="q-itemlist">
          {actualLayer && actualLayer.items.filter(it => {

            let test = []

            if(q.filter && q.filter.length > 0){
              q.filter.forEach(filt => {
                if(it.features[filt.f] === filt.v) {
                  test.push(true)
                } else {
                  test.push(false)
                }
              })
            }

            return q.id_element.includes(it.id_element) && test.some(t => t === true);
          }).map((item,i) => {
            return <span className="q-itemtab" key={i}>{item.name}<button
              onClick={removeElementNum}
              data-idelement={item.id_element}
              data-typology={item.features[1]}
            >x</button></span>
          })}
        </div>
      case 'itemmodlist':
        const modcount = actualLayer.items.filter(item => {
          let testFilter = []

          q.filter.forEach(ff => {
            if(item.features[ff.f] === ff.v){
              testFilter.push(true)
            } else {
              testFilter.push(false)
            }
          })

          return testFilter.some(fil => fil === true)
        }).length
        return <div className="q-itemmods">
          {actualLayer ? actualLayer.items.filter(item => {
          let testFilter = []

          q.filter.forEach(ff => {
            if(item.features[ff.f] === ff.v){
              testFilter.push(true)
            } else {
              testFilter.push(false)
            }
          })

          return testFilter.some(f => f === true)
        }).map((item,i) => {
            return <div key={i}>
              <span className="q-itemtab">{item.name}</span>
              {q.options && <div className='q-radios'>
                {q.options.map((o,j) => {
                  const checklist = checkActions(item,o.actions);

                  return <label key={j} className={checklist === true ? "q-radiobtn checked" : "q-radiobtn"} >
                  <input type="radio" id={o.id} name={'item_' + i} value={o.actions[0].v}
                    data-oid={o.id}
                    onChange={handleModification}
                    // checked={checklist}
                    className="debug"
                    data-option={j}
                    data-idelement={item.id_element}
                    data-typology={item.features[1]}
                  />{o.label}</label>})}
              </div>}
            </div>
          })
          :
          <h5>{q.emptyMessage}</h5>}
        </div>
      case 'itemnums':
        return <div className="q-itemboxes">
          {q.options && actualLayer && q.options.map((o,i) => {
            const quantity = actualLayer.items.filter(item => {
              return item.features[1] == o.filter[0].v
            })
            return <div key={i} className="q-itembox"><label>
                <img className='box-icon' src={o.img} alt={o.label} />
                <div style={{display:'flex',paddingLeft:'1em',width:'100%',justifyContent:'space-between',alignItems:'center'}}>
                  <div className="font-bold" title={q.table}>{o.label}</div>
                  <div className="plusminus">
                      <div className="sq-btn" data-name={o.name} data-label={o.name} data-idelement={o.id_element} data-typology={o.filter[0].v} data-option={i} onClick={removeElementNum}>-</div>
                      <div className="num">{quantity.length}</div>
                      <div className="sq-btn" data-name={o.name} data-label={o.name} data-idelement={o.id_element} data-typology={o.filter[0].v} data-option={i} onClick={addElement}>+</div>
                </div>
              </div></label>
            </div>
          })}
        </div>
      case 'btns':
        return <div>
          {q.name && <div>{q.name}</div>}
          <div  className="q-radiobtns">
          {q.options && q.options.map((o,i) => {
            return <label key={i} className="q-radiobtn"><input type="radio" name={q.id} onChange={handleOptionChange} value={o.id}/>{o.label}</label>
          })}
          </div>
        </div>
      case 'optbtns':
        return <div>
          {q.name && <div>{q.name}</div>}
          <div  className="q-radiobtns">
          {q.options && q.options.map((o,i) => {
            {/* const actualId = q.link ? q.link : o.id; */}

            {/* console.log(actualId) */}

            const optchck = currentQnr[q.id].value === o.id
            return <label key={i} className={optchck === true ? "q-radiobtn checked" : "q-radiobtn"}>
              <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                onChange={handleOptionChange}
                checked={optchck} 
                className="debug"
              />{o.label}</label>
          })}
          </div>
        </div>
      case 'widebtns':
        console.log('IN WIDEBTNS',currentQnr)
        return <>
          {q.name && <div>{q.name}</div>}
          <div className="q-widebtns">
          {q.options && q.options.map((o,i) => {
            const optchck = currentQnr[q.id].value === o.id
            return <div key={i} style={{position:'relative'}}><label className={optchck === true ? "q-widebtn checked" : "q-widebtn"}>
              {o.icon && <span style={{alignSelf:'flex-start'}}>{<img src={process.env.PUBLIC_URL + o.icon} />}</span>}
              <div>
                <div className='q-label' style={{color:optchck === true ? '#007DFF' : '#050850'}}>{o.label}</div>
                <div className='q-description'>{o.description}</div>
                {o.img && <div style={{height:'2em'}} />}
                  <input type="radio" id={o.id} name={q.id} value={i}
                    data-oid={o.id}
                    onChange={handleOptionChange}
                    checked={optchck} 
                    className="debug"
                /></div>
                {optchck ? <span className="btncheck on"><BsCheck2 size="20px"/></span> 
                  : <span className="btncheck off"><BsPlus size="20px"/></span> }
                </label>     
              {o.img && o.modal && <div className="q-link inside" data-option={i} onClick={showModal}>Ver imagen <FiArrowRight /></div>}
            </div>
          })}
          </div>
        </>
      case 'selectbtns':
        return <div>
          {q.name && <div>{q.name}</div>}
          <div  className="q-widebtns">
          {q.options && q.options.map((o,i) => {
            const optchck = currentQnr[q.id].value === o.id
            return <label key={i} className={optchck === true ? "q-widebtn checked" : "q-widebtn"}>
              <div><div className='q-label'>{o.label}</div>
              <div className='q-description'>{o.description}</div>
              <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                onChange={handleOptionChange}
                checked={optchck} 
                className="debug"
              /></div>
              {optchck  ? <span className="btncheck on"><BsCheck2 size="20px"/></span> 
                : <span className="btncheck off"><BsPlus size="20px"/></span> }
              </label>
          })}
          </div>
        </div>
      case 'lvlbtns':
        return <div style={{margin:'1em 0'}}>
          {q.name && <div style={{marginBottom:'-0.5em'}}>{q.name}</div>}
          <div  className="q-lvlbtns">
          {q.options && q.options.map((o,i) => {
            const optchck = currentQnr[q.id].value === o.id
            return <label key={i} className={optchck === true ? "q-radiobtn checked" : "q-radiobtn"}>
              <input type="radio" id={o.id} name={q.id} value={i}
                data-oid={o.id}
                onChange={handleOptionChange}
                checked={optchck} 
                className="debug"
              />{o.label}</label>
          })}
          </div>
        </div>
      case 'cards':
        return <div className="q-cards">
          {q.options && q.options.map((o,i) => {
            const actualId = q.link ? q.link : q.id 
            const cardChck = currentQnr[actualId].value === o.id
            const cardCss = {
              background:o.color ? o.color : 'gray'
            }
            {/* console.log('in panel',actualId) */}
            return <label key={i} className="q-card" style={cardCss} >
              <div>
                {o.icon && <img className="box-icon" src={o.icon} alt={o.label}/>}
                {o.label && <div className="q-cardtitle" dangerouslySetInnerHTML={{__html: o.label}} />}
                {o.description && <div className="q-description">{o.description}</div>}
                <input type="radio" name={q.id} value={i}
                  data-oid={o.id}
                  data-link={q.link}
                  onChange={handleOptionChange}
                  checked={cardChck}
                  className="debug"                    
                />
              </div>
              {cardChck === true && <Overlay />}
            </label>
          })}
        </div>
      case 'infocards':
         return <div className="q-cards">
          {q.options && q.options.map((o,i) => {
            const actualId = q.link ? q.link : q.id 
            const cardChck = currentQnr[actualId].value === o.id
            const iCardCss = {
              background:o.color ? o.color : 'gray',
              cursor:'default',
              width:q.size && '100%'
            }
            const iCss = {
              fontSize:'12px',
              paddingTop:'10px',
              position:'absolute',
              top:'-20px',
              left:'-20px',
              background:'#007DFF',
              width:'40px',
              height:'40px',
              borderRadius:'50%',
              textAlign:'center',
              overflow:'visible'
            }
            const listCss = {
              color:'#04073F',
              listStyle:'none',
              margin:'2em',
              listStyle:'square',
              display:'none'
 
            }
            return <div key={i} style={{position:'relative',marginTop:'2em',marginLeft:'20px',width:q.size && '100%'}}>
                  <div className="q-card qnr-card-custom flex gap-4 qnr-h-auto" style={iCardCss}>
                    {o.icon && <img className="box-icon" src={o.icon} alt={o.label} style={{background:'white',width:'72px',height:'72px'}}/>}
                    <div className={''}>
                      {o.label && <div className="q-small-title pb-2 my-0" dangerouslySetInnerHTML={{__html: o.label}} />}
                      {o.description && <div className="q-description" style={{color:' #777786',opacity:1,minHeight:'4em'}}>{o.description}</div>}
                      {o.list && <ul style={listCss} className='checkpoint-list'>
                        {o.list.map((li,j) => <li key={j}>
                          {li.title && <div style={{
                            textDecoration:li.checked ? 'line-through' : 'none'
                          }}>{li.title}</div>}
                          {li.description && <div style={{
                            color:'#555',
                            fontSize:'0.8em',
                            marginBottom:'1em'
                          }}>{li.description}</div>}
                        </li>)}
                      </ul>}
                      {o.buttons && o.buttons.map((btn,k) => <Button className="q-btn" style={{
                          width:'100%',
                          borderRadius:'10px',
                          padding:'1em 0',
                          height:'4em'
                        }}
                         data-option={i}
                         value={k}
                         onClick={handleActions}
                         key={k}
                      >{btn.label}</Button>)}
                    </div>

                </div>
                {q.numbers && <div style={iCss}>{i + 1}</div>}
              </div>
          })}
        </div>
      case 'infopanel':
        return <div className="q-cards" style={{justifyContent:'space-around',marginBottom:'5em',flexWrap:'wrap'}}>
          {q.options && q.options.map((o,i) => {
            const actualId = q.link ? q.link : q.id 
            const cardChck = currentQnr[actualId].value === o.id
            const iCardCss = {
              background:o.color ? o.color : 'gray',
              minHeight:'15em',
              cursor:'default'
            }
            const iCss = {
              fontSize:'12px',
              paddingTop:'10px',
              position:'absolute',
              top:'-20px',
              left:'-20px',
              background:'#007DFF',
              width:'40px',
              height:'40px',
              borderRadius:'50%',
              textAlign:'center',
              overflow:'visible'
            }
            const listCss = {
              color:'#04073F',
              listStyle:'none',
              margin:'2em',
              listStyle:'square'
            }
            return <div key={i} style={{position:'relative',marginTop:'3em'}}>
                  <div className="q-panel" style={iCardCss}>
                    {o.icon && <img className="box-icon" src={o.icon} alt={o.label} style={{background:'white'}}/>}
                    {o.label && <div className="q-small-title" dangerouslySetInnerHTML={{__html: o.label}} />}
                    {o.description && <div className="q-description" style={{color:' #777786',opacity:1,minHeight:'7em'}}>{o.description}</div>}
                    {o.list && <ul style={listCss} className='checkpoint-list'>
                      {o.list.map((li,j) => <li key={j}>
                        {li.title && <div style={{
                          textDecoration:li.checked ? 'line-through' : 'none'
                        }}>{li.title}</div>}
                        {li.description && <div style={{
                          color:'#555',
                          fontSize:'0.8em',
                          marginBottom:'1em'
                        }}>{li.description}</div>}
                      </li>)}
                    </ul>}
                    {o.buttons && o.buttons.map((btn,k) => <Button style={{
                      width:'100%',
                      borderRadius:'10px',
                      padding:'1em 0',
                      height:'4em'
                    }} className='q-btn' key={k}>{btn.label}</Button>)}
                </div>
                {q.numbers && <div style={iCss}>{i + 1}</div>}
              </div>
          })}
        </div>
      case 'squares':
        return <div className="q-squares">
          {q.options && q.options.map((o,i) => {
            const actualId = q.link ? q.link : q.id 
            const cardChck = currentQnr[actualId].value === o.id
            const cardCss = {
              background:o.color ? o.color : 'gray'
            }
            return <label key={i}
              className={(location.pathname.includes('cocina') ? 'q-square-kitchen ' : '') + 'q-square'}
              style={{...cardCss,
                backgroundColor:'white',
                backgroundImage:'url(' + process.env.PUBLIC_URL + o.img[0] + ')',
                backgroundPosition:'center',
                backgroundSize:'cover',
                backgroundRepeat:'no-repeat',
                borderRadius:'1em',
                display:'relative',
              }}
            >
              {cardChck === true && <Overlay />}
            {o.label && <div className="q-cardtitle" dangerouslySetInnerHTML={{__html: o.label}} />}
            {o.description && <div className="q-description">{o.description}</div>}
              <input type="radio" name={q.id} value={i}
                data-oid={o.id}
                data-link={q.link}
                onChange={handleOptionChange}
                checked={cardChck}
                className="debug"
              />
            </label>
          })}
        </div>
      case 'squares_':
        return <div className={'h-full p-4 flex flex-col justify-center items-center'}>
          <div className={'flex w-full justify-end gap-4 pb-4 wideonly'}>
            <span className="cursor-pointer"
              onClick={() => {
                document.getElementById('qnr-square').scrollLeft -= 370;
              }}
            >
              <BsArrowLeft size={20} />
            </span>
            <span className="cursor-pointer"
              onClick={() => {
                document.getElementById('qnr-square').scrollLeft += 370;
              }}
            >
              <BsArrowRight size={20} />
            </span>
          </div>
          <div className={'qnr-square'} id="qnr-square">
            {q.options && q.options.map((o,i) => {
              const actualId = q.link ? q.link : q.id
              const cardChck = currentQnr[actualId].value === o.id
              return <label key={i} className={'qnr-square-card'}>
                <img alt="" className={'rounded'} src={process.env.PUBLIC_URL + o.img[0]} />
                <div>
                  <div className="flex justify-between p-4 items-center color-blue-dark qnr-square-title">
                    <h3 className={'font-bold m-0 truncate'}>{o.label}</h3>
                    <h3 className="font-bold m-0 flex gap-3 items-center wideonly">
                      <span className={'truncate'}>Ver detalles</span>
                      <BsArrowRight size={25} />
                    </h3>
                  </div>

                  <div className="flex gap-3 justify-between items-center px-4 pb-4">
                    <p className={'m-0 text-secondary'}>{o.description}</p>
                    <div className="check-selected-mobile">
                      {cardChck
                        ? <span className="btncheck on"><BsCheck2 size="20px"/></span>
                        : <span className="btncheck off"><BsPlus size="20px"/></span>
                      }
                    </div>
                  </div>
                </div>
                {cardChck && <div className={'check-selected'}><Overlay /></div>}
                <input type="radio" name={q.id} value={i}
                   data-oid={o.id}
                   data-link={q.link}
                   onChange={handleOptionChange}
                   checked={cardChck}
                   className="debug hide"
                />
              </label>
            })}
          </div>
        </div>
      case 'address':
        return <div>
          <ZipCodeCities citiesData={qnrCities} refresh={refresh} setRefresh={setRefresh}  messages={messages}/>
          {/* {authActions.currentUser && <div>
          <hr style={{margin:'2em'}}/>
            <input placeholder='Nombre del proyecto' id="projectName" name="Nombre del proyecto" />
            <div className="q-navbtn border" onClick={handleSaveNewProject} >Guardar</div>
          </div>} */}
        </div>
      case 'imgconnect':
        // const styleImg = project.defaults.style ? q.options[project.defaults.style].img : q.options[0].img;
        console.log(projectActions.styles,project.defaults,project.defaults.style,project.defaults.substyle ?? 0)
        return <div className="q-fullImg" style={{
            background:'url(' + process.env.PUBLIC_URL + projectActions.styles[project.defaults.style ?? 0].substyles[project.defaults.substyle ?? 0].url + ')',
            backgroundSize:'cover',
            width: "100%"
          }}>
          </div>
      case 'imgbig':
        return <div className="q-fullImg" style={{
            background:'url(' + process.env.PUBLIC_URL + q.options[0].img + ')',
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover',
            width: "100%"
          }}>
          </div>
      case 'imgfull':
        return <div className='styleImg'
            style={{
              background:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
              backgroundSize:'cover',
              backgroundPosition:'center', 
              minWidth:'700px',
              backgroundRepeat:'no-repeat'                
            }}>
            <div style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-end',
                height:'100%',
                backgroundImage:`url(${process.env.PUBLIC_URL}${projectActions.styles[project.defaults.style ?? 0].substyles[project.defaults.substyle ?? 0].url})`,
                backgroundSize:'cover',
                backgroundPosition:'center',
                color:'white',
                borderRadius:'1em'
            }}>
          </div>
        </div>
      case 'carousel':
        return <Carousel id={q.id} q={q} action={handleOptionChange} />
      case 'slider':
        const actualId = q.link ? q.link : q.id
        const old = currentQnr[actualId].value
        return <Slider id={q.id} old={old} q={q} action={handleOptionChange} />
      case 'confirm':
        return <div className="mt-1 q-description">
          {q.options && q.options.map((o,i) => {
            switch (o.type){
              case 'checkbox':
                return <input key={i} type="checkbox" name="confirm" />
              case 'link':
                return <a key={i} href={o.actions[0].link}>{o.content}</a>
              default:
                return <span key={i}>{o.content}</span>
            }
          })}
        </div>
      case 'tabbedPanel':

        const elementData = q.options.length > 0 ? projectActions.elementsData.elements.find(item => item.id_element == q.options[activeTab].e) : null

        const id = getIdfromSelected(actualLayer,sceneActions.selected )

        return <div>
          <div style={{
            // position:'absolute'
          }}> 
            {q.options && q.options.map((o,i) => 
              <button key={i} className={i === activeTab ? 'q-navbtn on' : 'q-navbtn'} onClick={() => {setActiveTab(i)}}>{o.label}</button>
            )}
          </div>
          <div>
            {q.options.length > 0 && <div style={{paddingTop:'0.5em'}}>
              {q.options[activeTab].type === 'shapePlanner' && <ShapePlanner q={q} />}
              {q.options[activeTab].type === 'imageSlider' && <ImageSlider refresh={projectActions.refresh} actualLayer={actualLayer} o={q.options[activeTab]} elementData={elementData}/>}
              {q.options[activeTab].type === 'wallFloorModels' && q.options[activeTab].children && q.options[activeTab].children.map((child,j) => {
                const edata = projectActions.elementsData.elements.find(item => item.id_element == child.e)
                  return <Selector key={j} src={child} refresh={projectActions.refresh} actualLayer={actualLayer} elementData={edata} setActiveTab={setActiveTab}/>
                }
              )}
            </div>}
          </div>
        </div>
      case 'shapePlanner':
        return <ShapePlanner q={q} />
      case 'shapePlanner2':
        return <ShapePlanner2 />
      case 'performAction':
        return q.options.map((o,i) => {
          let inp = null;
          switch (o.input){
            case 'link':
              inp = <div className={o.access && o.access === 'debug' ? "q-link debug" : "q-link"} data-option={i} onClick={performActions}>{o.label} <FiArrowRight /></div>
            break;
            case 'button':
              inp = <button className={o.access && o.access === 'debug' ? "q-button debug" : "q-button"} data-option={i} onClick={performActions}>{o.label} <FiArrowRight /></button>
            break;
            default:
          }          
          return <div key={i}>
            {inp}
          </div>})
      case 'thumbs':
          return <div className="thumbsCanvas">
            {q.options && q.options.map((o,i) => {
              return <canvas key={i} width="150" height="100" className="thumb"></canvas>
            })}
          </div>
      case 'results':
        return projectActions.calcData && (
            <div style={{padding:'1em 0 4em'}} className={'bg-white w-full h-full'}>
              <div className={'qnr-budget w-full overflow-auto'}>
                <Budget isBasicBudget={true} blur={true} isHeader={false}/>
              </div>
            </div>
        )
      case 'table':
        return null
      case 'resultlist':
        return projectActions.calcData && <Results />
      case 'actionbtn':
        const foundSum = q.options.find(o => o.icon === 'sum')
        return <div className='q-actionbtns' style={{
          alignItems:'center',
          position:q.position && q.position === 'bottom' ? 'fixed' : 'static',
          background:q.position && q.position === 'bottom' ? 'white' : 'none',
          bottom:q.position && q.position === 'bottom' ? '1em' : '',
          justifyContent:foundSum ? 'space-between' : 'flex-end'
        }}>
          {q.options && q.options.map((o,i) => <div key={i} className={o.icon === 'sum' ? "m-sum-display" : "q-navbtn grey"} data-option={i} onClick={handleActions}>
            {o.label}&nbsp;&nbsp;{o.icon && o.icon === 'arrow' && <FiArrowRight />}
            {o.icon === 'sum' && <Sum />}
          </div>)}
        </div>
      case 'summary':
        return <Summary q={q} messages={messages} />
      case 'register':
        return <Register q={q} messages={messages} />
      case 'saveProject':
        return <div className='save-panel'>
            <input placeholder='Nombre del proyecto' id="projectName" name="Nombre del proyecto" />
            <div className="debug">{authActions.selectedUser}</div>
            <div className="q-navbtn border primary" onClick={handleSaveNewProject}>Guardar</div>
        </div>
      case 'save':
        const debugCss = {
              width:'100%',
              margin:'1em 0 0 0',          
        }
        return <div className="debug" style={{
          border:'3px solid red',
          borderRadius:'5px',
          padding:'1em',
          marginTop:'2em'
        }}>
          <Button onClick={() => setSaveOpen(!saveOpen)}><FiChevronDown/>UPDATE EXISTING PROJECT<FiChevronDown/></Button>
          {saveOpen && <div>
          <input name="projectID" placeholder="project ID here..." onChange={(e) => setProjectID(e.target.value)}/>
          <input name="projectName" placeholder="project Name here... (optional)" onChange={(e) => setProjectName(e.target.value)}/>
            <Button onClick={handleSaveProject} style={debugCss}>UPDATE PROJECT</Button>
            <Button onClick={handleCreateProject} style={debugCss}>CREATE NEW PROJECT</Button>        
            <Button onClick={handleAddResult} style={debugCss}>ADD RESULT</Button>
          </div>}    
        </div>
      default:
        return <div>Q type: {q.type}</div>
    }
  } 

  const hidClass = (q.blur && q.blur === 0) ? 'q-quest q-blur' : 'q-quest'; 

  return (
    <div className={`q-question ${q.wideonly ? 'wideonly' : ''} ${q.mobileonly ? 'mobileonly' : ''}`}>
      <span className="debug" style={{position:'absolute',right:'10px'}}>{q.id}</span>
      {q.title && <p className="q-title" dangerouslySetInnerHTML={{__html: (q.title2 && edit) ? q.title2 : q.title}} />}
      {q.description && <p className="q-description" dangerouslySetInnerHTML={{__html: q.description}} />}
      <div className={hidClass} style={{
        position:'relative'
      }}>{question(refresh)}</div>
      {q.message && <div className="q-message">
        {(q.message && q.message.icon) && <span className="q-icon">{q.message.icon}</span>}
        <p dangerouslySetInnerHTML={{__html: q.message.text}} />
      </div>}
      {q.q_tooltip && <div style={{position:'absolute',top:0,right:0}}><Tooltip title={
        <><QuestionCircleOutlined /> 
          {q.q_tooltip}
        </>} color={"blue"} >
        <QuestionCircleOutlined />
      </Tooltip>
      </div>}   
    </div>
  )
}

export default Question
