/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: amplitude-js@^8.21.0
 * Tracking Plan Version: 19
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/homyplan/Homyplan-Develop/implementation/web)
 */

import amplitude from 'amplitude-js';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * Data to be processed by middleware
 * @typedef MiddlewarePayload
 * @type {object}
 * @property {BaseEvent} event
 * @property {MiddlewareExtra} [extra]
 */

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 * @typedef MiddlewareNext
 * @type {function}
 * @param {MiddlewarePayload} payload
 *
 * @return
 */

/**
 * A function to run on the Event stream (each logEvent call)
 * @typedef Middleware
 * @type {function}
 * @param {MiddlewarePayload} payload The event and extra data being sent
 * @param {MiddlewareNext} next Function to run the next middleware in the chain, not calling next will end the middleware chain
 * @return
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [options]
 * @property {AmplitudeClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'development'|'production'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef {Object} IdentifyOptions
 * @type {object}
 */

/**
 * @typedef {Object} GroupOptions
 * @type {object}
 */

/**
 * @typedef {Object} MiddlewareExtra
 * @type {Object.<string, *>}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} development
 * @property {string} production
 */
export const ApiKey = {
  development: '',
  production: '0da1517ac334043c106b3756d032d596'
};

export const SpecialEventType = {
  Identify: "Identify",
  Group: "Group"
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions = {
  plan: {
    version: '19',
    branch: 'main',
    source: 'web',
    versionId: '90346a71-d97a-40fd-bebc-efd89bc2dc87'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '1.0.0'
    }
  }
};

export class Identify {
  constructor() {
    this.event_type = 'Identify';
  }
}

export class Group {
  constructor() {
    this.event_type = 'Group';
  }
}

export class ContinueClicked {
  constructor(properties) {
    this.event_type = 'continue_clicked';
    this.event_properties = properties;
  }
}

export class GoBackClicked {
  constructor(properties) {
    this.event_type = 'go_back_clicked';
    this.event_properties = properties;
  }
}

export class KitchenOnbCountertopViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_countertop_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbLayoutViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_layout_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbLocationViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_location_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbNewElectricEquipmentViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_new_electric_equipment_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbNewEquipment {
  constructor(properties) {
    this.event_type = 'kitchen_onb_new_equipment';
    this.event_properties = properties;
  }
}

export class KitchenOnbQuoteViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_quote_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbRegistrationViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_registration_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbShapeViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_shape_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbSideViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_side_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbStyleViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_style_viewed';
    this.event_properties = properties;
  }
}

export class KitchenOnbWelcomeViewed {
  constructor(properties) {
    this.event_type = 'kitchen_onb_welcome_viewed';
    this.event_properties = properties;
  }
}

export class OnbCallFormSent {
  constructor(properties) {
    this.event_type = 'onb_call_form_sent';
    this.event_properties = properties;
  }
}

export class OnbLocationViewed {
  constructor(properties) {
    this.event_type = 'onb_location_viewed';
    this.event_properties = properties;
  }
}

export class OnbMeasurementsViewed {
  constructor(properties) {
    this.event_type = 'onb_measurements_viewed';
    this.event_properties = properties;
  }
}

export class OnbNewEquipment {
  constructor(properties) {
    this.event_type = 'onb_new_equipment';
    this.event_properties = properties;
  }
}

export class OnbNewEquipmentViewed {
  constructor(properties) {
    this.event_type = 'onb_new_equipment_viewed';
    this.event_properties = properties;
  }
}

export class OnbQualityViewed {
  constructor(properties) {
    this.event_type = 'onb_quality_viewed';
    this.event_properties = properties;
  }
}

export class OnbQuoteSectionOpened {
  constructor(properties) {
    this.event_type = 'onb_quote_section_opened';
    this.event_properties = properties;
  }
}

export class OnbQuoteViewed {
  constructor(properties) {
    this.event_type = 'onb_quote_viewed';
    this.event_properties = properties;
  }
}

export class OnbRegistrationViewed {
  constructor(properties) {
    this.event_type = 'onb_registration_viewed';
    this.event_properties = properties;
  }
}

export class OnbSetUpCallClicked {
  constructor(properties) {
    this.event_type = 'onb_set_up_call_clicked';
    this.event_properties = properties;
  }
}

export class OnbShapeViewed {
  constructor(properties) {
    this.event_type = 'onb_shape_viewed';
    this.event_properties = properties;
  }
}

export class OnbStyleViewed {
  constructor(properties) {
    this.event_type = 'onb_style_viewed';
    this.event_properties = properties;
  }
}

export class OnbWelcomeViewed {
  constructor(properties) {
    this.event_type = 'onb_welcome_viewed';
    this.event_properties = properties;
  }
}

export class RegistrationCompleted {
  constructor(properties) {
    this.event_type = 'registration_completed';
    this.event_properties = properties;
  }
}

export class WebCtaClicked {
  constructor(properties) {
    this.event_type = 'web_cta_clicked';
    this.event_properties = properties;
  }
}

export class WhatsappClicked {
  constructor(properties) {
    this.event_type = 'whatsapp_clicked';
    this.event_properties = properties;
  }
}

export class WhatsappEstimateClicked {
  constructor(properties) {
    this.event_type = 'whatsapp_estimate_clicked';
    this.event_properties = properties;
  }
}

export class WhatsappEstimateSent {
  constructor(properties) {
    this.event_type = 'whatsapp_estimate_sent';
    this.event_properties = properties;
  }
}

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {AmplitudeClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
    /* @type {Middleware[]} */
    this.middlewares = [];
  }

  /**
   * @return {AmplitudeClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param {LoadOptions} [options] Configuration options to initialize the Ampli SDK with.
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    const env = options?.environment ?? 'development';
    const apiKey = options?.client?.apiKey ?? ApiKey[env];

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultOptions, ...(options?.client?.options ?? options?.client?.config) });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user.
   *
   * @param {string|undefined} userId The user's id.
   * @param {IdentifyOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  identify(userId, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Identify,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      this._identify(payload.event, options);
    });
  }

  _identify(event, options) {
    const ampIdentify = new amplitude.Identify();
    if (event.event_properties != null) {
      for (const [key, value] of Object.entries(event.event_properties)) {
        ampIdentify.set(key, value);
      }
    }
    this.amplitude.identify(
      ampIdentify,
      options?.callback,
      options?.errorCallback
    );
  }

  /**
   * Set Group for the current user
   *
   * @param {String} name
   * @param {String|String[]} value
   * @param {GroupOptions} [options]
   * @param {MiddlewareExtra} [extra]
   */
  setGroup(name, value, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.amplitude?.setGroup(name, value);
  }

  /**
   * Identify a group.
   *
   * @param {string} groupType The group type.
   * @param {string|string[]} groupName The group name.
   * @param {GroupOptions} [options] Options for this groupIdentify call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  groupIdentify(groupType, groupName, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Group,
      user_id: options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      const e = payload.event;
      if (e.user_id) {
        this.amplitude.setUserId(e.user_id);
      }
      if (e.device_id) {
        this.amplitude.setDeviceId(e.device_id);
      }
      const amplitudeIdentify = new amplitude.Identify();
      if (e.event_properties != null) {
        for (const [key, value] of Object.entries(e.event_properties)) {
          amplitudeIdentify.set(key, value);
        }
      }
      this.amplitude.groupIdentify(groupType, groupName, amplitudeIdentify, options?.callback);
    });
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  track(event, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const trackEvent = { ...event, ...options };
    this.runMiddleware({ event: trackEvent, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      const userProperties = payload.event.user_properties;
      if (userProperties) {
        const identifyEvent = {
          event_type: SpecialEventType.Identify,
          event_properties: userProperties,
          user_id: payload.event.user_id,
          device_id: payload.event.device_id
        };
        this._identify(identifyEvent, options);
      }

      this.amplitude.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        options?.errorCallback,
      );
    });
  }

  /**
   * continue_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/continue_clicked)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {Object} [properties.description] Property has no description in tracking plan.
   * @param {*} [properties.from] Property has no description in tracking plan.
   * @param {*} [properties.plan] Property has no description in tracking plan.
   * @param {*} [properties.quality] Property has no description in tracking plan.
   * @param {*} [properties.style] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  continueClicked(properties, options, extra) {
    this.track(new ContinueClicked(properties), options, extra);
  }

  /**
   * go_back_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/go_back_clicked)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {string} properties.from Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  goBackClicked(properties, options, extra) {
    this.track(new GoBackClicked(properties), options, extra);
  }

  /**
   * kitchen_onb_countertop_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_countertop_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbCountertopViewed(properties, options, extra) {
    this.track(new KitchenOnbCountertopViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_layout_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_layout_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbLayoutViewed(properties, options, extra) {
    this.track(new KitchenOnbLayoutViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_location_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_location_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbLocationViewed(properties, options, extra) {
    this.track(new KitchenOnbLocationViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_new_electric_equipment_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_new_electric_equipment_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbNewElectricEquipmentViewed(properties, options, extra) {
    this.track(new KitchenOnbNewElectricEquipmentViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_new_equipment
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_new_equipment)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} [properties] The event's properties.
   * @param {*} [properties.action] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbNewEquipment(properties, options, extra) {
    this.track(new KitchenOnbNewEquipment(properties), options, extra);
  }

  /**
   * kitchen_onb_quote_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_quote_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbQuoteViewed(properties, options, extra) {
    this.track(new KitchenOnbQuoteViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_registration_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_registration_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.action] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbRegistrationViewed(properties, options, extra) {
    this.track(new KitchenOnbRegistrationViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_shape_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_shape_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbShapeViewed(properties, options, extra) {
    this.track(new KitchenOnbShapeViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_side_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_side_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbSideViewed(properties, options, extra) {
    this.track(new KitchenOnbSideViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_style_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_style_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbStyleViewed(properties, options, extra) {
    this.track(new KitchenOnbStyleViewed(properties), options, extra);
  }

  /**
   * kitchen_onb_welcome_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/kitchen_onb_welcome_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} [properties] The event's properties.
   * @param {*} [properties.action] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  kitchenOnbWelcomeViewed(properties, options, extra) {
    this.track(new KitchenOnbWelcomeViewed(properties), options, extra);
  }

  /**
   * onb_call_form_sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_call_form_sent)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbCallFormSent(properties, options, extra) {
    this.track(new OnbCallFormSent(properties), options, extra);
  }

  /**
   * onb_location_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_location_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbLocationViewed(properties, options, extra) {
    this.track(new OnbLocationViewed(properties), options, extra);
  }

  /**
   * onb_measurements_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_measurements_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbMeasurementsViewed(properties, options, extra) {
    this.track(new OnbMeasurementsViewed(properties), options, extra);
  }

  /**
   * onb_new_equipment
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_new_equipment)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} [properties] The event's properties.
   * @param {*} [properties.action] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbNewEquipment(properties, options, extra) {
    this.track(new OnbNewEquipment(properties), options, extra);
  }

  /**
   * onb_new_equipment_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_new_equipment_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbNewEquipmentViewed(properties, options, extra) {
    this.track(new OnbNewEquipmentViewed(properties), options, extra);
  }

  /**
   * onb_quality_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_quality_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbQualityViewed(properties, options, extra) {
    this.track(new OnbQualityViewed(properties), options, extra);
  }

  /**
   * onb_quote_section_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_quote_section_opened)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {string} properties.section_name Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbQuoteSectionOpened(properties, options, extra) {
    this.track(new OnbQuoteSectionOpened(properties), options, extra);
  }

  /**
   * onb_quote_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_quote_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbQuoteViewed(properties, options, extra) {
    this.track(new OnbQuoteViewed(properties), options, extra);
  }

  /**
   * onb_registration_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_registration_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.action] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbRegistrationViewed(properties, options, extra) {
    this.track(new OnbRegistrationViewed(properties), options, extra);
  }

  /**
   * onb_set_up_call_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_set_up_call_clicked)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbSetUpCallClicked(properties, options, extra) {
    this.track(new OnbSetUpCallClicked(properties), options, extra);
  }

  /**
   * onb_shape_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_shape_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbShapeViewed(properties, options, extra) {
    this.track(new OnbShapeViewed(properties), options, extra);
  }

  /**
   * onb_style_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_style_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbStyleViewed(properties, options, extra) {
    this.track(new OnbStyleViewed(properties), options, extra);
  }

  /**
   * onb_welcome_viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/onb_welcome_viewed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} [properties] The event's properties.
   * @param {*} [properties.action] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  onbWelcomeViewed(properties, options, extra) {
    this.track(new OnbWelcomeViewed(properties), options, extra);
  }

  /**
   * registration_completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/registration_completed)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  registrationCompleted(properties, options, extra) {
    this.track(new RegistrationCompleted(properties), options, extra);
  }

  /**
   * web_cta_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/web_cta_clicked)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} [properties] The event's properties.
   * @param {*} [properties.origin_type] Property has no description in tracking plan.
   * @param {*} [properties.url] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  webCtaClicked(properties, options, extra) {
    this.track(new WebCtaClicked(properties), options, extra);
  }

  /**
   * whatsapp_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/whatsapp_clicked)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {string} properties.url Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  whatsappClicked(properties, options, extra) {
    this.track(new WhatsappClicked(properties), options, extra);
  }

  /**
   * whatsapp_estimate_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/whatsapp_estimate_clicked)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  whatsappEstimateClicked(properties, options, extra) {
    this.track(new WhatsappEstimateClicked(properties), options, extra);
  }

  /**
   * whatsapp_estimate_sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/homyplan/Homyplan-Develop/events/main/latest/whatsapp_estimate_sent)
   *
   * Owner: Nicolas Montilva
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.action Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  whatsappEstimateSent(properties, options, extra) {
    this.track(new WhatsappEstimateSent(properties), options, extra);
  }

  /**
   * Add new middleware to end of chain
   *
   * @param {Middleware} middleware
   */
  addEventMiddleware(middleware) {
    this.middlewares.push(middleware);
  }

  /**
   * Runs all middleware
   *
   * @param {MiddlewarePayload} payload
   * @param {MiddlewareNext} next The method to run after all middleware.
   *
   * @protected
   */
  runMiddleware(payload, next) {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();
