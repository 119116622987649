import {init, track} from "@amplitude/analytics-browser"; 
// import { auth } from "../../firebase/config";
import { EnumEventsAmplitude } from "./EnumEventsAmplitude";

import { ampli } from "../../ampli"

/** Init amplitude client. */
ampli.load({ environment: 'production' });
// init("0da1517ac334043c106b3756d032d596");

const plan = {
  version: '8',
  branch: 'main',
  source: 'web',
  versionId: '00e61526-219c-47b2-820f-5a287e87ce39'
}

/**
 * Get coords by user.
 *
 * @returns {lat: number, lng: number} Coords of location user.
 */
// const getIpLocation = () => new Promise((resolve, reject) => {
//   navigator.geolocation.getCurrentPosition(
//     ({coords}) => {
//       resolve({
//         lat: coords.latitude,
//         lng: coords.longitude,
//       })
//     }, (err) => reject(err));
// })

/**
 * Generate amplitude event.
 *
 * @param event An event specific from object `EnumEventsAmplitude`
 * @param description Description of the event.
 * @param action The type of action that invokes the event
 */
export const amplitudeEvent = async (event, description = '', action = 'click') => {
  try {
    // Catch error if event don't exist in object `EnumEventsAmplitude`
    if (!EnumEventsAmplitude[event]) {
      throw new Error(`invalid key event: ${event}`)
    }

   await ampli.track({
      event_type: event,
      event_properties: {
        action,
        ...description,
      }
    })

  } catch (e) {
    console.error('AMPLITUDE ERROR: ', e);
  }
}

