import { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import Budget from './Budget' 
const Budgets = ({projects, isSingle, styles}) => {

    useEffect(() => {
        console.log('IN BUDGETS',projects,styles)
    },[])

  return (
    <div>
        <Tabs defaultActiveKey="0" centered size="large">
            {projects.map((prj,i) => {

                const activeResult = prj.results.find(res => res.active === true)
                console.log('IN BUDGETS',prj)

            return <Tabs.TabPane style={{flex:1}} tab={prj.name} key={i}>
                {activeResult ? <Budget result={activeResult} scope={prj.scope} defaults={prj.defaults} isSingle={isSingle} isHeader={true} styles={styles}/> : 'No active results'}
            </Tabs.TabPane>})}
        </Tabs>
    </div>
  )
}

export default Budgets