import React from 'react'
import Check from './Check'

const Overlay = () => {
  return (
    <div className='q-overlay'>
      <Check />
    </div>
  )
}

export default Overlay