import React, { useContext, useRef, useState, useEffect } from "react";
import emailjs from '@emailjs/browser';
import ModalContext from "../../context/ModalContext";
import QnrContext from "../../context/QnrContext";

import { Form, Input, DatePicker, Checkbox, Button, message } from "antd";
import { auth, firestore as db } from "../../firebase/config";
import { amplitudeEvent, EnumEventsAmplitude, EnumActionsAmplitude } from "../../utils/amplitude";
import { useParams } from "react-router-dom";

const key = "updatable";

const Summary = ({ q, messages, buttons }) => {

  const iCardCss = {
    padding:'1em 2em 1em 3em',
    margin:'1em auto',
    background:'#F4F4F1',
    minHeight:'15em',
    width:'330px',
    cursor:'default'
  }

  const [form] = Form.useForm();
  const params = useParams()
  const { toggleModal, setModalData } = useContext(ModalContext);

  const requestMeeting = (type) => {
    console.log("meeting called");
    if (!auth.currentUser) {
      return message.error({
        content: messages.pleaseRegisterFirst,
        key,
        duration: 2,
      });
    }
    message.loading({
      content: messages.loading,
      key,
    });

    let data = {
      userId: auth.currentUser.uid,
      phone: document.getElementById("basic_phone").value,
      // date: new Date(document.getElementById("date-meeting").value).toLocaleDateString()
    }

    console.log("meeting called",data);

    if(!data.phone){
      return message.error({
        content: messages.pleaseFillMissingInformation,
        key,
        duration: 2,
      });
    }
    if(type === "whatsapp"){
      data.type = type;
    }
    else {
      data.email = document.getElementById("basic_email").value
      data.name = document.getElementById("basic_name").value
      data.allowWhatsapp = document.getElementById("allow-whatsapp-contact").checked
      data.type = "normal";
    }
    if(params.projectID){
      data.projectID = params.projectID
    }
    else if(localStorage.getItem("projectID")){
      data.projectID = localStorage.getItem("projectID")
    }
    else{
      return message.error({
        content: messages.noProjectToSchduleMeeting ? messages.noProjectToSchduleMeeting : messages.unknownError,
        key,
      });
    }
    console.log("Final Email Data", data)
    emailjs.send('service_a2nzu8s', 'template_prazb78', 
    // document.getElementById('basic'),
    data,
    'y0jf5duVfiqbtox2L')
      .then((result) => {
          console.log("emailjs success", result.text);
      }, (error) => {
          console.log("emailjs error",error.text);
      });
    db.collection("Meetings")
      .doc(data.projectID).set(data, {merge: true})
      .then((res) => {
        toggleModal();
        if(type === "normal") {
          amplitudeEvent(EnumEventsAmplitude.onb_call_form_sent, '', EnumActionsAmplitude.InteractionEvent)
        } else if(type === "whatsapp") {
          amplitudeEvent(EnumEventsAmplitude.whatsapp_estimate_sent, '', EnumActionsAmplitude.InteractionEvent)
        }
        message.success({
          content: messages.requestSentSuccessfully  ,
          key,
          duration: 2,
        });


        localStorage.removeItem("projectID")
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: messages[err.code] ? messages[err.code] : messages.unknownError,
          key,
        });
      });
  };

  const amplitudeWhatsAppEvents = () => {
    amplitudeEvent(
      EnumEventsAmplitude.whatsapp_clicked, 
      { url: window.location.href }, 
      EnumActionsAmplitude.InteractionEvent
    );
    amplitudeEvent(EnumEventsAmplitude.whatsapp_estimate_clicked, '', EnumActionsAmplitude.InteractionEvent)
  }
  
  return (
    <div>
      <div
        style={{
          display: "flex",
          margin: "1em 0",
          gap: "1em",
        }}
      >
        {/* <div
          style={{
            flexShrink: 1,
            width: "140px",
            height: "130px",
            borderRadius: "4px",
            background:
              "url(" + process.env.PUBLIC_URL + "/renders/BAÑO_Clasico.jpg)",
            backgroundSize: "cover",
          }}
        ></div> */}
        <div
          style={{
            borderTop: "1px solid #777786",
            borderBottom: "1px solid #777786",
          }}
        >
          {q.description && (
            <div
              className="q-description"
              style={{
                margin: "1em 0",
              }}
            >
              {/* {q.description} */}
            </div>
          )}
          {/* <ul className="q-List">
            {q.options &&
              q.options.map((o, i) => {
                return <li key={i}>{o.label}</li>;
              })}
          </ul> */}
        </div>
      </div>
      <Button
        type="primary"
        className="q-navbtn truncate"
        block
        title={'Quiero hablar con mi asesor'}
        onClick={() => {
          amplitudeEvent(EnumEventsAmplitude.onb_set_up_call_clicked, '', EnumActionsAmplitude.InteractionEvent)
          setModalData({
            heading: "Solicitar una llamada",
            subHeading: "con nuestro asesor",
            note: "Nos pondremos en contacto contigo para agendar una llamada lo antes posible, en la que a través de tu panel de usuario verificaremos los datos y definiremos todos los detalles de tu reforma.",
            body: (
              <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  id="email-meeting"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce el email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Nombre"
                  name="name"
                  id="name-meeting"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce tu nombre!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Teléfono"
                  name="phone"
                  id="phone-meeting"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce el teléfono!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                {/* <DatePicker id="date-meeting" /> */}
                
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox id="allow-whatsapp-contact" >
                    <b>¿Permitir contractar por whatsapp?</b> Para facilitar las
                    comprobaciones sobre tu presupuesto
                  </Checkbox>
                </Form.Item>
              </Form>
            ),
            buttons: [
              { text: "En otro momento", type: "text", onClick: "close" },
              {
                text: "Agendar llamada",
                type: "primary",
                onClick: () => requestMeeting(),
              }
            ],
          });
        }}
      >
        Quiero hablar con mi asesor &nbsp; &#x279C;{" "}
      </Button>
      <br />
      {buttons === 'both' && <Button
        className="q-navbtn truncate"
        block
        title={'Recibe la selección de equipamientos y acabados por whatsapp'}
        style={{background: "green", border: "0px solid white", color: "white", marginTop: 5}}
        onClick={() => {
          amplitudeWhatsAppEvents()
          setModalData({
            heading: "Te enviamos todos los detalles de tu presupuesto",
            subHeading: "con nuestro asesor",
            note: "Déjanos tu teléfono y te enviaremos un link con este presupuesto y el detalle del equipamiento y acabados seleccionados , para que accedas cuándo quieras y lo compartas con quién quieras.¡No te preocupes! Nunca te llamaremos sin que nos lo pidas.",
            body: (
              <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Teléfono"
                  name="phone"
                  id="phone-meeting"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor, introduce tu teléfono!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                {/* <DatePicker id="date-meeting" /> */}
                <Form.Item name="remember" valuePropName="checked">
                </Form.Item>
              </Form>
            ),
            buttons: [
              { text: "En otro momento", type: "text", onClick: "close" },
              {
                text: "Enviar",
                type: "primary",
                onClick: () => requestMeeting("whatsapp"),
              },
            ],
          });
        }}
      >
        Recibe la selección de equipamientos y acabados &nbsp; &#x279C;{" "}
      </Button>}
    </div>
  );
};

export default Summary;
