import React from 'react';
import ItemDescriptionProject from "./ItemDescriptionProject";

/**
 * Modal to display detail final design project.
 *
 * @param prop Props.
 * @param prop.project Details of the project.
 * @param prop.imgSrc Image to display.
 * @return React element ModalFinalDesign component.
 */
const ModalFinalDesign = ({project, imgSrc, scope}) => {

    console.log(project)

    return (
        <div className='flex items-center gap-3 finalDesignModal w-full'>
            <div className='card-description-design-modal rounded bg-white h-full p-4'>
                <h1>Diseño de tu {scope}</h1>
                <p className='text-secondary text-sm'>
                    La imagen del diseño final es genérica por lo que la composición
                    no se corresponde con la realidad de tu reforma.
                </p>

                <hr className='hr-blue mb-2'/>

                <ItemDescriptionProject
                    name={'Estilo'}
                    value={project.style}
                />

                <ItemDescriptionProject
                    name={'Subestilo'}
                    value={project.substyle}
                />

                <ItemDescriptionProject
                    name={'Descripción'}
                    value={project.description}
                />
            </div>

            <img src={imgSrc} alt="" className="h-full image-final-design rounded"/>

        </div>
    );
}

export default ModalFinalDesign
