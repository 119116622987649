import {useState, useRef, useContext, useEffect} from 'react'
import { motion } from 'framer-motion'
import Carousel from './Carousel';

const Selector = ({src, refresh, actualLayer, elementData, setActiveTab}) => {

    const [values, setValues] = useState([])
    const [feature, setFeature] = useState(null)

    console.log('in Selector',src, actualLayer,elementData)

    useEffect(() => {
        const values = elementData.properties.find(prop => prop.name === src.property).values;
        const feature = elementData.properties.find(prop => prop.name === src.property).feature;
        setValues(values)
        setFeature(feature)
    },[])

  return (
    <div>
        <div className="m-columns">
            {src.label && src.title !== "bottom" && <div className='q-title' style={{width:'100%',textAlign:'center'}}>{src.label}</div>}
            {src.split && src.split === true && <div className='q-title' style={{width:'100%',textAlign:'center'}}>{src.label}</div>}
        </div>
        <div className="m-columns">
            <Carousel layer={actualLayer} id_element={src.e} feature={feature} action={setActiveTab} values={values} width={src.split === true ? 400 : 800}/>
            {src.split && src.split === true 
            && <><div style={{width:'0.5em',background:'#007DFF'}}></div>
                <Carousel layer={actualLayer} id_element={src.e} feature={feature} action={setActiveTab} values={values} width={src.split === true ? 400 : 800}/>
            </>}
        </div>
        {src.split && src.split === true 
                && <div style={{height:'0.5em',background:'#007DFF'}}></div>}
        {src.label && src.title === "bottom" && <div className='q-title' style={{width:'100%',textAlign:'center'}}>{src.label}</div>}
        {/* <div className='m-selector'>
            {elementData.properties.find(prop => prop.name === src.property).values.map((v,i) => {
                return <div key={i} className="carousel-thumb" style={{background:`url(${v.img[0]})`}}>{i}. {v.img[0]}</div>
            })}
        </div> */}
    </div>
  )
}

export default Selector