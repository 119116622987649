import React, { useRef, useEffect, useState, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import areapolygon from 'area-polygon';
import {
    midPoint,
    getOffset,
    verticesMidPoint,
    verticesDistance,
    roundRect,
    getPolygonCentroid
  } from '../../utils/geometry'

const Canvas2 = (props) => {

    const {sceneId, plannerType = 'dimensions', width, height} = props;

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const { scene, layer, setScene, vertices, setVertices, elements, setElements } = useContext(SceneContext)
  
    const canvasRef = useRef(null)

    const currentScene = projectActions.scenes[sceneId]
     
    useEffect(() => {
        
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        
        // console.log('CANVAS REF',vertices)

        //Our draw come here
        // setVertices(scene.scene.layers[0].layer_1.vertices)
        // setElements(scene.scene.layers[0].layer_1.lines)

        console.log('IN CANVAS',sceneId,projectActions.scenes)

        if(projectActions.scenes){
            draw(context)
        }
    }, [projectActions.scenes,vertices,elements])   

    const draw = ctx => {

        // console.log('Drawing',projectActions.scenes)

        const layer = projectActions.scenes[0].layers[0].layer_1

        const elements = layer.lines

        const vertices = projectActions.scenes[0].layers[0].layer_1.vertices

        console.log('in canvas',layer,vertices)

        const offset = {x:getOffset(ctx,vertices).x,y:getOffset(ctx,vertices).y}

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        if(elements && vertices){
            elements.forEach((e,i) => {
                const v1 = {x:vertices[e.v1].x * 100 + offset.x, y:vertices[e.v1].y * 100 + offset.y}
                const v2 = {x:vertices[e.v2].x * 100 + offset.x, y:vertices[e.v2].y * 100 + offset.y}

                const line = new Path2D();

                ctx.strokeStyle = "#00D4FF";
                ctx.lineWidth = 3;
                ctx.beginPath();
                line.moveTo( v1.x, v1.y)
                line.lineTo( v2.x, v2.y);
                ctx.closePath();      // go back to point 1
                ctx.stroke(line);

                if(e.label && plannerType === 'dimensions'){
                    const label = new Path2D()
                    ctx.fillStyle = "#00D4FF";
                    const size = 30;
                    const mp = midPoint(v1.x,v1.y,v2.x,v2.y);
                    roundRect(ctx, mp.x - size/2 , mp.y - size/2, size, size, {tl: 3, tr: 3, br: 3, bl: 3}, true, false);
                    ctx.font = "15px Arial";
                    ctx.fillStyle = "#FFFFFF";
                    ctx.textAlign = "center";
                    ctx.fillText(e.label, mp.x, mp.y+5);
                }
            })

            const polygon = vertices.map(v => [v.x,v.y])

            let areaSize = areapolygon(polygon, false);
            const center = getPolygonCentroid(vertices);
            ctx.fillStyle = "#00D4FF";
            ctx.fillText(Math.round(areaSize * 100) / 100 + ' m2', center.x * 100 + offset.x, center.y * 100 + offset.y);
        }
    }

    
    return <canvas className='rc-shapeCanvas' ref={canvasRef} {...props}/>
}

export default Canvas2