import React, { useState, useContext, useEffect } from 'react'
import { Button, Modal } from 'antd';
import QnrContext from '../../context/QnrContext';
import ProjectContext from '../../context/ProjectContext';
import SceneContext from '../../context/SceneContext';
import CalcContext from '../../context/CalcContext';
import { Content } from 'antd/lib/layout/layout';
import SaveResult from '../widgets/SaveResult'

const PopUp = ({children}) => {

  const qnrActions = useContext(QnrContext)
  const projectActions = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)
  const calcActions = useContext(CalcContext)

  const [visible, setVisible] = useState(false);
  const [popupData, setPopupData ] = useState(null)

  const hideModal = () => {
    setVisible(null);
  };

  const components = {
    SaveResult:SaveResult
  }

  useEffect(() => {
    if(projectActions.popup !== null){
      // console.log('changing popup',projectActions.popups[projectActions.popup])
      setPopupData(projectActions.popups[projectActions.popup])
    }
  },[projectActions.popup])

  const popupContent = () => {

    
    if(popupData !== null){
      if(popupData.components){
        return <>{popupData.components.map((component,i) => {
          const TempComponent = components[component]
          return <TempComponent key={i} />
        })}
        </>
      }
    }
  }

  const handleAction = (id) => {
    const actions = popupData.buttons[id].actions

    actions.forEach(action => {
      switch(action.a){
        case 'cancel':
          projectActions.setPopup(null)
        break;
        case 'projectActions':
          projectActions[action.v]()
        break;
        case "resetAll":
            sceneActions.copyScene();
            projectActions.setPopup(null);                         
        break;
        case "wipeAll":
            sceneActions.resetScenes();
            projectActions.setPopup(null);   
        break;
        default:
          console.log('BTN ' + popupData.buttons[id].label + ' clicked')
      }
    })
  }

  return (
    <Modal
      title={popupData !== null ? popupData.title : 'modal'}
      visible={projectActions.popup !== null}
      onOk={hideModal}
      onCancel={() => projectActions.setPopup(null)}
      footer={popupData && popupData.buttons ? popupData.buttons.map((btn,i) => {
        return <Button key={i} onClick={() => handleAction(i)}>{btn.label}</Button>
      }) : []}
    >
      {popupData !== null && <div>{popupData.description}</div>}
      {popupContent()}
    </Modal>
  )
}

export default PopUp