import { useContext, useEffect, useState } from 'react'
import ProjectContext from '../../context/ProjectContext'
import { getChapters } from '../../utils/budgetUtils'
import { currencyFormat } from '../../utils/formatUtils'

const Sum = ({chapterId = 8}) => {

    const projectActions = useContext(ProjectContext)

    const [chapters, setChapters] = useState(null)
    const [sum, setSum] = useState(null)



    useEffect(() => {
        if(projectActions.results[0]){
            getChapters(projectActions.results[0], projectActions.calcData).then((resultTemp) => setChapters(resultTemp))
            if(chapters && chapters.jobs && chapters.jobs.length > 0){
                const foundJob = chapters.jobs.find(ch => ch.id === chapterId);
                console.log('IN SUM',foundJob ? foundJob.amountTotal : null)
                if(foundJob){
                    setSum(currencyFormat((foundJob.amountTotal).toFixed(2)))
                } else {
                    setSum('-');
                }
            }
        }
    },[projectActions.refresh])

    return (
        <span style={{fontWeight:500}}>{sum}</span>
    )
}

export default Sum