import { useContext, useState, useEffect } from 'react'
import Chat from '../components/dashboard/Chat.js'
import Navbar from '../components/dashboard/Navbar'
import Sidebar from '../components/dashboard/Sidebar'
import UserPanel from '../components/dashboard/UserPanel'
import UserList from '../components/dashboard/UserList'
import { BsFillChatDotsFill } from 'react-icons/bs'


const Chatroom = () => {
  return (
    <div className="dashboard-layout">
    <Navbar />
    <div className='dashboard-content'>
        <Sidebar />
        <div className="chatroom">
          <h4>Active users</h4>
          <Chat />
          <h4>Recent users</h4>
          <Chat />
          <h4>Unanswered users</h4>
          <Chat />
        </div>
      </div>
      <UserPanel />    
    </div>
  )
}

export default Chatroom