import React, { Fragment, useContext, useEffect, useState } from 'react'
import ProjectContext from '../../context/ProjectContext'

const MiniBudget = () => {

    const projectActions = useContext(ProjectContext)

    const [ refresh, setRefresh ] = useState(false)

    useEffect(() => {
        // console.log('MINI RESULT',projectActions.results)
        setRefresh(!refresh)
    },[projectActions.results])

    const mBudgetCss = {
        display:'flex',
        alignItems:'center'
    }

    return (
        <div className='m-budget'>
            {/* {projectActions.results.length > 0
                ? projectActions.results.map((result,i) => {
                return <Fragment key={i}>
                    {i === 0 
                    ? <h1><span style={{fontSize:'.5em'}}>Presupuesto&nbsp;actual:&nbsp;</span>{result.overall.toFixed(2)}€</h1>
                    : <h3 style={{margin:'0 0.5em'}}>{result.overall.toFixed(2)}€</h3>
                    }
                 </Fragment>
            }) */}
            {projectActions.results.length > 0 ?
                <h1><span style={{fontSize:'.5em'}}>Presupuesto&nbsp;actual:&nbsp;</span>{projectActions.results[0].overall.toFixed(2)}€</h1>
                :
            <div>No results</div>}
        </div>
    )
}

export default MiniBudget