import React, { Fragment, useContext, useEffect, useState } from 'react'
import Question from './Question'
import QnrContext from '../../context/QnrContext'
import ProjectContext from '../../context/ProjectContext'
import { amplitudeEvent, EnumActionsAmplitude } from "../../utils/amplitude"
import { getElements, filteredSomeItems, getFirstElement, getAllValues } from "../../utils/sceneUtils"
import { elementNameToId, getElementValueLabel, getItemLabel, getFeatureValue, getFeatureLabel, getFeatureColumn, getPropertyLabel } from "../../utils/formatUtils"
import { Collapse } from 'antd'
import { AiOutlineEdit } from 'react-icons/ai'

const ExtLayout = ({ id, category }) => {
  
  const { Panel } = Collapse;

  const { activeQ, currentQnr, filteredCategories } = useContext(QnrContext)
  const qnrActions = useContext(QnrContext)
  const projectActions = useContext(ProjectContext)
  const [ activeCategory, setActiveCategory ] = useState(0)
  const [ refresh, setRefresh ] = useState(false)

  
  useEffect(() => {
    if (category.amplitudePageViewEvent) {
      if(category.amplitudePageViewEvent.action == EnumActionsAmplitude.PageView) {
        amplitudeEvent(category.amplitudePageViewEvent.name, "", category.amplitudePageViewEvent.action)
      }
    }
  }, [activeQ])

  const handleColapse = () => {

    console.log('COLLAPSE')

    if(category.modal && category.modal === true){
      projectActions.setModal(category.id)
    } else {
      if(qnrActions.activeC === category.id){
        qnrActions.setActiveC(null)
      } else {
        qnrActions.setActiveC(category.id)
      }
    }
  }

  return (<>
    {category.c_name && <p className="q-name" style={{margin:'0 1.5em',fontSize:'12px'}} dangerouslySetInnerHTML={{__html: category.c_name}} />}
    {category.c_title && <p className="c-title" style={{margin:'0 0.5em'}} dangerouslySetInnerHTML={{__html: category.c_title }}/>}
    <div id={"slide_" + id} className={(category.full && category.full === true) ? "c-full" : qnrActions.chapter !== 4 ? `c-layout-chapter c-layout-chapter-${id}` : "c-layout"}>
      <span className="debug" style={{position:'absolute'}}>[{category.id}]</span>
      {!category.full && <div className={qnrActions.chapter !== 4 ? (qnrActions.activeC === category.id ? '' : 'q-rounded-b') + ' q-content-chapter' : 'q-content'} style={{margin:0}}>
        <div className='c-bar' style={{position:'relative'}} onClick={handleColapse}>
          {category.c_subtitle && <p className="q-subtitle mb-2 color-blue-dark" style={{fontWeight:500}} dangerouslySetInnerHTML={{__html: category.c_subtitle}} />}
          {category.questions && category.questions.map((q,i) => {
            if(q.output && projectActions.scenes[q.table === 'integratedTable' ? 1 : 0]){
              let oldValue = null;
              let tempText = '';
              const actualLayer = projectActions.scenes[q.table === 'integratedTable' ? 1 : 0].layers[0].layer_1;
              switch(q.output.a){
                case 'defaultValue':
                  oldValue = projectActions.project.defaults[q.output.e]
                  let unit = ''
                  let coma = i > 0 ? ', ' : ''
                  switch(q.output.e){
                    case 'height':
                      unit = 'cm'
                      oldValue = Math.round(oldValue * 100);
                    break;
                    case 'houseShape':
                      const area = actualLayer ? actualLayer.areas[0].floor.measurements.area : null;
                      oldValue = "Forma " + (oldValue + 1) + ' - ' + 'Area: ' + area; 
                    break;
                    case 'plan':
                      const plans = q.output.v ? q.output.v : ['Plan 1', 'Plan 2', 'Plan 3']
                      oldValue = oldValue !== null ? plans[parseInt(oldValue)] : "Ninguna elección"
                    break;
                    case 'style':
                      const activeStyle = projectActions.project.defaults.style;
                      const activeSubStyle = projectActions.project.defaults.substyle;
                      oldValue = activeStyle && activeSubStyle ? `${projectActions.styles[activeStyle].label} - ${projectActions.styles[activeStyle].substyles[activeSubStyle].label}` : '!Ninguna elección!';
                    break;
                  }
                  oldValue = coma + oldValue + ' ' + unit;                  
                break;
                case 'itemlist':
                  let filtered = []
                  
                  switch(elementNameToId(q.output.e)){
                    case 4:
                    case 5:
                    case 6:
                      filtered = filteredSomeItems(actualLayer.holes,q.output.f);
                    break;
                    default:
                      filtered = filteredSomeItems(actualLayer.items,q.output.f);
                  }

                  oldValue = filtered.length > 0 ? filtered.map((item,j) => <div key={j} className="q-itemtab" style={{marginRight:'1em'}}>
                    {item.name}
                  </div>) : <div>Ninguna elección</div>
                break;
                case 'feature':
                  const id_element = elementNameToId(q.output.e)
                  let item = null;
                  {/* const actualLayer = projectActions.scenes[q.table === 'initialTable' ? 0 : 1].layers[0].layer_1 */}
                  switch (parseInt(id_element)){
                    case 2:
                      const value2 = actualLayer.areas[0] ? actualLayer.areas[0].ceiling.features[parseInt(q.output.f)] : null;
                      oldValue = value2 !== null ? getElementValueLabel(projectActions.elementsData,parseInt(id_element),parseInt(q.output.f),value2) : "-";
                    break;
                    case 3:
                      const value3 = actualLayer.areas[0] ? actualLayer.areas[0].ceiling.features[parseInt(q.output.f)] : null;
                      oldValue = value3 !== null ? getElementValueLabel(projectActions.elementsData,parseInt(id_element),parseInt(q.output.f),value3) : "-";
                    break;
                    default:
                      {/* const items = actualLayer.items ? actualLayer.items.filter(it => it.id_element === parseInt()).features[parseInt(q.output.f)] : null;
                      console.log(actualLayer.items) */}
                  }
                  if(item !== null){
                    oldValue = item.features[parseInt(q.output.f)]       
                  }
                break;
                case "features":
                  let tempFeatures = '';
                  q.output.e.forEach((el,i) => {
                    const tempFeatureItem = getElements(actualLayer,null,elementNameToId(el))
                    const oldFeatureValue = getAllValues(tempFeatureItem,q.output.f[i])
                    const featureLabel = getElementValueLabel(projectActions.elementsData,elementNameToId(el),3,oldFeatureValue)
                    if(i > 0){
                      tempFeatures += ', '
                    }
                    tempFeatures += q.output.l[i] ? q.output.l[i] + " : " + featureLabel !== null ? q.output.l[i] + " : " + featureLabel : oldFeatureValue : el + " : "
                  })
                  oldValue = tempFeatures
                break; 
                case 'wallsfloor':
                  oldValue = "Paredes: ---- Suelo: -----"
                break;
                case 'model':
                  const itemsArray = []
                  q.output.e.forEach((e,i) => {
                    const tempItem = getFirstElement(actualLayer,null,elementNameToId(e),[q.output.filters[i]])

                    if(i > 0){
                      tempText += ', '
                    }                    
                    if(tempItem !== null){
                      const column = getFeatureColumn(projectActions.elementsData,q.output.a,tempItem)
                      const oldValue = getFeatureValue(tempItem,column)
                      const valueLabel = getPropertyLabel(projectActions.elementsData,q.output.a,tempItem)
                      const itemLabel = getItemLabel(projectActions.elementsData,tempItem)

                      const text = (tempItem ? itemLabel : "-" +  " : ") + " : " + (valueLabel ? valueLabel.label : "not found");

                      itemsArray.push(text)              
                    } else {
                      itemsArray.push(null)
                    }
                  }) 
                  oldValue = itemsArray.map((it,i) => it && <div key={i} style={{marginRight:'0.5em'}} className='q-itemtab'>{it}</div>)
                break;
                case "wallGroups":
                  console.log('IN OUTPUT ',q.output, actualLayer.groups)
                  if(actualLayer.groups && actualLayer.groups.length > 0 && actualLayer.groups.some(g => g.items.length > 0)){
                    let coma = '';
                    oldValue = '';
                    actualLayer.groups.forEach((g,i) => {
                      if(i > 0){coma = ', '}
                      oldValue += coma + g.label + ' : ' + g.items.length + q.output.l;
                    })
                  } else {
                    oldValue = 'NO GROUPS FOUND'
                  }
                break;
                default:
                  oldValue = 'VALUE'
              }            


              return oldValue && <div className="c-output" key={i}>{oldValue}</div>} 
              else {
                return <h3 key={i}> </h3>
              }    
            }
          )}  
          <div style={{position:'absolute',right:0,top:0}}><AiOutlineEdit /></div>  
        </div>
      </div>}
      {(qnrActions.activeC === category.id || qnrActions.chapter === 0 || category.chapter === 4) && <div key={category.id} className={qnrActions.chapter !== 4 ? 'q-content-question-chapter' : ''}>
          {/* {category.c_title && <p className="q-title align-left" dangerouslySetInnerHTML={{__html: category.c_title}} />}  */}
          {category.c_description && <p className="q-title align-left" dangerouslySetInnerHTML={{__html: category.c_description}} />}           
          {category.c_message && <div className="q-message align-left">
            {(category.c_message && category.c_message.icon) && <span className="q-icon">{category.c_message.icon}</span>}
            <p dangerouslySetInnerHTML={{__html: category.c_message.text}} />
          </div>}
          {!category.modal && category.questions && category.questions.map((q,i) => <Question key={i} q={q} refreshCategory={() => setRefresh(!refresh)} />)}
      </div>}
    </div>
  </>)
}

export default ExtLayout
