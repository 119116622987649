import { useContext, useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import { firestore as db } from "../../firebase/config";
import PuffLoader from "react-spinners/PuffLoader";
import Search from './Search'
import {FaPlusSquare} from 'react-icons/fa'
import DataTable from './DataTable';
import TimeToggle from '../widgets/TimeToggle';
import { Button, Space, Input } from 'antd';
import ProjectContext from '../../context/ProjectContext';
import AdminContext from '../../context/AdminContext';
import ModalContext from '../../context/ModalContext';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment'

// date, username, email, zip code, price, links (results, dashboard)

const ProjectList = () => {

  const navigate = useNavigate()
  const projectActions = useContext(ProjectContext)
  const modalActions = useContext(ModalContext)
  const adminActions = useContext(AdminContext);
  const [projects, setProjects] = useState(null);
  // const [nextStart, setNextStart] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(-1);
  const [perPage, setPerPage] = useState(10)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text
  });
  const projectColumns = [
    {
      title: 'Project Id',
      dataIndex: 'projectId',
      key: 'projectId',
      ...getColumnSearchProps('projectId'),
    },
    {
      title: 'User name',
      dataIndex: 'userName',
      key: 'userName',
      ...getColumnSearchProps('userName'),
    },
    {
      title: 'User email',
      dataIndex: 'userEmail',
      key: 'userEmail',
      ...getColumnSearchProps('userEmail'),
    },

    {
      title: 'Name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Zip Code',
      dataIndex: 'zipCode',
      ...getColumnSearchProps('zipCode'),
    },
    {
      title: 'Price',
      dataIndex: 'overall',
      ...getColumnSearchProps('overall'),
      render: (project) => {
        return (
          <>{project.overall ? parseInt(project.overall).toFixed(2) : '-' }</>
      )},
    },
    
    {
      title: 'Date',
      key: 'created_At',
      ...getColumnSearchProps('created_At'),

      render: (project) => {
        return (
          // <>{new Date(project.created_At).toLocaleDateString()}</>
          moment(project.created_at).format('DD/MM/YYYY')
      )},
    },
    {
      title: 'Type',
      dataIndex: 'scope',
      filters: [
        {
          text: 'Bano Proyecto',
          value: 'BanoProyecto',
        },
        {
          text: 'Cocina Proyecto',
          value: 'CocinaProyecto',
        },
      ],
      onFilter: (value, record) => record.scope.indexOf(value) === 0,
    },
    {
      title: 'Action',
      key: 'action',
      render: (project) => {
        return (
        <Space size="middle">
          <a onClick={() => handleDelProject(project.projectId)}>Delete</a>
          <Link to={`/results/${project.projectId}`} target="_blank">Results</Link>
          <Link to={`/project/${project.projectId}`} target="_blank">Edit</Link>
        </Space>
      )},
    },
  ];
  useEffect(() => {
    getProjectsData();
  }, []);

  const handleNewProject = () => {
    modalActions.setModalData({
      heading: "Nuevo miniproyecto",
      subHeading: "Nuevo miniproyecto",
      note: "Select scope of new project.",
      body: (
          <div className='projects-container'>
              <div className="project-thumb" onClick={() => navigate('/bano',{target:'_blank'})}>Bano Proyecto</div>
              <div className="project-thumb" onClick={() => navigate('/cocina',{target:'_blank'})}>Cocina Proyecto</div>
          </div>
      )})
  }

  const getProjectsData = async () => {
    // setLoading(true);
    // let {response, next} = await projectActions.getPaginatedProjects('', null, perPage, 'created_At', 'desc')
    // console.log("projectActions.getPaginatedProjects", response, next.id)
    // console.log("next data", (0 * perPage) , (0 * perPage) + perPage, projects?.length)
    
    // setNextStart(next)
    // setPage(0)
    // setProjects(response)
    // setLoading(false)

    setLoading(true);
    let response = await adminActions.getAllProjects()
    let users = await adminActions.getAllUsers()
    // console.log("next data", (0 * perPage) , (0 * perPage) + perPage, projects?.length)

    let finalData = response.map(project => ({...project, ...users.find(user => project.userId === user.key)}))
    setPage(0)
    setProjects(finalData)
    setLoading(false)
  };

  const handleDelProject = (projectID) => {
    const delModal = <div>Are You sure You want to delete project? This aoperation cannot be undone</div>

    console.log('MODAL')
    modalActions.setModalData({
      heading: "Are You sure to delete project",
      subHeading: "del",
      note: "Delete. This operatin cannot be undone",
      body: (
        delModal
      ),
      buttons: [
        { text: "En otro momento", type: "text", onClick: "close" },
        {
          text: "Delete object",
          type: "primary",
          onClick: () => projectActions.deleteProject(projectID)
        }
      ]
    });
    modalActions.setOpen(true);
  }

  const handleDelProjects = () => {
    if(adminActions.selectedProjects.length > 0){
      console.log('deleting',adminActions.selectedProjects)
    }
  }

  return (
    <div className="projects-container">
    {projects ? <div>
      <div className='d-toolbar'>
        <FaPlusSquare size="2em" title="Add new user" onClick={handleNewProject} style={{cursor:'pointer'}}/>
        <TimeToggle target="projects"/>
        <span>{adminActions.filteredProjects.length + ' projects found'}</span>
        <Button onClick={handleDelProjects}>DELETE SELECTED</Button>
      </div>
        <DataTable 
          // data={projects.slice((page * perPage), (page * perPage) + perPage)} 
          data={projects}
          tableColumns={projectColumns}
          loading={loading}
          expendable={true}
          scroll={{x: 500, y: '100vw'}} 
          pagination={{
            pagination: ['none', 'bottomCenter'],
            pageSize: 10
          }}
        />
    </div> : <PuffLoader />}
    </div>
  )
}

export default ProjectList