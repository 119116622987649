import { useContext, useState } from 'react'
import { useParams } from "react-router-dom";
import { IoHelpBuoySharp } from 'react-icons/io5'
import emailjs from '@emailjs/browser';
import ModalContext from "../../context/ModalContext";
import ProjectContext from "../../context/ProjectContext";
import { Form, Input, DatePicker, Checkbox, Button, message } from "antd";
import { FaRegQuestionCircle } from 'react-icons/fa';
import { auth, firestore as db } from "../../firebase/config";

const key = "updatable";

const Chat = ({bottom, customClass}) => {

  const [form] = Form.useForm();
  const params = useParams()
  const { toggleModal, setModalData } = useContext(ModalContext);
  const { messages } = useContext(ProjectContext);
  const [tooltip, setTooltip] = useState(false)

  const requestMeeting = (type ) => {
    console.log("meeting called");
    if (!auth.currentUser) {
      return message.error({
        content: messages.pleaseRegisterFirst,
        key,
        duration: 2,
      });
    }
    message.loading({
      content: messages.loading,
      key,
    });

    let data = {
      userId: auth.currentUser.uid,
      phone: document.getElementById("basic_phone").value,
      // date: new Date(document.getElementById("date-meeting").value).toLocaleDateString()
    }

    console.log("meeting called",data);

    if(!data.phone){
      return message.error({
        content: messages.pleaseFillMissingInformation,
        key,
        duration: 2,
      });
    }
    if(type === "whatsapp"){
      data.type = type;
    }
    else {
      data.email = document.getElementById("basic_email").value
      data.name = document.getElementById("basic_name").value
      data.allowWhatsapp = document.getElementById("allow-whatsapp-contact").checked
      data.type = "normal";
    }
    if(params.projectID){
      data.projectID = params.projectID
    }
    else if(localStorage.getItem("projectID")){
      data.projectID = localStorage.getItem("projectID")
    }
    else{
      return message.error({
        content: messages.noProjectToSchduleMeeting ? messages.noProjectToSchduleMeeting : messages.unknownError,
        key,
      });
    }
    console.log("Final Email Data", data)
    emailjs.send('service_a2nzu8s', 'template_prazb78', 
    // document.getElementById('basic'),
    data,
    'y0jf5duVfiqbtox2L')
      .then((result) => {
          console.log("emailjs success", result.text);
      }, (error) => {
          console.log("emailjs error",error.text);
      });
    db.collection("Meetings")
      .doc(data.projectID).set(data, {merge: true})
      .then((res) => {
        toggleModal();
        // if(type === "normal") {
        //   amplitudeEvent(EnumEventsAmplitude.onb_call_form_sent, '', EnumActionsAmplitude.InteractionEvent)
        // } else if(type === "whatsapp") {
        //   amplitudeEvent(EnumEventsAmplitude.whatsapp_estimate_sent, '', EnumActionsAmplitude.InteractionEvent)
        // }
        message.success({
          content: messages.requestSentSuccessfully  ,
          key,
          duration: 2,
        });


        localStorage.removeItem("projectID")
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: messages[err.code] ? messages[err.code] : messages.unknownError,
          key,
        });
      });
  };

  const handlePopup = () => {
    setModalData({
      heading: "Solicitar una llamada",
      subHeading: "con nuestro asesor",
      note: "Nos pondremos en contacto contigo para agendar una llamada lo antes posible, en la que a través de tu panel de usuario verificaremos los datos y definiremos todos los detalles de tu reforma.",
      body: (
        <Form
          form={form}
          layout="vertical"
          name="basic"
          initialValues={{
            remember: true,
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            id="email-meeting"
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor, introduce el email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Nombre"
            name="name"
            id="name-meeting"
            rules={[
              {
                required: true,
                message: "Por favor, introduce tu nombre!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Teléfono"
            name="phone"
            id="phone-meeting"
            rules={[
              {
                required: true,
                message: "Por favor, introduce el teléfono!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <DatePicker id="date-meeting" /> */}
          
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox id="allow-whatsapp-contact" >
              <b>¿Permitir contractar por whatsapp?</b> Para facilitar las
              comprobaciones sobre tu presupuesto
            </Checkbox>
          </Form.Item>
        </Form>
      ),
      buttons: [
        { text: "En otro momento", type: "text", onClick: "close" },
        {
          text: "Agendar llamada",
          type: "primary",
          onClick: () => requestMeeting(),
        }
      ],
    })
  }

  return (
    <div
      className={
        customClass || 'chat wideonly'
      }
      title="¿Te surgen dudas? Dejános tu contacto y te ayudaremos"
      style={{bottom:bottom && bottom}}
    >
      <div
        className='q-rightbtn q-navbtn'
        onMouseLeave={() => setTooltip(false)} 
        onMouseOver={() => setTooltip(true)} 
        onClick={() => handlePopup()} 
        style={{
          display:'flex',
          alignItems:'center',
          color:'white',
          background:'rgb(0, 125, 255)',
          border:'none'
        }}     
      >
        <FaRegQuestionCircle size="1.5em" color="white" />
        <span>&nbsp;¿Necesitas ayuda?</span>
      </div>
      {tooltip && <><div className='chat-tooltip'>¿Te surgen dudas? ¡Dejános tu contacto y te ayudaremos!</div></>}
    </div>
  )
}

export default Chat
