import { useEffect, useState, useContext, useRef } from 'react'
import { useLocation, useParams } from "react-router-dom";
import emailjs from '@emailjs/browser';
import Qlayout from '../components/qnr/Qlayout'
import Table from '../components/results/Table'
import Budget from '../components/budget/Budget'
import Zoom from '../components/widgets/Zoom.js'
import Stepper from '../components/widgets/Stepper.js'
import PopUp from '../components/widgets/PopUp.js'
import Chat from '../components/dashboard/Chat.js'
import PuffLoader from "react-spinners/PuffLoader";
import ProjectContext from '../context/ProjectContext';
import QnrContext from '../context/QnrContext';
import CalcContext from '../context/CalcContext';
import SceneContext from '../context/SceneContext';
import ChatContext from '../context/ChatContext';
import ModalContext from '../context/ModalContext';
import AuthContext from '../context/AuthContext'

import * as Scroll from "react-scroll"
import { database, auth, firestore as db } from '../firebase/config'
import { BsChevronExpand } from 'react-icons/bs'
import { amplitudeEvent } from '../utils/amplitude'
import { Tooltip, Form, Input, Checkbox, message } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { createElement, filterElement, getNewFromCalcData } from '../utils/sceneUtils'
import { checkZipCode, elementNameToId } from '../utils/formatUtils'
import { AiOutlineEdit, AiOutlineUser } from 'react-icons/ai'
import UserPanel from '../components/dashboard/UserPanel';

const key = "updatable";

const Qnr = () => {

const { project, setDefaults, calcData, setCalcData, results, setMessages } = useContext(ProjectContext)
const { activeQ, setActiveQ, qnrData, setQnrData, currentQnr, setCurrentQnr, filteredCategories, zoom, qnrCities, setQnrCities } = useContext(QnrContext)

const { toggleModal, setModalData, setOpen } = useContext(ModalContext);

const authActions = useContext(AuthContext)
const projectActions = useContext(ProjectContext)
const sceneActions = useContext(SceneContext)
const calcActions = useContext(CalcContext)
const chatActions = useContext(ChatContext)
const [ debug, setDebug ] = useState(false) 
const [ show, setShow ] = useState(false)
const [ userOpen, setUserOpen ] = useState(false)
const [ showShadow, setShowShadow ] = useState(false)
const [showTooltip, setTooltip] = useState(false)

const debugRef = useRef(null)
const layoutRef = useRef()

const location = useLocation(); 

const [form] = Form.useForm();
const params = useParams()

useEffect(() => {  
    const tempProject = projectActions.project;
    let qnrSrc = '/qnr/qnrDataHouse_es.json'
    let scenesSrc = '/planner/bathScenes.json'
    let elementsSrc = '/elements/elements.json'

    // fetch(process.env.PUBLIC_URL + '/qnr/cities_es.json')
    //     .then(data => {console.log('=====',data)})
    //     .catch(err => console.error(err))


    switch(location.pathname){
        case '/bano':
        case '/bano/':
            qnrSrc = '/qnr/qnrDataBath_es.json'
            scenesSrc = '/planner/bathScenes.json'
            projectActions.setRoomType(6)
            tempProject.scope = 'BanoProyecto'
            projectActions.setProject(tempProject)  
        break;
        case '/cocina':
        case '/cocina/':
            qnrSrc = '/qnr/qnrDataKitchen_es.json'
            scenesSrc = '/planner/kitchenScenes.json'
            projectActions.setRoomType(8)
            tempProject.scope = 'CocinaProyecto'
            projectActions.setProject(tempProject)       
        break;
        default:
            // tempProject.scope = 'CasaProyecto'
            // projectActions.setProject(tempProject)  
            qnrSrc = '/qnr/qnrData_es.json'
            scenesSrc = '/planner/scenes.json'
            projectActions.setRoomType(6)
            tempProject.scope = 'BanoProyecto'
            projectActions.setProject(tempProject)  
    }

    var starCountRef = database.ref(
        "14eDFn5tD9pjzebcLcnuOcs6bA53XO2r3psezHtCP9wc"
      )
      
    starCountRef.once("value", (snapshot) => {
        const data = snapshot.val();
        setCalcData(data)
    })

    Promise.all([
        fetch(process.env.PUBLIC_URL + qnrSrc),
        fetch(process.env.PUBLIC_URL + '/qnr/cities_es.json'),
        fetch(process.env.PUBLIC_URL + scenesSrc),
        fetch(process.env.PUBLIC_URL + '/planner/defaults.json'),
        fetch(process.env.PUBLIC_URL + elementsSrc),
        fetch(process.env.PUBLIC_URL + '/chat/chatData.json'),
    ]).then(allResponses => {
        const res0 = allResponses[0];
        const res1 = allResponses[1];
        const res2 = allResponses[2];
        const res3 = allResponses[3];
        const res4 = allResponses[4];
        const res5 = allResponses[5];
        Promise.all([
            res0.json(),
            res1.json(),
            res2.json(),
            res3.json(),
            res4.json(),
            res5.json()
        ]).then(allData => {
            const qData = allData[0];
            const qCities = allData[1];
            const allScenes = allData[2];
            const qDefaults = allData[3];
            const elements = allData[4];
            const chatData = allData[5];
            
            const actualData = qData['basicQNRData'];
            setQnrCities(qCities)
            setMessages(qData['messages'])
            projectActions.setStyles(actualData.styles)
            setQnrData(() => actualData);
            projectActions.setDefaults(qDefaults);
            projectActions.setElementsData(elements);
            projectActions.setSceneData(allScenes);

            chatActions.setChatData(chatData);
        })
    }).catch(err => console.log(err))
},[])

useEffect(() => {
    window.scrollTo({
        top:0
    })
    setOpen(false)
},[activeQ])

useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 10) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    });
  }, []);

const handleNext = (e) => {
    // console.log('SCROLL');
    let newActive = activeQ;

    // console.log(qnrData.categories.length,'-----',filteredCategories.map(c => c.id))
    // console.log(activeQ, 'activeQ', filteredCategories, 'filteredCategories', currentQnr, 'currentQnr')


    switch(e.target.id){
        case 'next':
            if(filteredCategories[activeQ].nextModal && filteredCategories[activeQ].nextModal.conditions){
                const conditions = [];
                filteredCategories[activeQ].nextModal.conditions.forEach(cond => {
                    switch(cond.type){
                        case 'zipCode':
                            console.log('before modal',qnrCities,projectActions.project.defaults.city)
                            if(checkZipCode(projectActions.project.defaults.city,qnrCities)){
                                setModalData({
                                    heading: filteredCategories[activeQ].nextModal.title ?? 'Modal',
                                    subHeading: filteredCategories[activeQ].nextModal.subtitle ?? '',
                                    note: filteredCategories[activeQ].nextModal.text ?? '',
                                    body: (<p></p>),
                                    buttons:filteredCategories[activeQ].nextModal.buttons.map(btn => {
                                        btn.text = btn.label
                                        btn.className = "q-navbtn"
                                        btn.onClick = () => {
                                            newActive++;
                                            console.log('NEXT INSIDE',newActive)
                                            setActiveQ(newActive)
                                            if(filteredCategories[activeQ].nextActions){
                                                makeActions(filteredCategories[activeQ].nextActions)
                                            }
                                        }
                                        return btn
                                    })
                                })
                            } else {
                                newActive++;
                                console.log('NEXT',newActive)
                                setActiveQ(newActive)
                                if(filteredCategories[activeQ].nextActions){
                                    makeActions(filteredCategories[activeQ].nextActions)
                                }
                            }
                        break;
                        default:
                    }  
               
                })
                return false;                         
            }
            newActive++;
            if(filteredCategories[activeQ].nextActions){
                filteredCategories[activeQ].nextActions.forEach(action => {
                    makeActions(filteredCategories[activeQ].nextActions)
                    // switch(action.a){
                    //     case 'setCurrentScene':
                    //         sceneActions.setCurrentScene(action.v)
                    //     break;
                    //     case 'copyScene':
                    //         sceneActions.copyScene();
                    //         // sceneActions.setModLayer(sceneActions.layer);
                    //     break;
                    //     case 'setDefault':
                    //         projectActions.setDefault(action.e,action.v)
                    //     break;
                    //     case 'makeCalculations':
                    //         calcActions.makeCalculations();
                    //     break;
                    //     case "addTitleToResult":
                    //         console.log('in next--------',projectActions.results[0])
                    //     case 'makeResults':
                    //         calcActions.makeResults();
                    //     break;
                    //     case 'add':
                    //         let features = new Array(8).fill(null);
                    //         const id_element = elementNameToId(action.e)
                    //         features[0] = projectActions.roomType ? projectActions.roomType : 6;
                    //         features[1] = action.typ;
                    //         const newElement = createElement(action.name,action.label,id_element,'doors',features)
                    //         sceneActions.addElement('initialTable',newElement)
                    //     break;
                    //     default:
                    //         console.log('NEXT ACTION',action)
                    // }
                })
            }
            handleAmplitudeContinueInteractionEvent(filteredCategories, activeQ, currentQnr)
        break;
        case 'prev':
            if(activeQ > 0){
                newActive--;
            }
            if(filteredCategories[activeQ].prevActions){
                makeActions(filteredCategories[activeQ].prevActions)
                // filteredCategories[activeQ].prevActions.forEach(action => {
                //     switch(action.a){
                //         case 'delScene':
                //             sceneActions.deleteScene(action.p)
                //         break;
                //         case 'setDefault':
                //             projectActions.setDefault(action.e,action.v)
                //         break;
                //         case 'setQuestion':
                //             setCurrentQnr({...currentQnr,[action.qId]:{...currentQnr[action.qId],value:action.v}});
                //         break;
                //     }
                // })
            }
            handleAmplitudeBackInteractionEventEvent(filteredCategories, activeQ)
        break;
        default:
    }

    setActiveQ(newActive)
    // Scroll.scroller.scrollTo("slide_" + newActive,{horizontal:false})
}

const makeActions = (actions) => {
    filteredCategories[activeQ].nextActions.forEach(action => {
        switch(action.a){
            case 'setCurrentScene':
                sceneActions.setCurrentScene(action.v)
            break;
            case 'copyScene':
                sceneActions.copyScene();
                // sceneActions.setModLayer(sceneActions.layer);
            break;
            case 'setDefault':
                projectActions.setDefault(action.e,action.v)
            break;
            case 'makeCalculations':
                calcActions.makeCalculations();
            break;
            case "addTitleToResult":
                console.log('in next--------',projectActions.results[0])
            case 'makeResults':
                calcActions.makeResults();
            break;
            case 'add':
                let features = new Array(8).fill(null);
                const id_element = elementNameToId(action.e)
                features[0] = projectActions.roomType ? projectActions.roomType : 6;
                features[1] = action.typ;
                const newElement = createElement(action.name,action.label,id_element,'doors',features)
                sceneActions.addElement('initialTable',newElement)
            break;
            case 'delScene':
                sceneActions.deleteScene(action.p)
            break;
            case 'setQuestion':
                setCurrentQnr({...currentQnr,[action.qId]:{...currentQnr[action.qId],value:action.v}});
            break;
            default:
                console.log('NEXT ACTION',action)
        }
    })
}

const requestMeeting = (type ) => {
    console.log("meeting called");
    if (!auth.currentUser) {
      return message.error({
        content: projectActions.messages.pleaseRegisterFirst,
        key,
        duration: 2,
      });
    }
    message.loading({
      content: projectActions.messages.loading,
      key,
    });

    let data = {
      userId: auth.currentUser.uid,
      phone: document.getElementById("basic_phone").value,
      // date: new Date(document.getElementById("date-meeting").value).toLocaleDateString()
    }

    console.log("meeting called",data);

    if(!data.phone){
      return message.error({
        content: projectActions.messages.pleaseFillMissingInformation,
        key,
        duration: 2,
      });
    }
    if(type === "whatsapp"){
      data.type = type;
    }
    else {
      data.email = document.getElementById("basic_email").value
      data.name = document.getElementById("basic_name").value
      data.allowWhatsapp = document.getElementById("allow-whatsapp-contact").checked
      data.type = "normal";
    }
    if(params.projectID){
      data.projectID = params.projectID
    }
    else if(localStorage.getItem("projectID")){
      data.projectID = localStorage.getItem("projectID")
    }
    else{
      return message.error({
        content: projectActions.messages.noProjectToSchduleMeeting ? projectActions.messages.noProjectToSchduleMeeting : projectActions.messages.unknownError,
        key,
      });
    }
    console.log("Final Email Data", data)
    emailjs.send('service_a2nzu8s', 'template_prazb78', 
    // document.getElementById('basic'),
    data,
    'y0jf5duVfiqbtox2L')
      .then((result) => {
          console.log("emailjs success", result.text);
      }, (error) => {
          console.log("emailjs error",error.text);
      });
    db.collection("Meetings")
      .doc(data.projectID).set(data, {merge: true})
      .then((res) => {
        toggleModal();
        // if(type === "normal") {
        //   amplitudeEvent(EnumEventsAmplitude.onb_call_form_sent, '', EnumActionsAmplitude.InteractionEvent)
        // } else if(type === "whatsapp") {
        //   amplitudeEvent(EnumEventsAmplitude.whatsapp_estimate_sent, '', EnumActionsAmplitude.InteractionEvent)
        // }
        message.success({
          content:projectActions.messages.requestSentSuccessfully  ,
          key,
          duration: 2,
        });


        localStorage.removeItem("projectID")
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: projectActions.messages[err.code] ? projectActions.messages[err.code] : projectActions.messages.unknownError,
          key,
        });
      });
  };


    const handleHelp = () => {
        setModalData({
            heading: "Solicitar una llamada",
            subHeading: "con nuestro asesor",
            note: "Nos pondremos en contacto contigo para agendar una llamada lo antes posible, en la que a través de tu panel de usuario verificaremos los datos y definiremos todos los detalles de tu reforma.",
            body: (
              <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  id="email-meeting"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce el email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
      
                <Form.Item
                  label="Nombre"
                  name="name"
                  id="name-meeting"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce tu nombre!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
      
                <Form.Item
                  label="Teléfono"
                  name="phone"
                  id="phone-meeting"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce el teléfono!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
      
                {/* <DatePicker id="date-meeting" /> */}
                
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox id="allow-whatsapp-contact" >
                    <b>¿Permitir contractar por whatsapp?</b> Para facilitar las
                    comprobaciones sobre tu presupuesto
                  </Checkbox>
                </Form.Item>
              </Form>
            ),
            buttons: [
              { text: "En otro momento", type: "text", onClick: "close" },
              {
                text: "Agendar llamada",
                type: "primary",
                onClick: () => requestMeeting(),
              }
            ],
          })
    }

    const checkCondition = (condition) => {
        if(condition.nextCondition){
            const conditions = [];
            condition.nextCondition.forEach(cond => {
                switch (cond.a){
                    case 'items':
                        const sceneId = (cond.table && cond.table === "integratedTable") ? 1 : 0; 
                        if(projectActions.scenes[sceneId]){
                        const filteredItems = projectActions.scenes[sceneId].layers[0].layer_1.items.filter(item => {
                            // const checkExistance = cond.v.some(id => id === item.id_element)
                            const matchedId = [];
                            cond.v.forEach(id => {
                                if(id === item.id_element){
                                    if(cond.filter){
                                        if(filterElement(item,null,item.id_element,cond.filter) === true){
                                            matchedId.push(true)
                                        } else {
                                            matchedId.push(false)
                                        }
                                    } else {
                                        matchedId.push(true)
                                    }
                                    
                                } else {
                                    matchedId.push(false)
                                }
                            })

                            return matchedId.some(id => id === true) || matchedId === []
                        })
                        if(filteredItems.length > 0){
                            conditions.push(true)
                        } else {
                            conditions.push(false)
                        }
                    }
                    break;
                    case 'defaultValue':
                        // console.log('checking condition',cond.v,projectActions.project.defaults,projectActions.project.defaults[cond.v] === undefined || projectActions.project.defaults[cond.v] === null)
                        if(projectActions.project.defaults[cond.v] === undefined || projectActions.project.defaults[cond.v] === null) {
                            conditions.push(false)
                        } else {
                            conditions.push(true)
                        }
                    break;
                    case 'zip':
                        if(projectActions.zipChange === true){
                            conditions.push(true)
                        } else {
                            conditions.push(false)
                        }
                    break;
                    default:
                }                
            })
            return conditions.length === 0 || conditions.some(c => c === true)
        } else {
            return true
        }
    }

    return (
        <div className='q-container' ref={layoutRef}>
            {filteredCategories 
                ? filteredCategories.filter((fq,i) => i === activeQ).map((category,i) => {
                    return <Qlayout key={i} id={i} category={category}/>
                })
                : <PuffLoader color={"#555"} loading={true} css={loaderCss} size={50} />
            }
            <div className="q-top-left" style={{
                transition:'box-shadow .5s',
                boxShadow:showShadow ? '10px 0px 32px rgba(0, 0, 0, 0.08)' : 'none'
            }}>
                {filteredCategories && <Stepper user={authActions.currentUser ? true : false}  categories={filteredCategories} />}
                <img className='q-logo' src={process.env.PUBLIC_URL + 'homyplan-logo.svg'} alt="HOMYPLAN-LOGO" />
                {(filteredCategories && filteredCategories[activeQ].c_tooltip) && <>
                <Tooltip title={<div><QuestionCircleOutlined />{filteredCategories[activeQ].c_tooltip}</div>} color={"blue"} >
                    <QuestionCircleOutlined />
                </Tooltip></>}
                {(filteredCategories && currentQnr) && <span className='debug'>{activeQ}/{filteredCategories.length}/{Object.keys(currentQnr).length}</span>}
                <div className='mobileonly q-link' style={{
                    position: 'fixed',
                    right: '1em',
                    top: '5.5em'
                    }}
                    onClick={handleHelp}
                >¿Necesitas ayuda?</div>   
                  
            </div>

            {/*{currentUser && <UserPanel />}*/}

            {(filteredCategories && !filteredCategories[activeQ].hidden) &&

            <div className={activeQ === 0 ? 'q-navbtns' : 'q-navbtns full'} style={{flexDirection:activeQ === 0 ? 'row-reverse' : 'row'}}>
            <>
                {activeQ > 0 && <div id="prev" className="q-navbtn prev" onClick={handleNext}>Atrás</div>}
                {(filteredCategories && activeQ < filteredCategories.length - 1) && <div id="next"
                    className={checkCondition(filteredCategories[activeQ]) ? 'q-navbtn next enabled' : 'q-navbtn next disabled'}
                    onClick={checkCondition(filteredCategories[activeQ]) ? handleNext : () => message.error({
                        content: projectActions.messages.makeActionFirst,
                        key,
                        duration: 2,
                        style: {
                            marginTop: '45vh'
                        }
                    })} 
                    title={checkCondition(filteredCategories[activeQ]) ? "" : projectActions.messages.makeActionFirst} 
                >{activeQ === 0 ? "Comenzar ahora" : "Continuar"} &nbsp; &#x279C;</div>}
            </>
            </div>
            }

            {filteredCategories && <div className="q-progress">
                  <div className='q-bar' style={{
                    width:100 * (activeQ + 1) / filteredCategories.length + '%'
                  }}/>
            </div>}
            {debug && <div className='q-output debug' ref={debugRef}>
                <div>
                    <button className='debug-btn debug' onClick={() => setShow(!show)}>Toggle results (RESULTS COUNT - {results ? results.length : 0}) <BsChevronExpand /></button>
                    <div>SCENES: {projectActions.scenes && projectActions.scenes.length > 0 ? projectActions.scenes.map((scene,j) => {
                        return <span key={j}>{scene.name}</span>
                    }):
                    "NO SCENES FOUND"
                    }
                    </div>
                    {show && <div className={'qnr-budget '}><Budget/></div>}
                    <button className="debug" style={{width:'200px',height:'50px',margin:'1em'}} onClick={() => calcActions.makeCalculations()}>MAKE CALCULATIONS</button>
                    <button className="debug" style={{width:'200px',height:'50px',margin:'1em'}} onClick={() => calcActions.makeResults()}>MAKE RESULTS</button>
                    <div className='b-table debug'><Table sceneId={0}/></div>
                    <div className='b-table debug'><Table sceneId={1}/></div>
                </div>
            </div>}   
            {authActions.currentUser && <UserPanel open={userOpen} name={false} onClickOutside={() => setUserOpen(false)}/>}            
            <PopUp />

            <Chat customClass={'chat-qnr wideonly'}/>

            {zoom && <Zoom />}
            <div className="debug" style={{
                position:'absolute', 
                width:"20px",
                height:"20px",
                top:'2em', 
                right:'10em', 
                border:'1px solid black', 
                cursor:'pointer',
                background:'white'}} 
                onClick={() => setDebug(!debug)}>X</div>
        </div> 
    )
}

const loaderCss = {
    position:'absolute',
    top:'50%', 
    left:'50%',
}  

const handleAmplitudeContinueInteractionEvent = (filteredCategoriesRaw, activeQRaw, currentQnrRaw) => {
    const foundQnr = filteredCategoriesRaw[activeQRaw];
    if(foundQnr) {
        const { amplitudeContinueInteractionEvent } = foundQnr;
        if(amplitudeContinueInteractionEvent) {
            if(!amplitudeContinueInteractionEvent.multiple) {
                const qnrSelectedValue = currentQnrRaw[amplitudeContinueInteractionEvent?.link]
                if(qnrSelectedValue) {
                    const selectedAmpliOption = amplitudeContinueInteractionEvent.options.find((option) => option.id == qnrSelectedValue.value)
                    if(selectedAmpliOption) {
                        amplitudeEvent(
                            amplitudeContinueInteractionEvent.name, 
                            {
                                from: foundQnr.c_name,
                                style: selectedAmpliOption.style,
                                quality: selectedAmpliOption.quality,
                                plan: selectedAmpliOption.plan
                            }, 
                            amplitudeContinueInteractionEvent.action
                        )
                    }
                } else {
                    amplitudeEvent(
                        amplitudeContinueInteractionEvent.name, 
                        {
                            from: foundQnr.c_name,
                        }, 
                        amplitudeContinueInteractionEvent.action
                    )
                }
            } else {
                let qualityTextArr = [];
                let styleTextArr = [];
                let planTextArr = [];
                for (const linkRaw of amplitudeContinueInteractionEvent?.link) {
                    const qnrSelectedValue = currentQnrRaw[linkRaw]
                    if(qnrSelectedValue) {
                        const selectedAmpliOption = amplitudeContinueInteractionEvent.options.find((option) => option.id == qnrSelectedValue.value)
                        if(selectedAmpliOption) {
                            if(selectedAmpliOption.quality) {
                                qualityTextArr.push(selectedAmpliOption.quality)
                            }
                            if(selectedAmpliOption.style) {
                                styleTextArr.push(selectedAmpliOption.style)
                            }
                            if(selectedAmpliOption.plan) {
                                planTextArr.push(selectedAmpliOption.plan)
                            }
                        }
                    }
                }
                const qualityText = qualityTextArr.length > 0 ? qualityTextArr.toString() : null;
                const styleText = styleTextArr.length > 0 ? styleTextArr.toString() : null;
                const planText = planTextArr.length > 0 ? planTextArr.toString() : null;
                amplitudeEvent(
                    amplitudeContinueInteractionEvent.name, 
                    {
                        from: foundQnr.c_name,
                        style: styleText,
                        quality: qualityText,
                        plan: planText
                    }, 
                    amplitudeContinueInteractionEvent.action
                )
            }
        }
    }
}

const handleAmplitudeBackInteractionEventEvent = (filteredCategoriesRaw, activeQRaw) => {
    const foundQnr = filteredCategoriesRaw[activeQRaw];
    if(foundQnr) {
        const { amplitudeBackInteractionEvent } = foundQnr;
        if(amplitudeBackInteractionEvent) {
            amplitudeEvent(
                amplitudeBackInteractionEvent.name, 
                {
                    from: foundQnr.c_name,
                }, 
                amplitudeBackInteractionEvent.action
            )
        }
    }
}



export default Qnr
