import { createContext, useState, useEffect } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
    const [message, setMessage] = useState(null);
    const [chatData, setChatData] = useState(null);

    return (
        <ChatContext.Provider value={{
            chatData, setChatData,
            message, setMessage
        }}>{children}</ChatContext.Provider>
    )
}

export default ChatContext;