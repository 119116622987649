import { useState, useEffect, useContext } from 'react'
import QnrContext from '../../context/QnrContext'
import ProjectContext from '../../context/ProjectContext'
import { getThumb } from '../../utils/formatUtils'
import { AiOutlineCheck } from 'react-icons/ai'

const SubstyleSelector = ({styles, action, localStyle, localSubstyle}) => {
    const qnrActions = useContext(QnrContext)
    const projectActions = useContext(ProjectContext)

    const [activeStyle, setStyle] = useState(projectActions.project.defaults.style ?? 0)
    const [activeSubstyle, setSubstyle] = useState(projectActions.project.defaults.substyle ?? 0)

    useEffect(() => {
        if(qnrActions.activeVariant !== 1){            
            setStyle(() => projectActions.project.defaults.style)
            setSubstyle(() => projectActions.project.defaults.substyle)
        } else {
            setStyle(() => localStyle)
        }
    },[projectActions.project.defaults.style,projectActions.project.defaults.substyle,localStyle])

    const i = 0;

    return styles && <div>
            {/* ||||| L: {localStyle} {localStyle} {localSubstyle} =V:{qnrActions.activeVariant} */}
            <div className='s-thumbs' style={{flexWrap:'wrap'}}>
                {styles[localStyle] && styles[localStyle].substyles.map((thumb,j) => <div key={j}
                    onClick={() => action(j)}
                    style={{
                        backgroundImage:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
                        backgroundSize:'200px',
                        flex:'0 0 45%',
                        height:'130px',
                        backgroundPosition:'center', 
                        backgroundRepeat:'no-repeat'  ,
                        position:'relative',  
                        border:j === localSubstyle ? '2px solid #007DFF' : '2px solid white'
                    }}
                    ><div style={{
                        display:'flex',
                        alignItems:'flex-end',
                        gap:'1em',
                        cursor:'pointer',
                        backgroundImage:`url(${process.env.PUBLIC_URL}${getThumb(thumb.url)})`,
                        backgroundSize:'cover',
                        backgroundPosition:'center', 
                        backgroundRepeat:'no-repeat',
                        borderRadius:'.5em',
                        height:'100%',
                    }}
                ><div style={{
                    padding:'10px 10px',
                    background:'linear-gradient(360deg, rgba(21, 19, 19, 0.7) 0%, rgba(21, 19, 19, 0) 100%)',
                    width:'100%'
                }}>{thumb.label}</div></div>
                    {j === localSubstyle && <div
                    style={{
                        position:'absolute',
                        top:'10px',
                        right:'10px',
                        background:'#007DFF',
                        width:'20px',
                        height:'20px',
                        borderRadius:'10px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}>
                    <AiOutlineCheck /></div>}
                </div>)}
            </div></div>
}

export default SubstyleSelector