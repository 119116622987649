import { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [selectedUser, setSelectedUser] = useState(JSON.parse(localStorage.getItem("selectedUser")));
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userDetails")))

    useEffect(() => {
        localStorage.setItem("user",JSON.stringify(currentUser))
        localStorage.setItem("userDetails",JSON.stringify(user))
    },[currentUser])

    useEffect(() => {
        localStorage.setItem("selectedUser",JSON.stringify(selectedUser))
    },[selectedUser])

    return (
        <AuthContext.Provider value={{
            currentUser, setCurrentUser,
            selectedUser, setSelectedUser,
            user, setUser
        }}>{children}</AuthContext.Provider>
    )
}

export default AuthContext;