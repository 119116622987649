import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Upload } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import ProjectContext from '../../context/ProjectContext';
import { storage } from '../../firebase/config';

function ImageUpload({files, resultNum}) {
  let projectActions = useContext(ProjectContext)
  const [fileList, setFileList] = useState(
    files.map((item, key) => ({...item, uid: key, status: 'done'}))
  );
  useEffect(() => {
    console.log("useEffect", files)
    setFileList(files.map((item, key) => ({...item, uid: key, status: 'done'})))
  }, [files])

  const handleUpload = (file) => {
    return projectActions.uploadResultImage(file, resultNum)
  }

  const handleRemove = (file) => {
    projectActions.removeImageFromStorage(file,resultNum)
  }

  const handleChange = (info) => {
    console.log('handleChange', info)
    if(info.file.status != 'removed'){
      handleUpload(info.file)
    }
      
  }

  return (
    <div className='content' style={{background: 'white', minWidth: 360, padding: 20, margin: 20}} >
      <h1>Upload Images</h1>

      <Upload
        // action={handleUpload}
        onRemove={handleRemove}
        onChange={handleChange}
        listType="picture-card"
        beforeUpload={() => false}

        defaultFileList={[...fileList]}
        // beforeUpload={handlePlanUpload}
      >
        <UploadOutlined style={{marginRight: 3}}  />Upload
      </Upload>


  </div>
  )
}

export default ImageUpload