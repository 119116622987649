import { Col, Row, Spin } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import ProjectContext from '../../context/ProjectContext'
import { database, firestore as db } from '../../firebase/config'
import Summary from '../results/Summary'
import Modal from "../widgets/Modal"
import Output from '../budget/Output'
import Budget from "../budget/Budget";

function SharedBudget() {
    const projectActions = useContext(ProjectContext)
    const [summaryData, setSummaryData] = useState(null)
    const [messages, setMessages] = useState(null)
    const [currentUser,setCurrentUser] = useState(null) 
    const [projects, setProjects] = useState([])
    let params = useParams();

    useEffect(() => {
        document.getElementsByTagName('body')[0].style.background = "none"
        // document.getElementsByTagName('body')[0].style.height = "100vh"

        db.collection("Projects")
            .doc(params.projectID).get().then(res => {
            if (res.exists) {
                projectActions.setResults(res.data().results)
                setCurrentUser(() => res.data().uid)
                setProjects(() => getProjects(res.data().userId))
            }
        })

        var starCountRef = database.ref(
            "14eDFn5tD9pjzebcLcnuOcs6bA53XO2r3psezHtCP9wc"
        )

        starCountRef.once("value", (snapshot) => {
            const data = snapshot.val();
            // console.log(data)
            projectActions.setCalcData(data)
        })

        Promise.all([
            fetch(process.env.PUBLIC_URL + '/elements/elements.json'),
            fetch(process.env.PUBLIC_URL + '/qnr/qnrDataBath_es.json')
        ]).then(allResponses => {
            const res0 = allResponses[0];
            const res1 = allResponses[1];
            Promise.all([
                res0.json(),
                res1.json()
            ]).then(allData => {
                const elements = allData[0];
                const qData = allData[1]
                let summryDta = qData['basicQNRData']["categories"][
                    qData['basicQNRData']["categories"].findIndex(x => x.id === 'c-11')
                ];
                console.log('===', summryDta)
                projectActions.setElementsData(elements);
                setSummaryData(summryDta);
                setMessages(qData['messages'])
            })
        }).catch(err => console.log(err))
    }, [])

    const listCss = {
        color: '#04073F',
        margin: '2em',
        listStyle: 'square'
    }

    const getProjects = async () => {  
        const projects = await db.collection("Projects").orderBy("created_At")
        .where("userId", "==", currentUser)
        .limit(20).get();
        const tempProjects = [];
        projects.forEach(doc => {
          const project = doc.data()
          tempProjects.push({projectId:doc.id,...project})
        })
        return tempProjects
    };

    return (
        <div style={{ background: "#ECECEC", overflow: 'hidden', height: '100%' }} className='custom-budget' >
            {projectActions.modal !== null && <Modal category={null} />}
            {projects.length > 0 && projects.map(prj => {
                return <div>{prj.name}</div>
            })}
            {summaryData && messages && projectActions.calcData ?
                <Row
                    gutter={40}
                    style={{ overflow: 'auto', height: '100%', margin: 0 }}>
                    <Col
                        xs={24}
                        md={8}
                        className="height-responsive"
                        style={{
                            backgroundColor: "white",
                            padding: 30,
                            overflow: "auto",
                        }}
                    >
                        <div style={{ paddingBottom: '2em' }}>
                            <img className='logo' src={'../' + process.env.PUBLIC_URL + 'homyplan-logo.svg'} alt="HOMYPLAN-LOGO" />
                        </div>
                        {summaryData.c_name && <p className="q-name" dangerouslySetInnerHTML={{ __html: summaryData.c_name }} />}
                        {summaryData.c_title && <p className="c-title" dangerouslySetInnerHTML={{ __html: summaryData.c_title }} />}
                        {summaryData.c_subtitle && <p className="q-subtitle" dangerouslySetInnerHTML={{ __html: summaryData.c_subtitle }} />}
                        {summaryData.c_description && <p className="q-description" dangerouslySetInnerHTML={{ __html: summaryData.c_description }} />}
                        {summaryData.c_message && <div className="q-message">
                            {(summaryData.c_message && summaryData.c_message.icon) && <span className="q-icon">{summaryData.c_message.icon}</span>}
                            <p dangerouslySetInnerHTML={{ __html: summaryData.c_message.text }} />
                        </div>}

                        {projects && projects.length > 1 ? <div>PROJECTS</div> : <div>NO PROJECTS FOUND</div>}

                        {
                            projectActions.results.length > 1
                                ? <Output wideonly={false} result={projectActions.results[0]} projects={projects}/>
                                : <Summary q={summaryData} messages={messages} buttons="both" />
                        }
                         
                        <div className="border-radius-responsive mobileonly new-budget" style={{ background: "white" }} >
                            {
                                projectActions.results.length > 0
                                ? <Budget result={projectActions.results[0]} isDownload={false} isFooter={true}/>
                                : <h3>No results</h3>
                            }
                        </div>
                        <div className="q-panel" style={{ marginTop: '2em' }}>
                            {summaryData.questions[2].options[projectActions.results.length > 1 ? 1 : 0].label && summaryData.questions[2].options[projectActions.results.length > 1 ? 1 : 0].label}
                            {summaryData.questions[2].options[projectActions.results.length > 1 ? 1 : 0].list && <ul style={listCss} className='checkpoint-list'>
                                {summaryData.questions[2].options[projectActions.results.length > 1 ? 1 : 0].list.map((li, j) => <li key={j}>
                                    {li.title && <div style={{
                                        textDecoration: li.checked ? 'line-through' : 'none'
                                    }}>{li.title}</div>}
                                    {li.description && <div style={{
                                        color: '#555',
                                        fontSize: '0.8em',
                                        marginBottom: '1em'
                                    }}>{li.description}</div>}
                                </li>)}
                            </ul>}
                        </div>
                        {projectActions.results.length > 1 && <Summary q={summaryData} messages={messages} buttons="first" />}
                    </Col>
                    <Col
                        xs={24}
                        md={16}
                        className="col-budget flex justify-center items-center py-4"
                        style={{
                            width: '100%',
                            overflow: "auto",
                            maxHeight: "100vh",
                            paddingRight: 0,
                            paddingLeft: 0
                        }}
                    >
                        <div className={'w-80 bg-white rounded h-shared-budget'}>
                            {projectActions.results.length > 1 && (
                                <div className="p-4">
                                    <Output wideonly={true} result={projectActions.results[0]}/>
                                    {projects && projects.length > 1 ? <div>PROJECTS</div> : <div>NO PROJECTS</div>}
                                </div>
                            )}
                            <div className={'wideonly '}>
                                {projectActions.results.length
                                    ? <Budget result={projectActions.results[0]} isDownload={false} isFooter={true}/>
                                    : <h3>No results</h3>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                :
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }}>
                    <Spin style={{
                        margin: "20px 0",
                        padding: "30px 50px",
                        textAlign: "center",
                        background: "white",
                        borderRadius: 4,
                    }} />
                </div>
            }
        </div>
    )
}

export default SharedBudget;

{/* projectActions.results.map((result,i) => <Budget2 key={i} resultId={i} resultsSize={projectActions.results.length} result={result}/>) */ }
