import { useEffect, useContext, useState } from 'react';
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'

const StyleImg = ({styles}) => {

  const projectActions = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)

  const [active, setActive] = useState(0)
  const [activeSubstyle, setActiveSubstyle] = useState(0)

  useEffect(() => {
    setActive(projectActions.project.defaults.style ?? 0)
    setActiveSubstyle(projectActions.project.defaults.substyle ?? 0)
  },[sceneActions.refresh])

  return (
    <div className='styleImg'
      style={{
        background:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
        backgroundSize:'cover',
        backgroundPosition:'center', 
        backgroundRepeat:'no-repeat'                
      }}>
      <div style={{
          display:'flex',
          flexDirection:'column',
          justifyContent:'flex-end',
          height:'100%',
          backgroundImage:`url(${process.env.PUBLIC_URL}${styles[active ?? 0].substyles[activeSubstyle ?? 0].url})`,
          backgroundSize:'cover',
          backgroundPosition:'center',
          color:'white',
          borderRadius:'1em'
      }}>
          <div className='summary-title-style-card'>
              <div>Estilo seleccionado</div>
              {/* <img src={process.env.PUBLIC_URL + '/' + styles[active].substyles[activeSubstyle].url}/> */}
              <div className="c-title" style={{color:'white'}} dangerouslySetInnerHTML={{__html:styles[active].label}}></div>
          </div>
      </div>
    </div>
  )
}

export default StyleImg
