import { BsCheck2 } from 'react-icons/bs'

const Check = () => {
    return <div style={{
        background:'white',
        borderRadius:'50%',
        width:'40px',
        height:'40px',
        textAlign:'center',
        paddingTop:'8px'
    }}>
        <BsCheck2 size="25px" color="#007DFF"/>
    </div>
}

export default Check