import { useEffect, useState, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import ExtLayout from '../components/qnr/ExtLayout'
import Table from '../components/results/Table'
import Budget from '../components/budget/Budget'
import MiniBudget from '../components/results/MiniBudget'
import Zoom from '../components/widgets/Zoom.js'
import Stepper from '../components/widgets/Stepper.js'
import Chat from '../components/dashboard/Chat.js'
import PuffLoader from "react-spinners/PuffLoader";
import ProjectContext from '../context/ProjectContext';
import QnrContext from '../context/QnrContext';
import CalcContext from '../context/CalcContext';
import SceneContext from '../context/SceneContext';
import ChatContext from '../context/ChatContext';
import ModalContext from '../context/ModalContext';
import AuthContext from '../context/AuthContext';
import * as Scroll from "react-scroll";
import { database, auth, firestore as db } from '../firebase/config';
import { BsChevronExpand } from 'react-icons/bs';
import { amplitudeEvent } from '../utils/amplitude'; 
import { Tooltip, Form, Input, Checkbox, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { filterElement } from '../utils/sceneUtils';
import Column from 'antd/lib/table/Column';
import PopUp from '../components/widgets/PopUp';
import Modal from '../components/widgets/Modal';
import UserPanel from '../components/dashboard/UserPanel'

const key = "updatable";

const Qnr = () => {

    const { loaded, project, setDefaults, calcData, setCalcData, results, loadProject, setMessages } = useContext(ProjectContext)
    const { activeQ, setActiveQ, qnrData, setQnrData, currentQnr, filteredCategories, zoom, setQnrCities } = useContext(QnrContext)
    const { currentUser, setCurrentUser, user, setUser } = useContext(AuthContext)
    const { toggleModal, setModalData } = useContext(ModalContext);
    
const projectActions = useContext(ProjectContext)
const sceneActions = useContext(SceneContext)
const qnrActions = useContext(QnrContext)
const calcActions = useContext(CalcContext)
const chatActions = useContext(ChatContext)
const [ allLoaded, setAllLoaded ] = useState(false) 
const [ debug, setDebug ] = useState(false) 
const [ show, setShow ] = useState(false)
const [ activeCategory, setActiveCategory ] = useState(0)
const [ userOpen, setUserOpen ] = useState(false)

const debugRef = useRef(null)
let params = useParams();
const [form] = Form.useForm();

useEffect(() => {   
    var starCountRef = database.ref(
        "14eDFn5tD9pjzebcLcnuOcs6bA53XO2r3psezHtCP9wc"
    )
      

    starCountRef.once("value", (snapshot) => {
        const data = snapshot.val();
        setCalcData(data)
    })

    projectActions.loadProject(params)

    if(projectActions.loaded){

    // console.log(projectActions.project)

    let dataPath = '';
    let scenePath = '';
    
    switch (projectActions.project.scope){
        case 'CocinaProyecto':
            dataPath = '/qnr/qnrDataKitchenDashboard_es.json'
            scenePath = '/planner/kitchenScenes.json'
            projectActions.setRoomType(8)
        break;
        case 'BanoProyecto':
            dataPath = '/qnr/qnrDataBathDashboard_es.json'
            scenePath = '/planner/bathScenes.json'
            projectActions.setRoomType(6)
        break;
        default:
            dataPath = '/qnr/qnrDataHouseDashboard_es.json'
            scenePath = '/planner/scenes.json'
            projectActions.setRoomType(1)
    }
    
    Promise.all([
        fetch(process.env.PUBLIC_URL + dataPath),
        fetch(process.env.PUBLIC_URL + '/qnr/cities_es.json'),
        fetch(process.env.PUBLIC_URL + scenePath),
        fetch(process.env.PUBLIC_URL + '/planner/defaults.json'),
        fetch(process.env.PUBLIC_URL + '/elements/elements.json'),
        fetch(process.env.PUBLIC_URL + '/chat/chatData.json'),
    ]).then(allResponses => {
        const res0 = allResponses[0];
        const res1 = allResponses[1];
        const res2 = allResponses[2];
        const res3 = allResponses[3];
        const res4 = allResponses[4];
        const res5 = allResponses[5];
        Promise.all([
            res0.json(),
            res1.json(),
            res2.json(),
            res3.json(),
            res4.json(),
            res5.json()
        ]).then(allData => {
            const qData = allData[0];
            const qCities = allData[1];
            const allScenes = allData[2];
            const qDefaults = allData[3];
            const elements = allData[4];
            const chatData = allData[5];
            
            const actualData = qData['extQNRData'];
            setQnrCities(qCities.cities)
            projectActions.setMessages(qData['messages'])
            projectActions.setPopups(qData['popups'])
            projectActions.setStyles(actualData.styles)
            setQnrData(actualData);
            setDefaults(qDefaults);
            projectActions.setElementsData(elements);
            projectActions.setSceneData(allScenes);
            chatActions.setChatData(chatData);
            projectActions.loadProject(params)
            setAllLoaded(true)
        })
    }).catch(err => console.log(err))
}
},[projectActions.loaded])


const handleResults = () => {
    calcActions.makeCalculations()
    calcActions.makeResults()
}

const handleReset = () => {
    alert('You will lost all calculations! Are You sure ?')
}

const handleNext = (e) => {
    let newActive = activeQ;

    // console.log(qnrData.categories.length,'-----',filteredCategories.map(c => c.id))

    switch(e.target.id){
        case 'next':

            newActive++;
            if(filteredCategories[activeQ].nextActions){
                filteredCategories[activeQ].nextActions.forEach(action => {
                    switch(action.a){
                        case 'setCurrentScene':
                            sceneActions.setCurrentScene(action.v)
                        break;
                        case 'copyScene':
                            sceneActions.copyScene();
                            // sceneActions.setModLayer(sceneActions.layer);
                        break;
                        case 'makeCalculations':
                            calcActions.makeCalculations();
                        break;
                        case 'makeResults':
                            calcActions.makeResults();
                        break;
                        default:
                    }
                    // console.log('NEXT ACTION',action)
                })
            }
            handleAmplitudeContinueInteractionEvent(filteredCategories, activeQ, currentQnr)
        break;
        case 'prev':
            if(activeQ > 0){
                newActive--;
            }
            handleAmplitudeBackInteractionEventEvent(filteredCategories, activeQ)
        break;
        default:
    }

    setActiveQ(newActive)    
    console.log('NEXT ',e.target.id,activeQ, 'activeQ', filteredCategories, 'filteredCategories', currentQnr, 'currentQnr')

    // Scroll.scroller.scrollTo("slide_" + newActive,{horizontal:false})
}

const requestMeeting = (type ) => {
    console.log("meeting called");
    if (!auth.currentUser) {
      return message.error({
        content: projectActions.messages.pleaseRegisterFirst,
        key,
        duration: 2,
      });
    }
    message.loading({
      content: projectActions.messages.loading,
      key,
    });

    let data = {
      userId: auth.currentUser.uid,
      phone: document.getElementById("basic_phone").value,
      // date: new Date(document.getElementById("date-meeting").value).toLocaleDateString()
    }

    console.log("meeting called",data);

    if(!data.phone){
      return message.error({
        content: projectActions.messages.pleaseFillMissingInformation,
        key,
        duration: 2,
      });
    }
    if(type === "whatsapp"){
      data.type = type;
    }
    else {
      data.email = document.getElementById("basic_email").value
      data.name = document.getElementById("basic_name").value
      data.allowWhatsapp = document.getElementById("allow-whatsapp-contact").checked
      data.type = "normal";
    }
    if(params.projectID){
      data.projectID = params.projectID
    }
    else if(localStorage.getItem("projectID")){
      data.projectID = localStorage.getItem("projectID")
    }
    else{
      return message.error({
        content: projectActions.messages.noProjectToSchduleMeeting ? projectActions.messages.noProjectToSchduleMeeting : projectActions.messages.unknownError,
        key,
      });
    }
    console.log("Final Email Data", data)
    emailjs.send('service_a2nzu8s', 'template_prazb78', 
    // document.getElementById('basic'),
    data,
    'y0jf5duVfiqbtox2L')
      .then((result) => {
          console.log("emailjs success", result.text);
      }, (error) => {
          console.log("emailjs error",error.text);
      });
    db.collection("Meetings")
      .doc(data.projectID).set(data, {merge: true})
      .then((res) => {
        toggleModal();
        // if(type === "normal") {
        //   amplitudeEvent(EnumEventsAmplitude.onb_call_form_sent, '', EnumActionsAmplitude.InteractionEvent)
        // } else if(type === "whatsapp") {
        //   amplitudeEvent(EnumEventsAmplitude.whatsapp_estimate_sent, '', EnumActionsAmplitude.InteractionEvent)
        // }
        message.success({
          content:projectActions.messages.requestSentSuccessfully  ,
          key,
          duration: 2,
        });


        localStorage.removeItem("projectID")
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: projectActions.messages[err.code] ? projectActions.messages[err.code] : projectActions.messages.unknownError,
          key,
        });
      });
  };


    const handleHelp = () => {
        setModalData({
            heading: "Solicitar una llamada",
            subHeading: "con nuestro asesor",
            note: "Nos pondremos en contacto contigo para agendar una llamada lo antes posible, en la que a través de tu panel de usuario verificaremos los datos y definiremos todos los detalles de tu reforma.",
            body: (
              <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  id="email-meeting"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce el email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
      
                <Form.Item
                  label="Nombre"
                  name="name"
                  id="name-meeting"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce tu nombre!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
      
                <Form.Item
                  label="Teléfono"
                  name="phone"
                  id="phone-meeting"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, introduce el teléfono!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
      
                {/* <DatePicker id="date-meeting" /> */}
                
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox id="allow-whatsapp-contact" >
                    <b>¿Permitir contractar por whatsapp?</b> Para facilitar las
                    comprobaciones sobre tu presupuesto
                  </Checkbox>
                </Form.Item>
              </Form>
            ),
            buttons: [
              { text: "En otro momento", type: "text", onClick: "close" },
              {
                text: "Agendar llamada",
                type: "primary",
                onClick: () => requestMeeting(),
              }
            ],
          })
    }

    const bigBtnCss = {
        borderRadius:'0.5em',
        height:'4em',
        width:'100%'
    }
    
    return (
        <div className="q-container">
        {(filteredCategories && filteredCategories !== null && allLoaded === true && projectActions.calcData) ? 
          <div className='q-ext-layout'>
            <div
                id="scroll-chapter-project"
                // className={(qnrActions.chapter !== 4 ? 'custom-padding-chapter' : !qnrActions.chapter ? 'custom-padding-ext-qnr' : '') + ' q-left'}>
                className={'q-left'}>
              {filteredCategories.filter(cat => cat.chapter === qnrActions.chapter).map((category,i) => {
                  return <ExtLayout key={i} activeCategory={activeCategory} id={i} category={category}/>
              })}
            {/* {!qnrActions.chapter &&<div className="flex justify-end mt-4">
                <Chat customClass={'chat-qnr-project wideonly'}/>
            </div>} */}
            </div>
            {/* <div className='q-right'>
                <Budget />
                <div style={{display:'flex',justifyContent:'space-around',gap:'0.5em'}}>
                    <Button style={bigBtnCss} onClick={calcActions.makeResults}>Recalculate budget</Button>
                    <Button style={bigBtnCss} onClick={projectActions.saveProject}>Save project</Button>
                </div>
            </div> */}
            </div>
            : <PuffLoader color={"#555"} loading={true} css={loaderCss} size={50} />}      

            {/* <div className={activeQ === 0 ? 'q-footer left' : 'q-footer full'} style={{flexDirection:activeQ === 0 ? 'row-reverse' : 'row'}}>
            {(filteredCategories && !filteredCategories[activeQ].hidden) &&
            <>
                {activeQ > 0 && <div id="prev" className="q-navbtn prev" onClick={handleNext}>Atrás</div>}
                {(filteredCategories && activeQ < filteredCategories.length - 1 && checkCondition(filteredCategories[activeQ])) && <div id="next" 
                    className='q-navbtn next' 
                    onClick={handleNext}>{activeQ === 0 ? "Empieza tu reforma" : "Continuar"} &nbsp; &#x279C;</div>}
            </>}
            
            </div> */}

            {/* <div className='q-help q-link' style={{
              position:'fixed',
            }}
            onClick={handleHelp}
            >¿Necesitas ayuda?</div> */}


            {filteredCategories && <div className="q-progress">
                <div className='q-bar' style={{
                    width:100 * (activeQ + 1) / filteredCategories.length + '%'
                }}/>
            </div>}

            {debug && <div className='q-output debug' ref={debugRef} style={{marginBottom:'8em',maxHeight:'calc(100vh - 8em)'}}>
                <div>
                    <button className='debug-btn debug' onClick={() => setShow(!show)}>Toggle results (RESULTS COUNT - {results ? results.length : 0}) <BsChevronExpand /></button>
                    <div>SCENES: {projectActions.scenes && projectActions.scenes.length > 0 ? projectActions.scenes.map((scene,j) => {
                        return <span key={j}> {scene.name} </span>
                    }):
                     "NO SCENES FUND"
                    }
                    </div>
                    {show && <Budget />}
                    <button className="debug" style={{width:'200px',height:'50px',margin:'1em'}} onClick={() => calcActions.makeCalculations()}>MAKE CALCULATIONS</button>
                    <button className="debug" style={{width:'200px',height:'50px',margin:'1em'}} onClick={() => calcActions.makeResults()}>MAKE RESULTS</button>
                    <div className='b-table debug'><Table sceneId={0}/></div>
                    <div className='b-table debug'><Table sceneId={1}/></div>
                </div>
            </div>}

            <div className="q-navbar">
              <div className="q-navbar-content">
                {/* {filteredCategories && <Stepper categories={filteredCategories} />} */}
                <img className='logo' style={{height:'30px'}} src={'../' + process.env.PUBLIC_URL + 'homyplan-logo.svg'} alt="HOMYPLAN-LOGO" />
                {qnrActions.chapter > 0 && <div className='q-navbtn' onClick={() => qnrActions.setChapter(0)}>{"<"} Volver al panel principal</div>}
                {(filteredCategories && filteredCategories[activeQ].c_tooltip) && <>
                <Tooltip title={<div><QuestionCircleOutlined /> {filteredCategories[activeQ].c_tooltip}</div>} color={"blue"} >
                <QuestionCircleOutlined />
                </Tooltip></>}
                {(filteredCategories && currentQnr) && <span className='debug'>{activeQ}/{filteredCategories.length}/{Object.keys(currentQnr).length}</span>} 
                <UserPanel open={userOpen} onClickOutside={() => setUserOpen(false)}/>
              </div>      
            </div>

            <div className='q-footer'>
                <div className='q-footer-content'>
                    <MiniBudget />
                    {/* <div className='q-navbtn border' onClick={() => projectActions.setPopup(3)}>Limpiar equip.inicial</div> */}
                    <div className='q-footer-btns'>
                      <div className='q-navbtn border' onClick={() => projectActions.setPopup(2)}>Limpiar reforma</div>
                      <div className='q-navbtn border' onClick={() => handleResults()}>Calcular presupuesto</div>
                      {/* <div className='q-navbtn border' onClick={() => projectActions.addResult(projectActions.results[0])}>Add result</div> */}
                      <div className='q-navbtn border' onClick={() => projectActions.setPopup(1)}>Guardar presupuesto</div>
                    </div>
                </div>
            </div>

            {projectActions.modal !== null && <Modal category={filteredCategories.find(category => category.id === projectActions.modal)} />}

            <PopUp />

            {/* <Chat bottom="8em"/> */}
            
            {zoom && <Zoom />}
            
            <div className="debug" style={{
                position:'absolute', 
                width:"20px",
                height:"20px",
                top:'2em', 
                right:'10em', 
                border:'1px solid black', 
                cursor:'pointer',
                background:'white'}} 
                onClick={() => setDebug(!debug)}>X
            </div>
        </div>
    )
}

const loaderCss = {
    position:'absolute',
    top:'50%', 
    left:'50%',
}  
    
const handleAmplitudeContinueInteractionEvent = (filteredCategoriesRaw, activeQRaw, currentQnrRaw) => {
    const foundQnr = filteredCategoriesRaw[activeQRaw];
    if(foundQnr) {
        const { amplitudeContinueInteractionEvent } = foundQnr;
        if(amplitudeContinueInteractionEvent) {
            if(!amplitudeContinueInteractionEvent.multiple) {
                const qnrSelectedValue = currentQnrRaw[amplitudeContinueInteractionEvent?.link]
                if(qnrSelectedValue) {
                    const selectedAmpliOption = amplitudeContinueInteractionEvent.options.find((option) => option.id == qnrSelectedValue.value)
                    if(selectedAmpliOption) {
                        amplitudeEvent(
                            amplitudeContinueInteractionEvent.name, 
                            {
                                from: foundQnr.c_name,
                                style: selectedAmpliOption.style,
                                quality: selectedAmpliOption.quality,
                                plan: selectedAmpliOption.plan
                            }, 
                            amplitudeContinueInteractionEvent.action
                        )
                    }
                } else {
                    amplitudeEvent(
                        amplitudeContinueInteractionEvent.name, 
                        {
                            from: foundQnr.c_name,
                        }, 
                        amplitudeContinueInteractionEvent.action
                    )
                }
            } else {
                let qualityTextArr = [];
                let styleTextArr = [];
                let planTextArr = [];
                for (const linkRaw of amplitudeContinueInteractionEvent?.link) {
                    const qnrSelectedValue = currentQnrRaw[linkRaw]
                    if(qnrSelectedValue) {
                        const selectedAmpliOption = amplitudeContinueInteractionEvent.options.find((option) => option.id == qnrSelectedValue.value)
                        if(selectedAmpliOption) {
                            if(selectedAmpliOption.quality) {
                                qualityTextArr.push(selectedAmpliOption.quality)
                            }
                            if(selectedAmpliOption.style) {
                                styleTextArr.push(selectedAmpliOption.style)
                            }
                            if(selectedAmpliOption.plan) {
                                planTextArr.push(selectedAmpliOption.plan)
                            }
                        }
                    }
                }
                const qualityText = qualityTextArr.length > 0 ? qualityTextArr.toString() : null;
                const styleText = styleTextArr.length > 0 ? styleTextArr.toString() : null;
                const planText = planTextArr.length > 0 ? planTextArr.toString() : null;
                amplitudeEvent(
                    amplitudeContinueInteractionEvent.name, 
                    {
                        from: foundQnr.c_name,
                        style: styleText,
                        quality: qualityText,
                        plan: planText
                    }, 
                    amplitudeContinueInteractionEvent.action
                )
            }
        }
    }
}

const handleAmplitudeBackInteractionEventEvent = (filteredCategoriesRaw, activeQRaw) => {
    const foundQnr = filteredCategoriesRaw[activeQRaw];
    if(foundQnr) {
        const { amplitudeBackInteractionEvent } = foundQnr;
        if(amplitudeBackInteractionEvent) {
            amplitudeEvent(
                amplitudeBackInteractionEvent.name, 
                {
                    from: foundQnr.c_name,
                }, 
                amplitudeBackInteractionEvent.action
            )
        }
    }
}

export default Qnr
