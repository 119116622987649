import { useContext, useEffect, useState} from 'react'
import { Link, useNavigate  } from 'react-router-dom'
import { message, Modal, Form, Input, Select, Checkbox, Button } from "antd";
import AuthContext from '../../context/AuthContext'
import AdminContext from '../../context/AdminContext'
import ModalContext from '../../context/ModalContext'
import { FaTrash } from 'react-icons/fa'
import { FiSave, FiEdit } from 'react-icons/fi'

const UserProfile = () => {

  const authActions = useContext(AuthContext)
  const adminActions = useContext(AdminContext)
  const modalActions = useContext(ModalContext)

  const navigate = useNavigate()

  const [activeUser, setActiveUser] = useState(authActions.selectedUser)
  const [edit,setEdit] = useState(false)
  const [delProjects,setDelProjects] = useState(true)
  const [editUserForm] = Form.useForm();

  useEffect(() => {
    setEdit(false)
  },[])

  useEffect(() => {
    setActiveUser(() => adminActions.filteredUsers.find(user => user.userId === authActions.selectedUser))
    console.log('IN PROFILE',activeUser,adminActions.filteredUsers)    
  },[authActions.selectedUser])

  const handleCheck = (e) => {
    setDelProjects(e.target.checked)
  }

  const handleDelUser = () => {
    const delModal = <div>Are You sure You want to delete the user? This aoperation cannot be undone
      <Form.Item label="Delete only user and leave his projects intact ?"><Checkbox onChange={handleCheck} /></Form.Item>
    </div>
    modalActions.setModalData({
      heading: `Are You sure to delete user ${authActions.selectedUser.userName}`,
      subHeading: "del",
      note: "Delete. This operatin cannot be undone",
      body: (
        delModal
      ),
      buttons: [
        { text: "En otro momento", type: "text", onClick: "close" },
        {
          text: "Delete project",
          type: "danger",
          onClick: () => {adminActions.deleteUser(authActions.selectedUser,delProjects);modalActions.setOpen(false);setEdit(false);}
        }
      ]
    });
    modalActions.setOpen(true);
  }

  return (
    activeUser && activeUser !== null 
      ? 
      <div style={{width:'50%'}}>
      <Form
        form={editUserForm}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal" 
      >
        {edit ? <Form.Item label="User name:" name="name" initialValue={activeUser.userName}><Input/></Form.Item>
        : <Form.Item label="User name:" initialValue={activeUser.userName}>{activeUser.userName}</Form.Item>}
        {edit ? <Form.Item label="User email:" name="email" initialValue={activeUser.userEmail}><Input/></Form.Item>
        : <Form.Item label="User email:">{activeUser.userEmail}</Form.Item>}
        {edit ? <Form.Item label="User phone:" name="phone" initialValue={activeUser.userPhone}><Input/></Form.Item>
        : <Form.Item label="User phone:">{activeUser.userPhone}</Form.Item>}
        {edit ? <Form.Item label="User zipCode:" name="zipCode" initialValue={activeUser.userZip}><Input/></Form.Item>
        : <Form.Item label="User zipCode:">{activeUser.userZip}</Form.Item>}
        {edit ? <Form.Item label="User type:" name="profile_type" initialValue={activeUser.userType}>
          <Select>
            <Select.Option value="personal">Personal</Select.Option>
            <Select.Option value="pro">Pro</Select.Option>
            <Select.Option value="admin">Admin</Select.Option>
          </Select>
        </Form.Item>
        : <Form.Item label="User type:">{activeUser.userType}</Form.Item>}
        {edit ? <Form.Item label="User plan:" name="plan" initialValue={activeUser.userPlan}>
          <Select>
            <Select.Option value="Plan Gratuito">Plan Gratuito</Select.Option>
            <Select.Option value="pro">Pro</Select.Option>
            <Select.Option value="elite">Elite</Select.Option>
          </Select>
        </Form.Item> 
        : <Form.Item label="User plan:">{activeUser.userPlan}</Form.Item>}
        <Button.Group style={{width:'100%',justifyContent:'space-between'}}>
          <Button type="primary" onClick={() => setEdit(!edit)}>{edit ? 'Cancel edit' : 'Edit profile'}</Button>
          {edit && <><Button type="danger" onClick={handleDelUser}>Delete user</Button>
          <Button type="primary" onClick={() => {adminActions.updateUser(activeUser.userId,editUserForm);setEdit(false)}}>Update user</Button></>}
          <Button type="primary" onClick={() => navigate('/miniprojects')}>Show Users projects</Button>
        </Button.Group>
      </Form>
      </div>
      :
    <div>No users</div>
  )
}

export default UserProfile