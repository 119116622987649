import { useEffect, useContext, useState } from 'react';
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import { AiOutlineCheck } from 'react-icons/ai'
import { getThumb } from '../../utils/formatUtils'

const Thumbs = ({styles}) => {
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)

    const [active, setActive] = useState(projectActions.project.defaults.style ?? 0)
    const [activeSubstyle, setActiveSubstyle] = useState(projectActions.project.defaults.substyle ?? 0)
  
    useEffect(() => {
        // console.log('++++',projectActions.project.defaults.style,projectActions.project.defaults.substyle)

        setActive(projectActions.project.defaults.style)
        setActiveSubstyle(projectActions.project.defaults.substyle ?? 0)
    },[sceneActions.refresh])

    const handleChangeSubstyle = (i) => {
        projectActions.setDefault('substyle',i)
        sceneActions.setRefresh(!sceneActions.refresh);
    }

    return (styles[active] && styles[active].substyles.length > 1 ?
        <div className='s-thumbs'>
            {styles[active] && styles[active].substyles.map((thumb,i) => <div key={i}
                onClick={() => handleChangeSubstyle(i)}
                style={{
                    backgroundImage:`url(${process.env.PUBLIC_URL}'/icons/preloader.gif')`,
                    backgroundSize:'100px',
                    backgroundPosition:'center', 
                    backgroundRepeat:'no-repeat'  ,
                    position:'relative',  
                    border:i === activeSubstyle ? '2px solid #007DFF' : '2px solid white'
                    
                }}
                ><div style={{
                    display:'flex',
                    alignItems:'flex-end',
                    gap:'1em',
                    cursor:'pointer',
                    backgroundImage:`url(${process.env.PUBLIC_URL}${getThumb(thumb.url)})`,
                    backgroundSize:'cover',
                    backgroundPosition:'center', 
                    backgroundRepeat:'no-repeat',
                    borderRadius:'.5em',
                    height:'100%',
                }}
            ><div style={{
                padding:'10px 10px',
                background:'linear-gradient(360deg, rgba(21, 19, 19, 0.7) 0%, rgba(21, 19, 19, 0) 100%)',
                width:'100%'
            }}>{thumb.label}</div></div>
                {i === activeSubstyle && <div
                style={{
                    position:'absolute',
                    top:'10px',
                    right:'10px',
                    background:'#007DFF',
                    width:'20px',
                    height:'20px',
                    borderRadius:'10px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                <AiOutlineCheck /></div>}
            </div>)}
        </div>
    : null)
}

export default Thumbs