import { createContext, useContext, useState, useEffect } from 'react';
import { firestore as db, storage } from '../firebase/config';
import { useQuery } from 'react-query'
import { message } from 'antd'
import ProjectContext from './ProjectContext'

const AdminContext = createContext();

let key = 'message-popup'

export const AdminProvider = ({ children }) => {

    const projectActions = useContext(ProjectContext);

    const [selectedUser, setSelectedUser] = useState(null)
    const [loadedUsers,setLoadedUsers] = useState(false)
    const [loadedProjects,setLoadedProjects] = useState(false)
    const [allUsers,setAllUsers] = useState(null)
    const [filteredUsers, setFilteredUsers] = useState([])
    const [filteredProjects, setFilteredProjects] = useState([])
    const [allProjects,setAllProjects] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [userRange, setUserRange] = useState({start:315360000,end:Date.now()})
    const [projectRange, setProjectRange] = useState({start:315360000,end:Date.now()})
    const [documentRange, setDocumentRange] = useState({start:315360000,end:Date.now()})


    // const fetchUsers = async () => {
    //     let allUsers = await getAllUsers();
    //     return allUsers
    //   }

    // const fetchProjects = async () => {
    //     let allProjects = await getAllProjects();
    //     return allProjects
    // }    
    
    // const users = useQuery('users', fetchUsers)
    // const projects = useQuery('projects', fetchProjects)

    useEffect(() => {
        filterUsers();
    },[userRange.start,refresh])

    const getAllProjects = async (orderBy = 'created_At', order = 'desc', userId = null) => {
        setLoadedProjects(false)
        let response = await db.collection("Projects")
            .orderBy(orderBy, order)
            .limit(200)
            .get()
        let projs = []
        response.forEach(proj => {
            // console.log(new Date(proj.data().created_At.seconds * 1000).toLocaleDateString())
            projs.push({
                key: proj.id,
                projectId: proj.id,
                name: proj.data().name ?? '',
                zipCode: proj.data().zipCode ?? '',
                scope: proj.data().scope ?? '',
                overall: proj.data().results && proj.data().results[0] ? proj.data().results[0].overall : '',
                created_At: proj.data().created_At ? new Date(proj.data().created_At.seconds * 1000).toLocaleDateString()  : '',
                userId: proj.data().userId ?? ''
            })
        });
        setAllProjects(projs)
        setLoadedProjects(true)
        return projs;
    }

    const getProjects = async (userId = null, orderBy = 'created_At', order = 'desc') => {
        let response = await db.collection("Projects")
            .where('userId','==',userId)
            .orderBy(orderBy, order)
            .limit(400)
            .get()
        let projs = []
        response.forEach(proj => {
            projs.push({
                active:proj.data().active ?? false,
                key: proj.id,
                projectId: proj.id,
                name: proj.data().name ?? '',
                zipCode: proj.data().zipCode ?? '',
                scope: proj.data().scope ?? '',
                overall: proj.data().results && proj.data().results[0] ? proj.data().results[0].overall : '',
                created_At: proj.data().created_At ? new Date(proj.data().created_At.seconds * 1000).toLocaleDateString()  : '',
                userId: proj.data().userId ?? '',
                results:proj.data().results ?? [],
                access:proj.data().access,
                defaults:proj.data().defaults,
                images:proj.data().images,
            })
        });
        setFilteredProjects(() => projs)
        return projs;
    }

    const getAllUsers = async (orderBy = 'accountCreationDate', order = 'desc') => {
        setLoadedUsers(false)
        let response = await db.collection("Users")
            .orderBy(orderBy, order)
            .get()
        let users = []
        response.forEach((user) => {users.push(
            {
                userId:user.id,
                key:user.id,
                userName: user.data().name ?? '',
                userPhone: user.data().phone ?? '',
                userEmail: user.data().email ?? '',
                userPlan: user.data().plan ?? '',
                userType: user.data().profile_type ?? '',
                userZip: user.data().zipCode ?? '',
                accountCreationDate: user.data().accountCreationDate ?? ''
            })
        });
        // console.log(users.length)
        setAllUsers(users);
        setLoadedUsers(true)
        return users;
    }

    const filterUsers = () => {
        const now = Math.round(Date.now() / 1000);
        // console.log('IN ADMIN FILTER',userRange)
        if(allUsers !== null){
            setFilteredUsers(() => Object.values(allUsers).filter(usr => {
                let test = usr.accountCreationDate.seconds > now - userRange.start;
                return test
            }))              
        }
    }

    const updateUser = (userID, form) => {
        console.log('updating user',userID, form.getFieldsValue())
        try{
            db.collection("Users")
                .doc(userID)
                .update(form.getFieldsValue())
                .then(() => {
                    message.info({content:'¡Guardado!', key})
                    // setPopup(null)
                    // setRefresh(!refresh)
                })

        } catch (err) {
                    console.error('ERR',err)
                    message.error({content:'SAVE ERROR'})
        }
    }

    const filterProjects = () => {
        if(loadedProjects){
            setFilteredProjects(() => allProjects);
        }
    }

    const deleteUsers = (userIds,ifDelProjects) => {
        console.log('DEL USERS',userIds)
        userIds.forEach(userId => deleteUser(userId,ifDelProjects))
    }

    const deleteProjects = (projectIds) => {
        console.log('DEL Project',projectIds)
    }

    const deleteUser = (userId,ifDelProjects) => {

        console.log('ADMN: DEL',ifDelProjects)

        if(ifDelProjects === true){
            getProjects(userId).then(prjs => {
                prjs.forEach((prj,i) => {
                    let resultCounter = prj.results.length
                    prj.results.forEach((result,j) => {
                        if(result.resultImages){
                            let imgCounter = result.resultImages.length;
                                result.resultImages.forEach((imageToDel,k) => {
                                    let fileref = storage.refFromURL(imageToDel.url)
                                    fileref.delete()
                                    .then(() => {
                                        console.log('ADMIN: IMAGE ' + k + imageToDel.name + 'deleted')
                                        imgCounter--;
                                        if(imgCounter === 0){
                                            console.log('ADMIN: All images deleted of result ',j,resultCounter)
                                        }
                                    })
                                })
                        }
                        resultCounter--;
                        if(resultCounter === 0){
                            projectActions.deleteProject(prj.projectId)
                            console.log('ADMIN: delete project ',i,prj.projectId)
                        }
                    })
                })
            })               
        }
            
        db.collection("Users")
            .doc(userId)
            .delete()
            .then((res) => {
                setAllUsers(() => allUsers.filter(user => user.userId !== userId))
                console.log("ADMIN: userId ",userId," deleted!",allUsers.length);
                filterUsers()
            });
    }

    const deleteProject = (projectIds) => {
        console.log('DEL Project',projectIds)
    }

    return (
        <AdminContext.Provider value={{
            loadedUsers,setLoadedUsers,
            loadedProjects,setLoadedProjects,
            userRange, setUserRange,
            projectRange, setProjectRange,
            documentRange, setDocumentRange,
            filterUsers,deleteUser,deleteUsers,
            filterProjects,deleteProject,deleteProjects,
            filteredUsers,setFilteredUsers,
            filteredProjects,setFilteredProjects,
            allUsers,setAllUsers,
            updateUser,deleteUser,
            allProjects,setAllProjects,
            selectedUser,setSelectedUser,
            getProjects,getAllProjects,
            getAllUsers
        }}>{children}</AdminContext.Provider>
    )
}

export default AdminContext;