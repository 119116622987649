import { createContext, useState } from "react";
import { Button, Modal } from "antd";
const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState({
    heading: null,
    subHeading: null,
    body: null,
    note: null,
    buttons: [],

  });

  const toggleModal = () => setOpen((open) => !open);

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };
  const setModalData = (data) => {
    if (!data) return;
    setModal({
      heading: data.heading,
      subHeading: data.subHeading,
      note: data.note,
      body: data.body,
      buttons: data.buttons ? data.buttons : [],
    });
    openModal();
  };
  const getLocalFunction = (name) => {
    switch (name) {
      case "close":
        return closeModal;
      default:
        return () => { };
    }
  };
  return (
    <ModalContext.Provider
      value={{
        open,
        setOpen,
        closeModal,
        toggleModal,
        setModalData,
      }}
    >
      <Modal
        title={modal.heading}
        visible={open}
        centered
        // onOk={modal.buttons.onOk ? modal.buttons.onOk : null}
        onCancel={() => closeModal()}
        footer={modal.buttons.map((button, key) => (
          <Button
            key={key}
            onClick={
              typeof button.onClick === "function"
                ? button.onClick
                : getLocalFunction(button.onClick)
            }
            type={button.type}
          >
            {button.text}
          </Button>
        ))}
      >
        <p>{modal.note}</p>
        {modal.body}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
