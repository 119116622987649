/**
 * All actions allowed by amplitude.
 */
 export const EnumActionsAmplitude = {
    
    /**
     * Interaction Event
     */
    InteractionEvent: 'InteractionEvent',

    /**
     * Page View Event
     */
    PageView: 'PageView'
}