import { getLabelFromId } from "./sceneUtils"
import moment from 'moment'

export const getQuestions = (data) => {

    const allQuestions = {}
    data.categories.map(cat => {
        if(cat.questions){
            cat.questions.forEach(q => {
                if(q.id){
                allQuestions[q.id] = {value:null,open:null,connect:null}
                }
            })
        }
        if(cat.panels){            
            cat.panels.forEach(q => {
                if(q.id){
                allQuestions[q.id] = {value:null,open:null,connect:null}
                }
            })
        }
    })

    return allQuestions
}

export const getCharFromIndex = (index = 0) => {
    const alphabet = ['A','B','C','D','E','F','G','H','I','J']

    console.log(alphabet[index])

    return alphabet[index]
}


export const elementNameToId = (element) => {
    const elements = [
        "house",
        "wall",
        "floor",
        "ceiling",
        "door",
        "door",
        "window",
        "wardrobe",
        "batheq",
        "bathfur",
        "kitcheneq",
        "kitchenfur",
        "heating",
        "energy",
        "energy",
        "electric",
        "kitchencount",
        "item",
        "item",
        "room",
        "cooling",
        "zone",
        "elpanel"
    ]

    const found = elements.indexOf(element)

    return found !== -1 ? found : 0
}

export const elementIdToName = (id_element = 0) => {
    const elements = [
        "house",
        "wall",
        "floor",
        "ceiling",
        "door",
        "door",
        "window",
        "wardrobe",
        "batheq",
        "bathfur",
        "kitcheneq",
        "kitchenfur",
        "heating",
        "energy",
        "energy",
        "electric",
        "kitchencount",
        "item",
        "item",
        "room",
        "cooling",
        "zone",
        "elpanel"
    ]

    return elements[id_element]
}

export const getFeatureColumn = (elementsData,featureName,item) => {
    let itemData = null;

    switch (item.id_element){
        case 1:
        case 2:
            itemData = elementsData.elements[item.id_element]
        break;
        default:
            itemData = elementsData.items.find(it => item.features[1] === it.type)
    }

    const feature = itemData ? itemData.properties.find(prop => prop.name === featureName) : null

    return feature ? feature.feature : null
}

export const getFeatureLabel = (elementsData,featureName,item) => {
    let itemData = null;
    switch (item.id_element){
        case 1:
            itemData = elementsData.elements[item.id_element]
        break;
        case 3:
            itemData = elementsData.elements[item.id_element]
        break;
        default:
            itemData = elementsData.items.find(it => item.features[1] === it.type)
    }
    const feature = itemData.properties.find(prop => prop.name === featureName)
    return feature ? feature.feature : null
}

export const getPropertyLabel = (elementsData,featureName,item) => {

    let itemData = null;
    switch (item.id_element){
        case 1:
            itemData = elementsData.elements[item.id_element]
        break;
        default:
            itemData = elementsData.items.find(it => item.features[1] === it.type)
    }
    const property = itemData ? itemData.properties.find(prop => prop.name === featureName) : null
    const value = property ? property.values.find(v => v.value === parseInt(item.features[property.feature])) : null

    // console.log('inside label',property.feature,itemData)

    return value ? value : null
}

export const getItemLabel = (elementsData,item,column) => { // unfinished
    let itemData = null;
    switch (item.id_element){
        case 1:
            if(Array.isArray(item)){
                // console.log(items.every(item => items[0].features[column] === getFeatureValue(item,column)))
                //   if(items.every(item => item[0].features_a[column] === getFeatureValue(item,column))){
                //     return item[0].features_a[column]
                //   }
            } else {
                itemData = elementsData.elements[item.id_element]
            }
        break;
        default:
            itemData = elementsData.items.find(it => item.features[1] === it.type)
    }



    return itemData ? itemData.label : null
}


export const getFeatureValue = (item,column) => {
    if(item.type === 'wall'){
      return item.features_a[parseInt(column)]
    } else {
      return item.features[parseInt(column)]    
    }
} 

export const getElementValueLabel = (elementData,id_element,f,v) => {

    const itemData = elementData.elements.find(el => el.id_element === id_element)
    const property = itemData ? itemData.properties.find(property => property.feature === f) : null
    const value = property ? property.values.find(val => val.value === v) : null

    return value ? value.label : null
}

export const getItemValueLabel = (elementsData,item,column) => {

    let srcData = elementsData.items

    switch (item.id_element){
        case 1:
            srcData = elementsData.elements
        break;
        case 4:
        case 5:
        case 6:
            srcData = elementsData.holes
        break;
        default:
            srcData = elementsData.items
    }

    const oldValue = item ? getFeatureValue(item,17) : null;  
    const itemData = srcData.find(it => it.type == item.features[1])
    const items = itemData ? itemData.properties.find(prop => prop.feature === parseInt(column)) : null
        
    const found = items !== null ? items.values.find(val => val.value == oldValue) : null

    return found ? found.label : 'Escoge el modelo'
}

export const getItemValueSuper = (elementsData,item,column) => {

    let srcData = elementsData.items

    switch (item.id_element){
        case 1:
            srcData = elementsData.elements
        break;
        case 4:
        case 5:
        case 6:
            srcData = elementsData.holes
        break;
        default:
    }

    const oldValue = item ? getFeatureValue(item,17) : null;  
    const itemData = srcData.find(it => it.typ == item.features[1])
    const values = itemData ? itemData.properties.find(prop => prop.feature === parseInt(column)).values : null

    const found = values !== null ? values.find(val => val.value === oldValue) : undefined

    return found ? found.superscript : null    
}

export const currencyFormat = (
    number = 0,
    type = "de-DE",
    options = { style: "currency", currency: "EUR" }
  ) => Intl.NumberFormat(type, options).format(number);
  

export const formatDefaults = (defaults) => {

    console.log('--------formating',defaults)

    const tempDefaults = {
            city:defaults.city,
            homeArea:defaults.homeArea,
            houseShape:defaults.houseShape,
            houseType: defaults.houseType,
            plantsNum: defaults.plantsNum,
            reformAge: defaults.reformAge,
            reformDate: null,
            style: defaults.style
    }
    
    return tempDefaults
}

export const convertScene = (scene) => {

    let tempLayers = {};
    
    scene.layers.forEach((layer,i) => {

        // console.log('inside layer',...layer[Object.keys(layer)].lines)

        let tempLines = {};
        let tempAreas = {};
        let tempItems = {};
        
        layer[Object.keys(layer)].lines.forEach((line,j) => {
            tempLines = {...tempLines,[line.id]:{
                id:line.id,
                external:false,
                holes:line.holes,
                id_element:line.id_element,
                prototype: "lines"
            }}
        })

        layer[Object.keys(layer)].areas.forEach((area,j) => {
            tempAreas = {...tempAreas,[area.id]:{
                id:area.id,
                holes:area.holes,
                id_element:area.id_element,
                prototype: "areas"
            }}
        })        

        layer[Object.keys(layer)].items.forEach((item,j) => {
            tempItems = {...tempItems,[item.id]:{
                id:item.id,
                external:false,
                holes:item.holes,
                id_element:item.id_element,
                prototype: "items"
            }}
        })        

        layer = {
            id:'layer-1',
            altitude:0,
            holes:layer[Object.keys(layer)].holes,
            areas:tempAreas,
            lines:tempLines,
            items:tempItems,
            selected:{
                areas:[],
                holes:[],
                items:[],
                lines:[],
                vertices:[]
            },
            visible:true
        }

        tempLayers = {...tempLayers,["layer-" + (i + 1)]:layer}
    })   

    const tempScene = {
        grids:{
            h1:{
                id:'h1',
                properties:{
                    colors:["#808080","#ddd","#ddd","#ddd","#ddd"],
                    step:20
                },
                type:'horizontal-streak'
            },
            v1:{                
                id:'v1',
                properties:{
                    colors:["#808080","#ddd","#ddd","#ddd","#ddd"],
                    step:20
                },                
                type:'vertical-streak'
            }
        },
        groups:{},
        guides:{
            circular:{},
            horizontal:{},
            vertical:{}
        },
        height:8000,
        width:8000,
        layers:{
            layers:tempLayers,
            altitude:0,
        },
        meta:{},
        selectedLayer:'layer-1',
        snappingStatus: true,
        unit:'cm'
    }

    console.log('format scene',tempLayers) 



    return null
}

export const getThumb = (filename) => {
    const modified = filename.substring(0, filename.lastIndexOf(".")) + "_t" + filename.substring(filename.lastIndexOf("."));
    return filename ? modified : "";
}

export const formatTable = (scene) => {
    console.log('format table',scene) 
    
    return null
}

export const formatResults = (results) => {
    console.log('format',results) 

    return null
}

export const formatDate = (date) => {
    let tempDate = moment(date).format('DD/MM/YYYY') ?? moment('2022-11-11').format('DD/MM/YYYY')
    // return tempDate.replaceAll('/','.')
    return tempDate
}

export const checkZipCode = (code,citiesData) => {

    console.log('IN CHECK',(citiesData.included.length > 0 && citiesData.included.includes(code)) 
    , (citiesData.excluded.length > 0 && !citiesData.excluded.includes(code))
    , (citiesData.startsWith.length > 0 && !citiesData.startsWith.some(start => code.startsWith(start))))

    return (citiesData.included.length > 0 && citiesData.included.includes(code)) 
        || (citiesData.excluded.length > 0 && !citiesData.excluded.includes(code))
        || (citiesData.startsWith.length > 0 && !citiesData.startsWith.some(start => code.startsWith(start)))
}

export const prepareLayerForTable = (layer) => {

    console.log('LAYER',layer['layer_1'])
    // layer = layer['layer_1'].map((l,i) => {
    //     l.key = i;
    //     return l
    // })

    return layer['layer_1']
}