import React from 'react'
import { AuthProvider } from './context/AuthContext';
import ReactDOM from 'react-dom/client';
import Homyplan from './components/Homyplan';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Homyplan />
    </AuthProvider>
  </React.StrictMode>
);

