import { useState, useEffect, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import QnrContext from '../../context/QnrContext'
import { checkValueFilter, getElementData, getElementOfId, getFeatureValue, getGroupFeatureValue } from '../../utils/sceneUtils'
import Thumb from './Thumb'

const ModelSelector = ({option, localStyle, localSubstyle, action, material, itemId, groupId}) => {

    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const qnrActions = useContext(QnrContext)

    const [elementData, setElementData] = useState(getElementData(projectActions.elementsData,option.id_element))
    const [propertyData, setPropertyData] = useState(null)
    const [activeLayer, setActiveLayer] = useState(null)
    const [item, setItem] = useState(null)
    const [group, setGroup] = useState(null)
    const [oldValue, setOldValue] = useState(null)

    useEffect(() => {
        const layer = projectActions.scenes[1].layers[0].layer_1
        setActiveLayer(layer)

        if(layer.groups && groupId){
            const foundGroup = layer.groups.find(group => group.id === groupId);
            setGroup(() => foundGroup)
            setOldValue(getGroupFeatureValue(layer,foundGroup.id,17))
        }

        if(option.id_element === 2){
            setItem(projectActions.scenes[1].layers[0].layer_1.areas[0].floor)
        } else if(itemId){
            setItem(getElementOfId(projectActions.scenes[1].layers[0].layer_1,null,itemId))
        }
        if(item){
            setOldValue(getFeatureValue(item,17))
        }
    },[])

    useEffect(() => {
        const data = getElementData(projectActions.elementsData,parseInt(option.id_element))
        setElementData(() => data)
        setPropertyData(() => data ? data.properties.find(property => property.name === option.property) : null)
        // console.log('REFRESHING S:',localStyle,item,group)
    },[projectActions.project.defaults.style,projectActions.project.defaults.substyle,localStyle,localSubstyle,projectActions.refresh])

    const handleAction = (value) => {
        console.log(value)
        if(group){
            action(value,option.id_element,group.id)
            setOldValue(value)
        } else if (item){
            action(value,option.id_element)
            setOldValue(value)
            
        }
    }

    let count = 0;

    return <div>
        GS:{projectActions.project.defaults.style} LS:{localStyle}:{localSubstyle}_M:{material}_V:{oldValue} eID:{option.id_element} v: {qnrActions.activeVariant}  {itemId}
        {propertyData && <div className="thumbs">
            {propertyData && propertyData.values.filter((value,i) => {
                const materialFilter = [{"f":3,"v":[material]}]
                const styleFilter = [{"f":23,"v":[qnrActions.activeVariant === 0 ? projectActions.project.defaults.style : localStyle]}]
                const testFilters = checkValueFilter(value,[...materialFilter,...styleFilter])
                if(testFilters === true){
                    count++;
                    {/* console.log(count,'DISPLAY GS:',projectActions.project.defaults.style,' LS:',localStyle,' V:',value.value,value.label,value.filter,styleFilter) */}
                }             
                return testFilters
            }).map((val,i) => {
                return <Thumb key={i} value={val} oldValue={oldValue} action={() => handleAction(val.value,option.id_element)} shape={'circle'}/>
            })}
        </div>}
    </div>
}

export default ModelSelector