import { useState, useEffect } from 'react';

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

  const getHeight = () => window.innerHeight 
  || document.documentElement.clientHeight 
  || document.body.clientHeight;

export const useDimension = () => {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());
    let [height, setHeight] = useState(getHeight());
    //   let [height, setHeight] = useState(getHeight());
    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutW = null;
        let timeoutH = null;
        const resizeListener = () => {
          // prevent execution of previous setTimeout
          clearTimeout(timeoutW);
          // change width from the state object after 150 milliseconds
          timeoutW = setTimeout(() => setWidth(getWidth()), 150);
          clearTimeout(timeoutH);
          // change width from the state object after 150 milliseconds
          timeoutH = setTimeout(() => setHeight(getHeight()), 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);
        // clean up function
        // return () => {
        // // remove resize listener
        // window.removeEventListener('resize', resizeListener);
        // }
    }, [])

    return {width:width,height:height};
}