import React, {useEffect, useState} from "react";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import {Button} from "antd";
import {currencyFormat} from "../../../utils/formatUtils";
import {AiOutlineCloudDownload} from "react-icons/ai";

const ExcelExportBudget = ({jobs}) => {

    // console.log('in export',jobs)

    const [dataBudget, setDataBudget] = useState('');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'Homyplan-Budget';

    const exportExcel = () => {
        const ws = XLSX.utils.table_to_sheet(dataBudget);
        const wb = { Sheets: {budget: ws}, SheetNames: ['budget']};
        const excelBuffer =  XLSX.write(wb, { bookType: 'xlsx', type: 'array'})
        const file = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(file, fileName + fileExtension)
    }

    useEffect(() => {
        const e = document.createElement('table')
        // ${currencyFormat(chapter.jobs.work.amountTotal.toFixed(2))}
        e.innerHTML = `
            <tr>
                <td>Fases de la reforma</td>
                <td></td>
                <td></td>
                <td>Importe</td>
            </tr>
            <tr></tr>
            ${jobs.jobs.map((chapter) => (`
                <tr>
                    <td>${chapter.name_es}</td>
                    <td></td>
                    <td></td>
                    <td>${currencyFormat(chapter.amountTotal.toFixed(2))}</td>
                </tr>
                <tr></tr>
                <tr>
                    <td>Trabajo</td>
                    <td>Medida</td>
                    <td>Precio</td>
                    <td>Importe</td>
                </tr>
                <tr align="center">
                    <td align="center" style="text-align: center" colspan="4">Obra</td>
                </tr>
                ${
                    chapter.jobs.work.data.map((job) => (`
                        <tr>
                            <td>${job.name}</td>
                            <td>
                                ${job.measurement.measurement.toFixed(2) + ' ' + job.measurement.unit}
                            </td>
                            <td>${currencyFormat(job.price.toFixed(2))}</td>
                            <td>${currencyFormat(job.amountTotal.toFixed(2))}</td>
                        </tr>
                    `))
                }
                <tr>
                    <td colspan="4">Acabados</td>
                </tr>
                ${
                    chapter.jobs.detail.data.map((job) => (`
                        <tr>
                            <td>${job.name}</td>
                            <td>
                                ${job.measurement.measurement.toFixed(2) + ' ' + job.measurement.unit}
                            </td>
                            <td>${currencyFormat(job.price.toFixed(2))}</td>
                            <td>${currencyFormat(job.amountTotal.toFixed(2))}</td>
                        </tr>
                    `))
                }
                <tr></tr>
                <tr></tr>
                <tr></tr>
            `))
        }`;
        setDataBudget(e)
    }, [jobs])

    return (
        <Button
            shape="round"
            type="primary"
            onClick={() => {exportExcel()}}
        >
            XLS
        </Button>
    )
}

export default ExcelExportBudget;
