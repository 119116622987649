import React, { Fragment, useEffect, useContext } from 'react'
import QnrContext from '../../context/QnrContext'

const Stepper = ({categories, user}) => {

const { activeQ } = useContext(QnrContext)

let bullet = 0;

  return (
    <div className={user ? "stepper shifted" : "stepper"}>
            {categories.map((cat,i) => {
                if(cat.bullet){bullet++}
                return <Fragment key={i}>
                    {cat.bullet && <div className={i <= activeQ ? 'step-bullet dark' : 'step-bullet'}>
                        {bullet}
                        <div className='step-bullet-label'>{cat.bullet}</div>
                    </div>}
                    <div className={`step-dash ${i === activeQ && 'active'} ${i <= activeQ && 'dark'}`}></div>
                </Fragment>
            }
        )}
    </div>
  )
}

export default Stepper