import {toFixedFloat, fAbs} from './math.js';
import {EPSILON} from './constants';

export function getOffset(ctx, vertices) {
  if(vertices.length > 0){
    const minX = vertices.map(v => v.x).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxX = vertices.map(v => v.x).reduce((acc, val) => {
        return acc > val ? acc : val
    })
    const minY = vertices.map(v => v.y).reduce((acc, val) => {
        return acc < val ? acc : val
    })
    const maxY = vertices.map(v => v.y).reduce((acc, val) => {
        return acc > val ? acc : val
    })

    const width = ctx.canvas.width;
    const height = ctx.canvas.height;

    const offsetX = ((width - pointsDistance(minX, 0, maxX, 0) * 100) / 2) - minX * 100
    const offsetY = ((height - pointsDistance(0, minY, 0, maxY) * 100) / 2) - minY * 100

    return {
        x: offsetX,
        y: offsetY
    }    
  } else {
    return {
        x: 0,
        y: 0
    }        
  }
}

export function pointsDistance(x0, y0, x1, y1) {
    let diff_x = x0 - x1;
    let diff_y = y0 - y1;

    return Math.sqrt((diff_x * diff_x) + (diff_y * diff_y));
}

export function verticesDistance(v1, v2) {

    // console.log('measure',v1,v2)

    let { x: x0,y: y0 } = v1;
    let { x: x1,y: y1 } = v2;

    return pointsDistance(x0, y0, x1, y1);
}

export function getPolygonCentroid(points){
    var centroid = {x: 0, y: 0};
    for(var i = 0; i < points.length; i++) {
       var point = points[i];
       centroid.x += point.x;
       centroid.y += point.y;
    }
    centroid.x /= points.length;
    centroid.y /= points.length;
    return centroid;
  }

export function midPoint( x1, y1, x2, y2 ) {
    return { x: (x1+x2)/2, y: (y1+y2)/2 };
  }

export function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = {tl: radius, tr: radius, br: radius, bl: radius};
  } else {
    var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }

  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }

}


export function angleBetweenTwoPointsAndOrigin(x1, y1, x2, y2) {
  return -(Math.atan2(y1 - y2, x2 - x1)) * 180 / Math.PI;
}

export function angleBetweenTwoPoints(x1, y1, x2, y2) {
  return Math.atan2(y2 - y1, x2 - x1);
}

export function absAngleBetweenTwoPoints(x1, y1, x2, y2) {
  return Math.atan2(Math.abs(y2 - y1), Math.abs(x2 - x1));
}

export function samePoints({x: x1, y: y1}, {x: x2, y: y2}) {
  return fAbs(x1 - x2) <= EPSILON && fAbs(y1 - y2) <= EPSILON;
}

export function extendLine(x1, y1, x2, y2, newDistance, precision = 6) {
  let rad = angleBetweenTwoPoints( x1, y1, x2, y2 );

  return {
    x: toFixedFloat(x1 + (Math.cos(rad) * newDistance), precision),
    y: toFixedFloat(y1 + (Math.sin(rad) * newDistance), precision),
  };
}

export function roundVertex(vertex, precision = 6) {
  vertex.set('x', toFixedFloat(vertex.get('x'), precision));
  vertex.set('y', toFixedFloat(vertex.get('y'), precision));

  return vertex;
}