import { useEffect, useState, useRef, useContext } from 'react'
// import PuffLoader from "react-spinners/PuffLoader";
import ProjectContext from '../../context/ProjectContext'
import { motion, useAnimation, useDragControls, useMotionValue, useTransform } from 'framer-motion'
import Check from './Check'
// import Loader from '../../assets/ui/preloader.gif'
import { getElementsFromLayer } from '../../utils/sceneUtils'
import { getCharFromIndex } from '../../utils/formatUtils'
import { BsCheck2, BsPlus, BsX, BsArrowRight, BsArrowLeft} from 'react-icons/bs'
import {Image} from "antd";
import { useDimension } from '../../hooks/useDimensions'

const Slider = ({q, action}) => {
  
  const {width} = useDimension();
  // const controls = useAnimation()
  const x = useMotionValue(0);
  const carousel = useRef();

  const projectActions = useContext(ProjectContext)

  const [carouselWidth, setCarouselWidth] = useState(q.options.length * 515)
  const [limitX, setLimitX] = useState(4000)
  const [over, setOver] = useState(null)
  const [moved, setMoved] = useState(false)
  const [featured, setFeatured] = useState([])
  const [elements,setElements] = useState([])
  const [active,setActive] = useState(projectActions.project.defaults.style)
  const [dragging,setDragging] = useState(false)
  const [visible, setVisible] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [count, setCount] = useState(0)

  useEffect(() => {
    setActive(() => projectActions.project.defaults.style)
    console.log(projectActions.project.defaults.style,active, active * 515)
    setCount(() => projectActions.project.defaults.style)
  },[projectActions.project.defaults])

  useEffect(() => {        
    setRefresh(() => !refresh)
  },[width])

  useEffect(() => {
    if(width > 1000){
      setLimitX(-(carouselWidth - 700))
    } else {
      setLimitX(-(carouselWidth - width))
    }
  },[refresh])

  // const zoomImg = () => {
  //   console.log('ZOOM')
  // }

  // const handleClick = (e) => {
  //   console.log('click')
  //   if(dragging === false){
  //       action(e)
  //   }
  // }

  // const handleMove = (e) => {
    // e.preventDefault();
    // setMoved(true);
    
    
    // let timeout;
    // (() => {
    //   clearTimeout(timeout);
    //   timeout = setTimeout(() => setMoved(false), 100);
    // })();
    // console.log('move',moved)
  // }

//   const handleDragStart = (e,info) => {
//     setRefresh(() => !refresh)
//     // controls.start('START')
//     console.log('START')
//     setDragging(() => true)
// }

// const handleDrag = () => {
//   // console.log('Drag')
// }

// const handleDragEnd = () => {
//   setRefresh(() => !refresh)
//     setDragging(() => false)
//     // x.set(100)
//     // console.log('Drag End')
//   }

//   const handleWhileDrag = (e,info) => {
//     console.log('While Drag',info)
//     setDragging(() => true)

//   }

//   const handlePan = (e,info) => {
//     console.log('Panning',info.offset,x.get())
//     // setDragging(() => true)
//     setRefresh(() => !refresh)
//   }

//   const handleVis = (i) => {
//     console.log('show Vis')
//     setVisible(() => true)
//   }

  const handleSlide = (how) => {
    console.log(how);
    switch(how){
      case 'left':
        setCount(() => count > 0 ? count - 1 : 0)
      break;
      case 'right':
        setCount(() => count < projectActions.styles.length ? count + 1 : projectActions.styles.length)
      break;
    }
  }

  return (
    <motion.div ref={carousel} 
      // onMouseMove={handleMove} 
      className="q-slider h-full p-4 flex flex-col justify-center"
      style={{
        width:`100%`,
        // overflow:'hidden',
      }}>
        <h4 style={{textAlign:'right',color:'#04073F',opacity:0.5}} className="wideonly">
        {/* {active}{width} */}
        {/*  {carouselWidth} {limitX} {width > 1000 ? -(carouselWidth - 700) : -(carouselWidth - width + 30)}  */}
        {count > 0 && <BsArrowLeft size={30} style={{cursor:'pointer'}} onClick={() => handleSlide('left')} />}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {count < projectActions.styles.length - 1 && <BsArrowRight size={30} style={{cursor:'pointer'}} onClick={() => handleSlide('right')}/>}
        </h4>
      <motion.div 
        drag="x"
        dragListener={width > 800 ? true : false}
        className='s-slider'
        animate={{x:width > 800 ? -(count * 515) : 0}}
        transition={{ type: "ease" }}
        // dragControls={controls}
        // dragDirectionLock
        // dragMomentum={false}
        // onDrag={handleDrag}
        dragConstraints={{ right:0, left:limitX}} 
        // onDragStart={handleDragStart}
        // onDragEnd={handleDragEnd}
        // whileDrag={(e,info) => handleWhileDrag(e,info)}
        // onPan={(e,info) => handlePan(e,info)}
        // className="flex w-full justify-end gap-4 pb-4"
        style={{
            display:'flex',
            gap:'1em',
            // paddingRight:'2000px',
            // width:carouselWidth + 1000 + 'px',
        }}
      >
      {q.options && q.options.map((o,i) => {
        const cardChck = active === i;
        const cardCss = {
            background:o.color ? o.color : 'gray'
        }
        return <label key={i}>
        <div className="s-img"
          style={{...cardCss, 
            backgroundColor:'white',
            backgroundImage:'url(' + process.env.PUBLIC_URL + projectActions.styles[i].substyles[0].url + ')',
            backgroundPosition:'center',
            backgroundSize:'cover',
            backgroundRepeat:'no-repeat',
            borderRadius:'1em',
            position:'relative',
            cursor:'pointer',
            display:'flex',
            alignItems:'flex-end',
            justifyAlign:'flex-start',
            padding:'1em',
            border:`2px solid ${cardChck === true ? '#007DFF' : '#CCCCCC'}`
          }}>
          {cardChck === true && <Check />}
          </div>
          <div style={{margin:'1em',position:'relative'}}>
            {o.label && <div className="q-cardtitle" dangerouslySetInnerHTML={{__html: projectActions.styles[i].label}} />}
            {o.description && <div className="q-description" dangerouslySetInnerHTML={{__html:o.description}} />}
              {/* <span 
                className='w-link'
                style={{
                    position:'absolute',
                    top:0,right:0
                }}
                onClick={handleVis}
              >
                Ver detalle&nbsp;&nbsp;&nbsp;<BsArrowRight size={20}/> // LINK TEMPORARLY 
            </span> */} 
          </div>
          <input type="radio" name={q.id} value={i}
            data-oid={o.id}
            data-link={q.link}
            onChange={action}
            checked={cardChck}
            className="debug"                    
          />
          </label>
      })}
      {/* <Image.PreviewGroup style={{display: 'none'}} preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
          {projectActions.styles[active ?? 0].substyles.map((img,i) => <Image style={{display: 'none'}} key={i} src={img.url}/>)}
      </Image.PreviewGroup> */}
      </motion.div>
    </motion.div>
  )
}

export default Slider