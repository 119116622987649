import React, { useRef, useEffect, useState, useContext } from 'react'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import { COLORS } from '../../utils/constants'
import { checkInGroup } from '../../utils/sceneUtils'

const GroupSelector = ({q}) => {
  const projectActions = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)

  const colors = COLORS.planner;

  const labels = ["A","B","C","D","E","F","G","H"];

  const [elements,setElements] = useState(projectActions.scenes[0].layers[0].layer_1.lines)
  const [groups,setGroups] = useState(projectActions.scenes[0].layers[0].layer_1.groups)

  useEffect(() => {
    setGroups(() => projectActions.scenes[0].layers[0].layer_1.groups);
    console.log('ingroups',projectActions.scenes[0].layers[0].layer_1)
  },[sceneActions.refresh])

  const handleHighlight = (el) => {
    sceneActions.setActive(el.id)
  }

  const handleAddToGroup = (el,option) => {
    // sceneActions.addToGroup(null,el.id,option.groupId,option.name);
    sceneActions.setRefresh(!sceneActions.refresh);
  }

  const handleToggleGroup = (el,option) => {
    sceneActions.toggleBetweenGroups(null,el.id,option.groupId,option.group1,option.group2);
    // sceneActions.addToGroup(null,el.id,option.groupId,option.name);
    sceneActions.setRefresh(!sceneActions.refresh);
  }

  return q.options.map((option,i) => <div key={i} className="group-labels">
    {elements ? elements.map((el,j) => <div key={j} className={checkInGroup(el.id,option.groupId,groups) ? 'big-label selected' : 'big-label'} 
      onMouseLeave={() => sceneActions.setActive(null)} 
      onMouseOver={() => handleHighlight(el)} 
      onClick={() => handleToggleGroup(el,option)}
    >{labels[j]}</div>) : 'Loading...'}
  </div>)
}

export default GroupSelector