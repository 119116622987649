import {v4 as uuid } from 'uuid'
import {elementIdToName} from './formatUtils'

export const createElement = (name = 'Element', label = 'Element', id_element = 8, type='', features, measurements = null,position,id = null,decisions = null) => {

  // console.log(features)

    const newElement = {
        id:id ? id : uuid().slice(0,8),
        name:name,
        label:label,
        id_element:parseInt(id_element),
        type:type,
        features:features,
    }

    if(measurements !== null){
      newElement.measurements = measurements
    }

    if(decisions !== null){

      console.log('in create',decisions)

      newElement.decisions = decisions
    }

    return newElement
}

export const createGroup = (groupId = null, items=[], name = 'group', label = 'Group', type = 'wallGroup') => {
  const newGroup = {
    id:groupId ? groupId : uuid().slice(0,8),
    name:name,
    label:label,
    type:type,
    items:[...items]
  }

  return newGroup
}

export const addElementToLayer = (layer, areaId, element, parent) => {
  switch(element.id_element){
    case 1:
      layer.lines = [...layer.lines, element]
    break;
    case 4:
    case 5:
    case 6:
      layer.holes = [...layer.holes, element]
    break;
    default:
      layer.items = [...layer.items, element]
  }

  return layer
}

export const removeElementFromLayer = (layer, areaId, id_element, itemID, parent) => {
  switch(id_element){
    case 1:
      layer.lines = [...layer.lines.filter(element => itemID ? element.id !== itemID : element.id_element !== id_element)]
    break;
    case 4:
    case 5:
    case 6:
      layer.holes = [...layer.holes.filter(element => itemID ? element.id !== itemID : element.id_element !== id_element)]
    break;
    default:
      layer.items = [...layer.items.filter(element => itemID ? element.id !== itemID : element.id_element !== id_element)]
  }

  if(!id_element && !itemID){
    layer.items = []
  }

  return layer
}

export const updateElement = (layer, element) => {

  // console.log('in update',layer)

  switch(element.id_element){
    case 1:
      layer.lines = [...layer.lines.forEach(el => el.id === element.id ? el = element : el)]
    break;
    case 4:
    case 5:
    case 6:
      layer.holes = [...layer.holes.forEach(el => el.id === element.id ? el = element : el)]
    break;
    default:
      layer.items = [...layer.items.forEach(el => el.id === element.id ? el = element : el)]
  }
  return layer
} 

export const getElementsFromLayer = (layer, id_element) => {

  let elements = []

  if(layer){
    switch(id_element){
      case 1:
        elements = layer.lines;
      break;
      case 2:
        elements = [layer.areas[0].floor];
      break;
      case 3:
        elements = [layer.areas[0].ceiling];
      break;
      case 4:
      case 5:
      case 6:
        elements = layer.holes;
      break;
      default:
        elements = layer.items;
    }    
  }  

  return elements
}

export const getItem = (layer,id_element,typology,filter=[],id) => {

  const elements = getElementsFromLayer(layer,id_element)

  return elements.find(item => {
    let testID = true;
    if(id_element){
      testID = item.id_element == id_element;
    }
    let testTypo = true
    if(typology){
      testTypo = item.features[1] == typology;
    }
    let testFilter = true
    if(filter && filter.length > 0){
      testFilter = filter.some(fil => item.features[fil.f] === fil.v)
    }
    let idFilter = true
    if(id){
      idFilter = item.id === id
    }

    return testID === true && testTypo === true && testFilter === true && idFilter === true;
  })
}

export const getFilteredElements = (layer,areaId,id_element,typology=null,filter=[],id=null) => { // todo filter

  let elements = getElementsFromLayer(layer,id_element)

  elements.filter(item => {
    let testTypo = true
    if(typology){
      testTypo = item.features[1] == typology;
    }
    let testFilter = true
    if(filter && filter.length > 0){
      testFilter = filter.some(fil => item.features[fil.f] === fil.v)
    }
    let testId = true
    if(id){
      testId = item.id === id
    }

    return testTypo === true && testFilter === true && testId === true;
  })

  return elements.length > 0 ? elements : null
}

export const filterElement = (element, typology, id_element = null, filter = []) => {

  let testIdElement = true;
  if(id_element){
    testIdElement = element.id_element === id_element;
  }

  let testTypo = true;
  if(typology){
    testTypo = element.features[1] === typology;
  }

  let testFilter = true
  if(filter && filter.length > 0){
    testFilter = filter.some(fil => element.features[fil.f] === fil.v)
  }

  console.log('----TEST',element.name,element.features,testIdElement, testTypo, testFilter, filter)

  return (testIdElement === true && testTypo === true && testFilter === true); 
}

export const getElementData = (elementData,id_element,typology) => {
  let elements = []

  switch(id_element){
    case 0:
    case 1:
    case 2:
    case 3:
      elements = elementData.elements;
    break;
    case 4:
    case 5:
    case 6:
      elements = elementData.holes;
    break;
    default:
      elements = elementData.items;
  }

  return elements.find(element => {
    const typoTest = typology ? element.type === typology : true
    return element.id_element === id_element && typoTest === true 
  })
}

export const checkValueFilter = (value,filters = []) => {
  let testFilter = []
  if(filters.length > 0 && value.filter && value.filter.length > 0){
      value.filter.forEach(filt => {
        const foundFilter = filters.find(fl => fl.f === filt.f)
        if(foundFilter){
          
          testFilter.push(filt.v.some(f => foundFilter.v.includes(f)))
        } else {
          testFilter.push(true)
        }
      })
    }
    // console.log('===',testFilter.length === 0,testFilter.every(test => test === true))
  return testFilter.length === 0 || testFilter.every(test => test === true)
}

  export const checkFilterItem = (item,filters = []) => {
  let testFilter = true
  if(filters.length > 0){
    testFilter = filters.every(fil => item.features[fil.f] == parseInt(fil.v))
  }
  return testFilter
}

export const filteredItems = (items,filters = []) => {
  let filtered = items;
  if(filters.length > 0){
    filtered = items.filter(item => {
      const tests = []
      filters.forEach(filter => {
        tests.push(item.features[filter.f] == parseInt(filter.v))
      })
      return tests.every(t => t === true)
    })
  }   
  return filtered
}

export const filteredSomeItems = (items,filters = []) => {
  let filtered = items.filter(item => {

    const tests = []

    filters.forEach(filter => {
      tests.push(item.features[filter.f] === filter.v)
    })

    return tests.some(t => t === true)
  })
    
  return filtered
}

export const getOldFromCalcData = (calcData,id_element,element) => {

  const olds = [,,,,76,,,,117,120,132,136,145,152,160,175,,,,145,]

  const idEl = element ? element.id_element : id_element
  // const calcElement = calcData.elements.find(
  //   el => el.id_element === (element ? element.id_element : id_element))
  
  //  console.log('searching for old',idEl,olds[idEl],'----',calcData.elements[idEl].old)
    
  // return calcElement.old;
  return olds[idEl];
} 

export const getNewFromCalcData = (calcData,id_element,element) => {
  const calcElement = calcData.elements.find(
    el => el.id_element === (element ? element.id_element : id_element))

  return calcElement ? calcElement.new : null;
} 

export const getElementOfId = (layer,areaId=null,id) => {

  let found = []

  if(layer){
    found = [
      layer.areas.find(area => area.id === id),
      layer.groups.find(group => group.id === id),
      layer.lines.find(line => line.id === id),
      layer.holes.find(hole => hole.id === id),
      layer.items.find(item => item.id === id)
    ]    
  }

  console.log('FOUND',id,found)

  return found.find(el => el !== undefined)
}

export const getIdfromSelected = (layer, selected) => {
  let tempSelected = null
  
  if(layer !== null && selected !== null){
    layer.lines.forEach((line,i) => {
      if(parseInt(selected.split('_')[1]) === i){
        tempSelected = line.id
      }
    })
  }
  return tempSelected
}

export const getNameFromId = (layer,id,areaId) => {

  // console.log('in layer',layer,id) 

  return 'Pared ' + id.split('_')[1]
}

export const getLabelFromId = (layer,id,areaId) => {

  // console.log('in layer',layer,id) 

  return 'Pared ' + id.split('_')[1]
}

export const getElements = (layer,areaId,id_element,filter) => {

  let tempElements = []

  switch(id_element){
    case 1:
      tempElements = layer.lines
    break;
    case 2:
      tempElements = layer.areas[0].floor
    break;
    case 3:
      tempElements = layer.areas[0].ceiling
    break;
    case 4:
    case 5:
    case 6:
      tempElements = layer.holes
    default:
      tempElements = layer.items
    break;
  }

  // console.log(filteredItems(tempElements,filter))

  return filteredItems(tempElements,filter)
}

export const getFirstElement = (layer,areaId=null,id_element=null,filter=null) => {
  
  const elems = getElements(layer,areaId,id_element,filter) 
  
  // console.log('elements found',elems)  

  return elems.length > 0 ? elems[0] : null
}

export const getAllValues = (items,column,feature) => {

  if(Array.isArray(items)){
    // console.log(items.every(item => items[0].features[column] === getFeatureValue(item,column)))
    switch(items[0].type){
      case "wall":
        if(items.every(item => items[0].features_a[column] === getFeatureValue(item,column))){
          return items[0].features_a[column]
        }
      break;
      default:

    }
    // console.log(items[0])

    // return getFeatureValue(items[0],column)
  } else {
    return getFeatureValue(items,column)
  }
}

export const getOldValue = (layer,areaId = null,id_element = null,f,id = null,typology = null,filter = []) => {

  let tempItems = []
  let value = []

  id_element = id_element !== null ? parseInt(id_element) : getElementOfId(layer,null,id).id

  if(layer){
    switch(parseInt(id_element)){
      case 1:
        tempItems = layer.lines
      break;
      case 2:
        tempItems = [layer.areas[0].floor]
      break;
      case 3:
        tempItems = [layer.areas[0].ceiling]
      break;
      case 4:
      case 5:
      case 6:
        tempItems = layer.holes
      break;
      default:
        tempItems = layer.items
    }

    if(id_element === 1){
      if(id !== null){
        const foundItem = tempItems.find(item => item.id === id);
        value = foundItem ? foundItem.features_a[f] : null
      } else {
        const firstValue = tempItems[0].features_a[f]
        value = tempItems.every(item => item.features_a[f] === firstValue) ? firstValue : null
      }
    } else {
      value = tempItems[0].features[f]
    }

  }

  // console.log('in old',layer)

  return value
} 

export const getFeatureValue = (item,column) => {
  if(item.type === 'wall'){
    return item.features_a[parseInt(column)]
  } else {
    return item.features[parseInt(column)]    
  }
} 

export const getGroupFeatureValue = (layer,groupId,column) => {
  // console.log('IN OLD VALUE',layer,groupId,column);

  const group = layer.groups.find(gr => gr.id === groupId)

  let values = [];

  if(group){
    group.items.forEach(itemId => {
      const item = getElementOfId(layer,null,itemId)
      if(item){
        values.push(getFeatureValue(item,column)) 
      }
    })
  }

  return values.every(v => v === values[0]) ? values[0] : null
}

export const checkInGroup = (itemId,groupId,groups) => {
  const foundGroup = groups ? groups.find(gr => gr.id === groupId) : null
  // console.log('CHECKING GROUPS',groups)
  
  return foundGroup && foundGroup.items.includes(itemId)
}

export const checkFilterItems = (layer,id_element = null, filter = []) => {
  if(layer === null) return false
  
  const elements = getElementsFromLayer(layer,id_element)

  // console.log('IN CHECK ',filter,id_element,elements)

  return elements.filter(it => {
      let test = []

      if(filter && filter.length > 0){
        filter.forEach(filt => {
          if(it.features[filt.f] === filt.v) {
            test.push(true)
          } else {
            test.push(false)
          }
        })
      }

      return test.length === 0 || test.every(t => t === true)
    });
}

export const checkActions = (item, actions) => {

  let checks = [];

  // console.log('++++++++++',item.id,actions)

  if(actions){

  actions.forEach(action => {
      switch (action.a){
        case 'set':
          // console.log('checking',item.id,item.features[action.f],'-',action,item.features[action.f] === parseInt(action.v))
          if(item.features[action.f] === parseInt(action.v)){
            checks.push(true)
          }
        break;
        default:
          checks.push(false)
      }
    })
  }

  // console.log('============',item.features[actions[0].f],actions[0],checks)

  return checks.every(ch => ch === true) && checks.length > 0
}

export const getCalculatedDimensions = (items,typologies = [],which) => {

  which = which ? which : 'length';

  const summed = items
    .filter(item => typologies.includes(item.features[1]))
    .filter(it => {return it.measurements !== undefined})
    .reduce((total,item_) => {
      console.log('measuring ',item_.measurements[which],total)
      return total + item_.measurements[which]
    },0)

  // console.log('IN CHECK DIMM',summed)

  return isNaN(summed) ? 0 : summed;
}