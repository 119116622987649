import { useEffect, useContext, useState } from 'react';
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import CalcContext from '../../context/CalcContext'

const Labels = ({styles}) => {
  const projectActions = useContext(ProjectContext)
  const sceneActions = useContext(SceneContext)
  const calcActions = useContext(CalcContext)
  const [active, setActive] = useState(projectActions.project.defaults.style)

  useEffect(() => {
    setActive(projectActions.project.defaults.style)
    // console.log('IN LABELS',projectActions.project.defaults.style)
      // calcActions.makeCalculations();
      // setTimeout(calcActions.makeResults(),5000);
      // sceneActions.setRefresh(!sceneActions.refresh)
      setActive(() => projectActions.project.defaults.style)
  },[sceneActions.refresh])

  const handleChangeStyle = (i) => {
    projectActions.setDefault('style',i)
    projectActions.setDefault('substyle',0)
    sceneActions.setFeatureOfAll({scene:'mScene',feature:23,value:projectActions.project.defaults.style})
      .then(scenes => {
        calcActions.makeCalculations();
        calcActions.makeResults();
        projectActions.saveProject(projectActions.project.projectId,projectActions.project.name)
      })
  }

  return <div className='s-labels'>
    {styles && styles.length > 0 && styles.map((o,i) => <div key={i} className={i === active ? 'selected' : ''}
          onClick={() => handleChangeStyle(i)}
        >{o.label}</div>)
    }</div>
}

export default Labels