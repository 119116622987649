import { useState, useEffect, useRef, useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { AiOutlineUser } from 'react-icons/ai'

const UserPanel = ({ onClickOutside, name=true }) => {

  const ref = useRef(null)

  const [open,setOpen] = useState(false)

  const { currentUser, setCurrentUser, user, setUser } = useContext(AuthContext)

  useEffect(() => {

    // console.log(user)    
    
    const handleClickOutside = (e) => {
      setOpen(false)
    }
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };

  },[onClickOutside])

  const handleLogout = (e) => {
    localStorage.setItem("user",'')
    localStorage.setItem("userDetails",'')
    localStorage.removeItem("user")
    localStorage.removeItem("userDetails")
    localStorage.removeItem("selectedUser")
    setCurrentUser(null)
    setUser(null)
  }

  const handleOpen = () => {
    setOpen(!open)
    console.log(open)
  }

  return (
    <div className="user-panel">
      <div className='user-icon' onClick={handleOpen}>{currentUser && name === true && currentUser.displayName}<AiOutlineUser size="2em" /></div>
      {open && <div ref={ref} className='user-menu'>
        {currentUser ? <>
          <Link to="/login" onClick={handleLogout}>LogOut</Link>
          {user.profile_type === "personal" && <>
            <Link to={"/profile/" + currentUser.uid}>Profile</Link>
          </>}
          <Link to="/miniprojects">{user.profile_type === "admin" && 'Mini'}Projects</Link>
          {user.profile_type === "admin" && <><Link to="/users">Users</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/clients">Clients</Link>
            <Link to="/dashboard">Dashboard</Link>
          </>}
        </> : <Link to="/login">Login</Link>}
      </div>}
    </div>  )
}

export default UserPanel