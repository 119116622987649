import {useContext, useEffect, useState} from "react";
import {Collapse, Image} from "antd";
import { HiArrowRight } from 'react-icons/hi';
import {currencyFormat} from "../../utils/formatUtils";
import moment from 'moment'
import ProjectContext from "../../context/ProjectContext";
import ModalFinalDesign from "./ModalFinalDesign";
import { getScope } from '../../utils/budgetUtils' 

/**
 * Header budget component.
 *
 * @param prop Props.
 * @param prop.result Results by projectActions.
 * @param prop.scope TODO: create doc.
 * @param prop.isSingle TODO: create doc.
 * @param prop.styles Custom styles.
 * @return React element HeaderBudget component.
 */
const HeaderBudget = ({
  result,
  scope='',
  isSingle=true,
  defaults,
  styles
}) => {

    const projectActions = useContext(ProjectContext)
    const [projectScope, setProjectScope] = useState('baño')
    const [visible, setVisible] = useState(false)

    const finalDesign = () => {

        // console.log('IN HEADER',scope,'-',defaults,styles[defaults.style])

        const substyle = styles ? styles[defaults.style ?? 0].substyles[defaults.substyle ?? 0] : {};
        const imgSrc = styles
            ? process.env.PUBLIC_URL + substyle?.url
            : '';
        const project = {
            style: styles ? styles[defaults.style ?? 0].label : '',
            substyle: substyle?.label || '',
            description: styles[defaults.style ?? 0].description,
        }
        projectActions.setModalData((<ModalFinalDesign imgSrc={imgSrc} project={project} scope={projectScope}/>));
        projectActions.setModal(true);
    }

    useEffect(() => {
        setProjectScope(getScope(scope))
    },[result])

    return (
        <>
            {isSingle === false 
                ? 
                <div className='s-overall'>
                    <div className="s-output">
                        <div className="s-row">
                            <span className="bolder">Precio de {projectScope}:</span>
                            <span className="s-subtitle" style={{fontSize:'24px'}}>{currencyFormat(result.overall.toFixed(2))}</span>
                        </div>
                    </div>
                    <div className="s-output">
                        <div className="s-row"><span className="bolder">Comentario:</span></div>
                        {result
                            ? <div className="s-row"><span className="s-comment">{result.description}</span></div>
                            : 'No active results found'}
                    </div>
                </div>
                :
                <><div className='s-overall' style={{marginBottom:'1em'}}>
                    <div className="s-output">
                        <div className="s-row">
                            <span className="bolder">Tiempo:</span>
                            <span className="s-subtitle" style={{fontSize:'24px'}}>{result.startDate && result.endDate ? (new Date(result.endDate) - new Date(result.startDate))/ (1000 * 3600 * 24) + ' días' : 'NA'}</span>
                        </div>
                        <div className="s-row">
                            <span className="s-comment" style={{marginTop:'1em'}}>Inicio estimado:</span>
                            <span className="bolder" style={{marginTop:'1em',paddingRight:'1em',fontSize:'12px'}}>{result.startDate ? moment(result.startDate).format('DD/MM/YYYY') : 'N/A'}</span>
                        </div>
                    </div>
                    <div className="s-output">
                        <div className="s-row">
                            <span className="bolder">Precio de {projectScope}:</span>
                            <span className="s-subtitle" style={{fontSize:'24px'}}>{currencyFormat(result.overall.toFixed(2))}</span>
                        </div>
                        <div className="s-comment">(IVA no incluido)</div>
                    </div>
                </div>
                <div className='s-overall'>
                    <div className="s-output">
                        <div className="s-row"><span className="bolder">Comentario:</span></div>
                        {result
                            ? <div className="s-row"><span className="s-comment">{result.description}</span></div>
                            : 'No active results found'}                        
                    </div>
                </div></>}
            <div className='s-overall'>
                {result.resultImages && result.resultImages.length > 0 &&
                <div className="s-link" onClick={() => setVisible(true)}>
                    <span className="bolder">Documentación de la reforma de tu {projectScope}</span><span className="blue-main">Ver <HiArrowRight /></span>
                </div>}
                <div className="s-link" onClick={() => {finalDesign()}}>
                    <span className="bolder">Diseño de referencia para tu {projectScope}</span><span className="blue-main">Ver <HiArrowRight /></span>
                </div>
            </div>
            {result.resultImages && result.resultImages.length > 0 &&
            <Image.PreviewGroup style={{display: 'none'}} preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                {result.resultImages.map((img,i) => <Image style={{display: 'none'}} key={i} src={img.url}/>)}
            </Image.PreviewGroup>}
            {/* {console.log(styles[style].substyles[substyle].url)} */}
        </>
    )
}

export default HeaderBudget;
