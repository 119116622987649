import { useEffect, useState, useContext } from 'react'
import { FaInfinity, FaRegClock, FaRegCalendarAlt, FaCalendarWeek } from 'react-icons/fa'
import {BsCalendarEvent } from 'react-icons/bs'
import { IoCalendarSharp } from 'react-icons/io5'
import AdminContext from '../../context/AdminContext'

const TimeToggle = ({target}) => {

    const adminActions = useContext(AdminContext)

    const times = [
        {title:'last hour',icon:<FaRegClock size='1.5em'/>,mode:'hour',start:3600},
        {title:'last 24hr',icon:<BsCalendarEvent size='1.5em'/>,mode:'day',start:86400},
        {title:'last 7 days',icon:<FaCalendarWeek size='1.5em'/>,mode:'week',start:604800},
        {title:'last 31 days',icon:<IoCalendarSharp size='1.5em'/>,mode:'month',start:2678400},
        {title:'all time',icon:<FaInfinity size='1.5em'/>,mode:'all',start:315360000}
    ]

    const [time, setTime] = useState(4)

    // useEffect(() => {
    //     times.forEach((t,i) => {
    //         if(t.start === adminActions.range.start){
    //             setTime(() => i)
    //         }
    //     })
    // },[])

    const handleToggle = () => {
        let tempTime = time;
        if(tempTime < times.length - 1){
            tempTime = tempTime + 1;
        } else {
            tempTime = 0
        }            
        switch(target){
            case 'users':
                console.log('IN TOOGLE',times[tempTime].start)
                adminActions.setUserRange({start:times[tempTime].start,end:Date.now()})
            break;
            case 'projects':
                adminActions.setProjectRange({start:times[tempTime].start,end:Date.now()})
            break;
            case 'documents':
                adminActions.setDocumentRange({start:times[tempTime].start,end:Date.now()})
            break;
        }
        setTime(() => tempTime);
    }

    return (
        <div style={{
            display:'flex',
            alignItems:'center',
            border:'1px solid lightgrey',
            borderRadius:'.5em',
            padding:'.3em .5em',
            gap:'.5em',
            cursor: 'pointer'
        }}>
            <select value={time} onChange={handleToggle}>
            {times.map((t,i) => <option key={i} value={i}>{t.title}</option>)}
            </select>
            <div style={{display:'flex'}} onClick={handleToggle}>{times[time].icon && times[time].icon}</div>
        </div>
    )
}

export default TimeToggle 