import { useState, useEffect, useContext } from 'react'
import { Select } from 'antd'
import QnrContext from '../../context/QnrContext'
import ProjectContext from '../../context/ProjectContext'
import SceneContext from '../../context/SceneContext'
import CalcContext from '../../context/CalcContext'
import SubstyleSelector from './SubstyleSelector'
import MaterialSelector from './MaterialSelector'
import ModelSelector from './ModelSelector'

const SelectorInput = ({q,itemId}) => {

    const qnrActions = useContext(QnrContext)
    const projectActions = useContext(ProjectContext)
    const sceneActions = useContext(SceneContext)
    const calcActions = useContext(CalcContext)

    const [activeStyle, setStyle] = useState(projectActions.project.defaults.style ?? 0)
    const [activeSubstyle, setSubstyle] = useState(projectActions.project.defaults.substyle ?? 0)
    const [localStyle, setLocalStyle] = useState(projectActions.project.defaults.style ?? 0)
    const [localSubstyle, setLocalSubstyle] = useState(projectActions.project.defaults.substyle ?? 0)
    const [variant, setVariant] = useState(0)
    const [material, setMaterial] = useState(null)
    const [model, setModel] = useState(null)

    // useEffect(() => {
    //     setStyle(() => projectActions.project.defaults.style)
    //     setSubstyle(() => projectActions.project.defaults.substyle)
    // },[projectActions.project.defaults.style,projectActions.project.defaults.substyle])

    useEffect(() => {
        setVariant(qnrActions.activeVariant)
    },[qnrActions.activeVariant])

    const handleChangeStyle = (value) => {
        if(variant !== 1){  
            projectActions.setDefault('style',parseInt(value))
            projectActions.setDefault('substyle',0)
            setLocalStyle(parseInt(value))
        } else {
            setLocalStyle(parseInt(value))
        }
        projectActions.setRefresh(!projectActions.refresh)
    }

    const handleChangeSubstyle = (j) => {
        // console.log(variant)
        // console.log(j)
        if(variant !== 1){            
            projectActions.setDefault('substyle',parseInt(j))
            setLocalSubstyle(parseInt(j))
        } else {
            setLocalSubstyle(parseInt(j))
        }
        projectActions.setRefresh(!projectActions.refresh)
    }

    const handleModelAction = (value,id_element,groupId) => {
        console.log('MODEL ',value,itemId,id_element)
        if(groupId){
            sceneActions.setGroupFeature('mScene',17,parseInt(value),groupId,parseInt(id_element))
        } else {
            sceneActions.setFeature('mScene',17,parseInt(value),parseInt(id_element),[],itemId)
        }
        calcActions.makeCalculations();
        calcActions.makeResults();
        projectActions.setRefresh(!projectActions.refresh)
    }

    const handleMaterialAction = (value,id_element,groupId) => {
        // console.log('MAT ',value,itemId,id_element)
        setMaterial(value)
        if(groupId){
            sceneActions.setGroupFeature('mScene',3,parseInt(value),groupId,parseInt(id_element))
        } else {
            sceneActions.setFeature('mScene',3,parseInt(value),parseInt(id_element),[],itemId)
        }
        calcActions.makeCalculations();
        calcActions.makeResults();
        projectActions.setRefresh(!projectActions.refresh)
    }

    const selectElement = (option) => {
        switch (option.values){
            case "styleSelect":
                const styles = qnrActions.qnrData.styles.map((style,i) => {return {label:style.label,value:String(i)}})
                return <Select
                defaultValue={String(projectActions.project.defaults.style)}
                    dropdownStyle={{
                        borderRadius:'1em',
                        padding:'1em .5em'
                    }}
                    size={'large'}
                    style={{
                        width: '100%',
                    }}
                    options={styles}
                    onChange={handleChangeStyle}
                />
            case "substyleSelect":
                return <SubstyleSelector 
                    styles={qnrActions.qnrData.styles} 
                    action={handleChangeSubstyle} 
                    localStyle={localStyle} 
                    localSubstyle={localSubstyle}
                />    
            case "materialSelect": 
            case "secondaryMaterialSelect":
                return <MaterialSelector 
                    option={option}
                    action={handleMaterialAction}
                    variant={qnrActions.variant} 
                    localStyle={localStyle} 
                    localSubstyle={localSubstyle}
                    itemId={itemId}
                    groupId={q.group}
                />    
            case "modelSelect":
                return <ModelSelector 
                    option={option}
                    action={handleModelAction}
                    variant={qnrActions.variant} 
                    localStyle={localStyle} 
                    localSubstyle={localSubstyle}
                    material={material}
                    itemId={itemId}
                    groupId={q.group}
                />    
            default:
                return <div>{option.values}</div>
        }        
    } 
    return q.options.map((option,i) => <div key={i} style={{
        marginBottom:'1em'
    }}>
        {option.title && <div className='q-title'>{option.title}</div>}
        {option.label && <div className='q-subtitle' style={{fontSize:'12px',fontWeight:'500',marginBottom:'.5em'}}>
            {option.label}
        </div>}
        {selectElement(option)}
    </div>)
}

export default SelectorInput