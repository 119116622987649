import { useContext, useState, useEffect } from 'react'
import Navbar from '../components/dashboard/Navbar'
import Sidebar from '../components/dashboard/Sidebar'
import UserPanel from '../components/dashboard/UserPanel'
import ProjectList from '../components/dashboard/ProjectList'

const Projects = () => {
  return (
    <div className="dashboard-layout">
        <Navbar />
        <div className='dashboard-content'>
            <Sidebar />
            <ProjectList />
        </div>
        <UserPanel />    
    </div>
  )
}

export default Projects