import { useEffect, useState } from 'react'
import PuffLoader from "react-spinners/PuffLoader";
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs'

const BigImg = ({source = []}) => {

    const [ loaded, setLoaded ] = useState(false)
    const [ current, setCurrent ] = useState(0)

    const onLoad = () => {
        setLoaded(true)
    };

    const handleNext = () => {
        setCurrent(current + 1)
        console.log('|||',current)
      }
  
      const handlePrev = () => {
        setCurrent(current - 1)
        console.log('|||',current)
      }

    useEffect(() => {
        setCurrent(0)
    },[source])

    return (
        <div style={{overflow:'hidden'}}>
            {/* {loaded === false && <PuffLoader color="#ffffff" />} */}
            {/* {source ? <img src={source.img[current]}  onLoad={onLoad} style={{borderRadius:'1em',maxHeight:"80vh"}}/> : <div>Sin imagen</div>} */}
            {source ? <div 
                style={{
                    zIndex:100,
                    borderRadius:'1em',
                    width:'800px',
                    height:'772px',
                    backgroundImage:`url(${source.img[current]})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize:'cover'
                }}
            /> : <div>Sin imagen</div>}
            {source && source.img.length > 1 && <div className='navBtnsPreview'>
                {current !== 0 && <div className='navBtn left' onClick={handlePrev}><BsArrowLeftCircle size="30px"/></div>}
                {current < source.img.length - 1 && <div className='navBtn right' onClick={handleNext}><BsArrowRightCircle size="30px"/></div>}
            </div>}
        </div>
    )
}

export default BigImg