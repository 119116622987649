import React from 'react'
import { Table } from 'antd';
import TableDetailJob from "./TableDetailJob";
import { amplitudeEventDropdownTable } from "../../utils/budgetUtils";
import { currencyFormat } from "../../utils/formatUtils";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";

const { Column } = Table;

/**
 * Table budget with collapsable groups by chapter.
 *
 * @param prop Props.
 * @param prop.jobs Results parsed by budgetUtils.getChapters
 * @param prop.isBasicBudget If only show a budget basic.
 * @param prop.blur TODO: create doc.
 * @param prop.isActive Is project is active.
 * @return React element BudgetTableGroup component.
 */
const BudgetTableGroup = ({jobs, isBasicBudget, blur=false, isActive}) => {

    // Emit Amplitude event when make dropdown table
    const emitDropdownAmplitude = (id) => amplitudeEventDropdownTable(id)

    const cssHeader = 'flex flex-col items-center justify-center color-blue cursor-pointer';

    return (
        <>
            <Table
                className={'w-full'}
                pagination={false}
                showHeader={true}
                expandable={{
                    onExpand: (isOpen, record) => {emitDropdownAmplitude(record.id)},
                    expandedRowRender: (record,i) => (
                        <div className={i > 1 && blur === true ? 'q-blur' : ''}>
                            {/*Obras*/}
                            <TableDetailJob
                                jobs={record.jobs.work}
                                type={'work'}
                                isBasicBudget={isBasicBudget}
                                blur={blur === true && i > 1}
                            />
                            {/*Acabados*/}
                            <TableDetailJob
                                jobs={record.jobs.detail}
                                type={'detail'}
                                isBasicBudget={isBasicBudget}
                                isActive={isActive}
                                blur={blur === true && i > 1}
                            />
                        </div>

                    ),
                    expandIcon: ({ expanded, onExpand, record }) => <div className={cssHeader}>
                            {expanded ? (
                                    <FiChevronUp
                                        onClick={e => onExpand(record, e)}
                                        size={18.18}
                                    />
                                ) : (
                                    <FiChevronDown
                                        onClick={e => onExpand(record, e)}
                                        size={18.18}
                                    />
                                )
                            }
                        </div>,
                    rowExpandable: (record) => (
                        record.jobs.detail &&
                        record.jobs.detail.data &&
                        record.jobs.detail.data.length
                    ) || (
                        record.jobs.work &&
                        record.jobs.work.data &&
                        record.jobs.work.data.length
                    ),
                }}
                dataSource={jobs.jobs}
            >
                <Column
                    title="Fases de la reforma"
                    dataIndex="name_es"
                    key="name_es"
                    colSpan={2}
                    align={'left'}
                    className="color-blue"
                    render={(name_es,data,i) => <div className={blur === true && i > 1 ? 'q-blur' : ''}>{name_es}</div>}
                />
                <Column className={'text-right'} title="Importe" dataIndex="amountTotal" key="amountTotal"
                    render={(amountTotal,data,i) => <div className={blur === true && i > 1 ? 'q-blur text-secondary font-bold' : 'text-secondary font-bold'}>
                            {blur === true && i > 1 ? '9999,99' : currencyFormat(amountTotal.toFixed(2))}
                        </div>}
                />
                {/*<Column className={'pl-16 text-center'} title="Progreso" dataIndex="percentageTotal" key="percentageTotal"*/}
                {/*    render={(percentage) => (*/}
                {/*        <div className='_lineParent _percentageBar'>*/}
                {/*            <div className='_line'>*/}
                {/*                <div style={{ width: percentage }} />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*/>*/}
                {/*<Column className={'text-center'} title="Porcentaje" dataIndex="percentageTotal" key="percentageTotal"*/}
                {/*    render={(percentage) => (<>{percentage.toFixed(2)}%</>)}*/}
                {/*/>*/}
            </Table>
        </>
    )
}

export default BudgetTableGroup
