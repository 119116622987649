import { useEffect, useState, useContext } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, Link } from 'react-router-dom'
import PuffLoader from "react-spinners/PuffLoader";
import Widget from './Widget'
import { HiUserGroup } from 'react-icons/hi'
import { MdOutlineMapsHomeWork } from 'react-icons/md'
import { GrDocumentStore} from 'react-icons/gr'
import { BsCalendarEvent } from 'react-icons/bs'
import { FaPlusSquare, FaInfinity, FaRegClock, FaRegCalendarAlt, FaCalendarWeek } from 'react-icons/fa'
import { CgCalendarToday } from 'react-icons/cg'
import {IoChevronUp, IoStatsChart, IoChatbubblesOutline, FaCalendarDay, IoCalendarSharp } from 'react-icons/io5'
import AuthContext from '../../context/AuthContext'
import AdminContext from '../../context/AdminContext'
import ModalContext from '../../context/ModalContext'
import TimeToggle from '../widgets/TimeToggle';
import moment from 'moment';
import { Calendar, Alert, Form, Input, Select } from 'antd';


const Intro = () => {
  const navigate = useNavigate()

  const authActions = useContext(AuthContext)
  const adminActions = useContext(AdminContext)
  const modalActions = useContext(ModalContext)

  const [newUserForm] = Form.useForm();

  const [time, setTime] = useState(4);
  const [filteredUsers, setFilteredUsers] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [value, setValue] = useState(() => moment('2017-01-25'));
  const [selectedValue, setSelectedValue] = useState(() => moment('2017-01-25'));

const fetchUsers = async () => {
  let allUsers = await adminActions.getAllUsers();
  return allUsers
}

const fetchProjects = async () => {
  let allProjects = await adminActions.getAllProjects();
  return allProjects
}

  const users = useQuery('users', fetchUsers)
  const projects = useQuery('projects', fetchProjects)

  useEffect(() => {
    authActions.setSelectedUser(() => null)
  },[])

  useEffect(() => {
    filterUsers();
  },[users.status,adminActions.userRange])

  useEffect(() => {
    if(projects.status === 'success')
      setFilteredProjects(() => projects.data.filter(prj => {
      return true;
    }));
  },[projects.status,time])

  const filterUsers = () => {
    const now = Math.round(Date.now() / 1000);
    console.log('FILTER USERS ',adminActions.userRange)
    if(users.status === 'success'){
      setFilteredUsers(() => Object.values(users.data).filter(usr => {
        let test = usr.accountCreationDate.seconds > now - adminActions.userRange.start;
        return test
      }))      
    }
  }

  const onSelect = (newValue) => {
    setValue(newValue);
    setSelectedValue(newValue);
  };

  const onPanelChange = (newValue) => {
    console.log(newValue)
    setValue(newValue);
  };

  const handleNew = (what) => {
    console.log('NEW',what)
    const addModal = <Form
      form={newUserForm}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onValuesChange={onAddNew}
    >
      Fill the form to add new {what}
      <Form.Item label=" Name">
        <Input />
      </Form.Item>
      <Form.Item label="profile type">
        <Select>
          <Select.Option key={0} value="admin">Admin</Select.Option>
          <Select.Option key={1} value="personal">Personal</Select.Option>
          <Select.Option key={2} value="admin">Pro</Select.Option>
        </Select>
      </Form.Item>
    </Form>
    modalActions.setModalData({
      heading: "New " + {what},
      subHeading: "del",
      note: "Add new user",
      body: (
        addModal
      ),
      buttons: [
        { text: "En otro momento", type: "text", onClick: "close" },
        {
          text: "Add " + {what},
          type: "primary",
          danger: "danger",
          // onClick: () => {adminActions.deleteUser(userId);modalActions.setOpen(false)}
        }
      ]
    });
    modalActions.setOpen(true);
  }

  const onAddNew = () => {
    console.log('ADD NEW')
  }

  return (
    <div className='widgets-container'>
        {authActions.user.profile_type === "admin" && <Widget type="users">
          <div className='widget-top'>
            <FaPlusSquare size="2em" title="Add new user" onClick={() => handleNew('user')} style={{cursor:'pointer'}}/>
            <TimeToggle target="users"/>
          </div>
          <div className='widget-mid'>
            {users.status === 'loading' && <PuffLoader />}
            {users.status === 'error' && (<div>Error fetching data</div>)}
            {users.status === 'success' && (<div className="active" onClick={() => navigate('/users')}>
              <span className="big">{filteredUsers.length}</span> users
            </div>)}
          </div>
          <div className='widget-bottom'>
            <div>
              <div className="active" onClick={() => {adminActions.filterUsers([{userType:'pro'}]);navigate('/users',{state:{filters:[{userType:'pro'},{time:time}]}})}}>{filteredUsers.filter(u => u.userType === 'pro').length} pro</div>
              <div className="active" onClick={() => {adminActions.filterUsers([{userType:'personal'}]);navigate('/users',{state:{filters:[{userType:'personal'},{time:time}]}})}}>{filteredUsers.filter(u => u.userType === 'personal').length} personal</div>
              <div className="active" onClick={() => {adminActions.filterUsers([{userType:'admin'}]);navigate('/users',{state:{filters:[{userType:'admin'},{time:time}]}})}}>{filteredUsers.filter(u => u.userType === 'admin').length} admin</div>
              
            </div>
            <HiUserGroup size="2em"/>
          </div>
        </Widget>}
        {authActions.user.profile_type === "admin" 
          ? <Widget>
            <div className='widget-top'>
              <FaPlusSquare size="2em" title="Add new user" onClick={() => handleNew('project')} style={{cursor:'pointer'}}/>
              <TimeToggle target="projects"/>
            </div>
            <div className='widget-mid'>
              {projects.status === 'loading' && <PuffLoader />}
              {projects.status === 'error' && 'Error Loading Projects'}
              {projects.status === 'success' && (<div className="active" onClick={() => navigate('/users')}>
                <span className="big">{filteredProjects.length}</span> projects
              </div>)}
            </div>
            <div className='widget-bottom'>               
              <div>
                <div className="active" onClick={() => {adminActions.filterProjects([{scope:'BanoProyecto'}]);navigate('/projects',{state:{filters:[{userType:'pro'},{time:time}]}})}}>{filteredProjects.filter(u => u.scope === 'BanoProyecto').length} Bano Proyecto</div>
                <div className="active" onClick={() => {adminActions.filterProjects([{scope:'CocinaProyecto'}]);navigate('/projects',{state:{filters:[{userType:'personal'},{time:time}]}})}}>{filteredProjects.filter(u => u.scope === 'CocinaProyecto').length} Cocina Proyecto</div>
                <div className="active" onClick={() => {adminActions.filterProjects([{scope:'Proyecto'}]);navigate('/projects',{state:{filters:[{userType:'admin'},{time:time}]}})}}>{filteredProjects.filter(u => u.scope === 'Proyecto').length} Proyecto</div>
              </div>
              <MdOutlineMapsHomeWork  size="2em"/></div>
            </Widget>
          : <Widget type="My Projects" icon={<MdOutlineMapsHomeWork  size="2em"/>}/>}
            {authActions.user.profile_type === "admin" 
              ? <Widget type="Documents">
                <div className='widget-top'>
                  <FaPlusSquare size="2em" title="Add new user" onClick={() => handleNew('document')} style={{cursor:'pointer'}}/>
                  <TimeToggle target="documents"/>
                </div>
                <div className='widget-mid'>
                  <div className="active">
                    <span className="big">0</span> documents
                  </div>
                </div>
                <div className='widget-bottom'>
                  <div>
                    <div className="active" onClick={() => {navigate('/documents',{state:{filters:[{userType:'pdf'},{time:time}]}})}}>0 PDFs</div>
                    <div className="active" onClick={() => {navigate('/documents',{state:{filters:[{userType:'image'},{time:time}]}})}}>0 Images</div>
                  </div>
                  <GrDocumentStore  size="2em"/>
                </div>
              </Widget> 
          : <Widget type="My Documents" icon={<GrDocumentStore  size="2em"/>}/>}
        {authActions.user.profile_type === "admin" 
          ? <Widget type="Calendar">
              <Alert message={`You selected date: ${selectedValue?.format('YYYY-MM-DD')}`} />
              <Calendar value={value} onSelect={onSelect} fullscreen={false} onPanelChange={onPanelChange} getCalendarContainer={triggerNode => triggerNode.parentNode}/>
            <div className='widget-bottom'><span></span><IoCalendarSharp size="2em"/></div>
          </Widget> 
          : <Widget type="My Calendar" icon={<IoCalendarSharp size="2em"/>}/>}
    </div>
  )
}


export default Intro